export const myVar = "InsertMyVar";

export const header = {
    myVar: "InsertMyVar",
};

export const footer = {
    myVar: "InsertMyVar",
};

export const cardCar = {
    prodYear: "An fabricație",
    ac: "Aer Condiționat",
    camera: "Cameră",
};

export const cardInstructor = {
    age: "Vârsta, ani",
    experience: "Experiența, ani",
    smoker: "Fumător",
};

export const cardStudent = {
    licenceCategory: "Categorie permis",
    edit: "Redacteaza",
    group: "Grupa de studii",
    forming: "Se formeaza",
    theory: "Cursuri teorie",
    of: "din",
    driving: "Sedinte conducere",
    notifications: "Notificari",
    settings: "Setari",
    messages: "Mesaje",
};

export const roadmap = {
    steps: "Etapele obtinerii permisului",
    data: "Completare date",
    dosc: "Adaugare acte",
    payment: "Efectuare plata",
    law: "Legislatie",
    driving: "Conducere",
    exam: "Examen",
    completeData: "Completare date personale",
    profileBefore: "Pentru a continua, completati datele din profil",
    demoFirst: "Pana la completarea profilului, sunt disponibile doar date\n" +
        "              demonstrative",
    editProfile: "Editeaza profil",
    addDocs: "Adaugare acte",
    docsBefore: "Adaugare acte",
    makePayment: "Efectuare plata",
    paymentBefore: "Pentru acces la legislatie este necesara plata minima",
    theory: "Legislatie",
    theoryStart: "Începe teoria",
    theoryBefore: "Pentru programarea sedintelor de conducere, finalizati teoria",
    theoryAfter: "Dupa finalizarea modulului teoretic, se va sustine un examen de\n" +
        "              iesire din scoala",
    drivingBefore: " Pentru programarea examenelor, sunt obligatorii 15 sedinte de\n" +
        "              conducere",
    drivingAfter: "Dupa finalizarea modulului practic, scoala va face prima programare pentru a sustine examinare la politie",
    schedule: "Programare",
    selectStep: " Selectati etapa in care va aflati."

};

export const school = {
    currentStats: "Statistici curente",
    currentMonth: "Luna curenta",
    currentWeek: "Saptamana curenta",
    nextWeek: "Saptamana viitoare",
    today: "Astăzi",
    appointmens: "Programari",
    totalHours: "Total ore",
    freeHours: "Ore libere",
    canceledHours: "Ore anulate",
    students: "Elevi",
    totalPersons: "Total persoane",
    docsIncomplete: "Acte incomplete",
    outPayment: "Restante plata",
    instructors: "Instructori",
    unavailableInstructors: "Indisponibili",
    cars: "Mașini",
    totalNumber: "Total număr",
    freeCars: "Libere",
    blockedCars: "Blocate",
};

export const placeholder = {
    search: "Cauta",
};

export const label = {
    cancel: "Renunță",
    logout: "Ieși din cont",
    newAppointment: "Programare noua",
    addStudent: "Adaugă elev",
    addCar: "Adaugă masina",
    addInstructor: "Adaugă instructor",
};
