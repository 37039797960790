import { ProgressBar } from "primereact/progressbar";

const SchoolHomeProgress = () => {
  return (
    <div className="w-full px-5 pt-3 mb-4">
      <div className="surface-card p-4 shadow-2 border-round">
        <div className="flex flex-wrap">
          <div className="col-5 mr-6">
            <ProgressBar value={30} />
          </div>
          <div className="col-5 mr-6">
            <ProgressBar value={80} />
          </div>
          <div className="col-5 mr-6">
            <ProgressBar value={60} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolHomeProgress;
