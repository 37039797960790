import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import Layout from "./Layout/Layout"; //icons

import { GlobalProvider } from "./context/GlobalContext";
import { LayoutProvider } from "./context/LayoutContext";
import { RegisterProvider } from "./context/RegisterContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <GlobalProvider>
      <RegisterProvider>
        <LayoutProvider>
          <Layout>
            <App />
          </Layout>
        </LayoutProvider>
      </RegisterProvider>
    </GlobalProvider>
  </Router>
);
