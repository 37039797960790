import { useState, useEffect } from "react";
import axios from "axios";

import { Card } from "primereact/card";

const StudentPaymentsForSchool = ({ id }) => {
  const [payments, setPayments] = useState([]);

  const getStudentPayments = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/payments/list/${id}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = { headers: { Authorization: `Bearer ${token}` } };

    axios
      .get(API_URL, config)
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        setPayments(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (date) => {
    return new Date(date).toISOString().substring(0, 10);
  };

  useEffect(() => {
    getStudentPayments();
  }, []);

  return (
    <>
      {payments.map((payment) => {
        return (
          <Card
            className="mb-3 py-0 payment-card"
            key={`payment-${payment.id}`}
          >
            <div className="flex">
              <span className="mr-3">Data platii: </span>
              <span>{formatDate(payment.created_at)}</span>
            </div>
            <div className="flex">
              <span className="mr-3">Total de plata:</span>
              <span>{payment.total_amount_to_pay}</span>
            </div>
            <div className="flex">
              <span className="mr-3">Suma platita:</span>
              <span>{payment.current_amount_payed}</span>
            </div>
            <div className="flex">
              <span className="mr-3">Tip plata: </span>
              <span>{payment.type}</span>
            </div>
          </Card>
        );
      })}
    </>
  );
};

export default StudentPaymentsForSchool;
