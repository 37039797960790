import { useState, useContext } from "react";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import { Link } from "react-router-dom";

import styles from "./FooterInstructor.module.css";

import GlobalContext from "../../../context/GlobalContext";
import {commonVars} from "../../../utils/lang/ro/layout";

export default function FooterInstructor() {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [active, setActive] = useState();
  let profileIncomplete = globalState.profileIncomplete[0];

  return (
    <footer>
      <ul className="surface-card p-0 m-0 list-none flex overflow-x-auto select-none col-12">
        <li className="col-3 line-height-1">
          <Link
            to="/student/"
            className={classNames(
              " flex flex-column p-ripple cursor-pointer px-1 py-1 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround",
              {
                "border-blue-500 text-blue-500 hover:border-blue-500":
                  active === 0,
                "text-700 border-transparent": active !== 0,
              }
            )}
            onClick={() => setActive(0)}
          >
            <i className="pi pi-home mr-1"></i> <br />
            <span className="font-small"> {commonVars.home}</span>
            <Ripple />
          </Link>
        </li>
        <li className="col-3 line-height-1">
          <Link
            to="/student/laws-tests"
            className={classNames(
              " flex flex-column p-ripple cursor-pointer px-2 py-1 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround",
              {
                "border-blue-500 text-blue-500 hover:border-blue-500":
                  active === 1,
                "text-700 border-transparent": active !== 1,
              }
            )}
            onClick={() => setActive(1)}
          >
            <i className="pi pi-book mr-1"></i> <br />
            <span className="font-small"> {commonVars.theory}</span>
            <Ripple />
          </Link>
        </li>
        <li className="col-3 line-height-1">
          <Link
            to="/student/driving-schedules"
            className={classNames(
              " flex flex-column p-ripple cursor-pointer px-2 py-1 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround",
              {
                "border-blue-500 text-blue-500 hover:border-blue-500":
                  active === 2,
                "text-700 border-transparent": active !== 2,
              }
            )}
            onClick={() => setActive(2)}
          >
            <i className="pi pi-car mr-1"></i> <br />
            <span className="font-small"> {commonVars.driving}</span>
            <Ripple />
          </Link>
        </li>
      </ul>
    </footer>
  );
}
