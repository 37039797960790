import { useContext } from "react";

import FormInputError from "../components/Utils/FormInputError";
import GlobalContext from "../context/GlobalContext";
import RegisterContext from "../context/RegisterContext";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import fields from "../utils/lang/ro/fields";

const Register = () => {
  const { registerFormState, isLoading, setIsLoading, showConfirmation } =
    useContext(RegisterContext);

  const { globalState } = useContext(GlobalContext);

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
  };

  return (
    <>
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
        {isLoading === true ? (
          <Loader />
        ) : showConfirmation === true ? (
          <Confirmation />
        ) : (
          <Form completedForm={registerFormState} submitForm={submitForm} />
        )}
      </div>
    </>
  );
};

const Loader = () => {
  return <h1>Loading...</h1>;
};

const Confirmation = () => {
  return <h1>Please confirm your email address</h1>;
};

const Form = () => {
  const { registerFormState, handleFormSubmit, handleFormChange, errors } =
    useContext(RegisterContext);

  return (
    <form
      onSubmit={handleFormSubmit}
      className="surface-card p-4 shadow-2 border-round"
    >
      <div className="grid formgrid p-fluid">
        <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

        <div className="field mb-4 col-12 md:col-12">
          <label htmlFor="email" className="font-medium text-900">
            {fields.register.email}
          </label>

          <InputText
            id="email"
            type="email"
            name="email"
            onChange={handleFormChange}
            value={registerFormState.email}
            required
          />

          {errors.email && <FormInputError errors={errors.email} />}
        </div>

        <div className="field mb-4 col-12 md:col-12">
          <label htmlFor="phone" className="font-medium text-900">
            {fields.register.phone}
          </label>

          <InputText
            id="phone"
            type="phone"
            name="phone"
            onChange={handleFormChange}
            value={registerFormState.phone}
            required
          />
          {errors.phone && <FormInputError errors={errors.phone} />}
        </div>

        <div className="field mb-4 col-12 md:col-12">
          <label htmlFor="password" className="font-medium text-900">
            {fields.register.password}
          </label>
          <Password
            id="password"
            type="password"
            name="password"
            toggleMask="true"
            onChange={handleFormChange}
            value={registerFormState.password}
            required
          />
          {errors.password && <FormInputError errors={errors.password} />}
        </div>

        <div className="field mb-4 col-12 md:col-12">
          <label
            htmlFor="password_confirmation"
            className="font-medium text-900"
          >
            {fields.register.confirmationPassword}
          </label>
          <Password
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            toggleMask="true"
            onChange={handleFormChange}
            value={registerFormState.password_confirmation}
            required
          />
        </div>

        <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

        <div className="field mb-4 col-12">
          <label htmlFor="gdpr" className="font-medium text-900">
            Privacy
          </label>

          <div className="flex align-items-center">
            <InputSwitch
              inputId="gdpr"
              checked={registerFormState.gdpr}
              name="gdpr"
              onChange={handleFormChange}
            />
            <span className="ml-2">Share my data with contacts</span>
          </div>
        </div>

        <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

        <div className="col-12">
          <Button label="Save Changes" className="w-auto mt-3" />
        </div>
      </div>
    </form>
  );
};

export default Register;
