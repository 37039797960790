import { useState, useEffect } from "react";
import axios from "axios";

import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const InstructorPaymentsForSchool = ({ payments }) => {
  console.log(payments);

  const formatDate = (date) => {
    return new Date(date).toISOString().substring(0, 10);
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString();
  };

  if (!payments.length) {
    return <div>Nu exista plati inregistrate.</div>;
  }

  console.log(payments);

  return (
    <DataTable value={payments} responsiveLayout="scroll">
      <Column field="amount" header="Suma"></Column>
      <Column field="created_at" header="Data adaugarii"></Column>
    </DataTable>
  );
};

export default InstructorPaymentsForSchool;
