import { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import {school} from "../../utils/lang/ro/components";

const SchoolHomeStatistics = () => {
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className="w-full px-5 pt-3">
      <div className="surface-card p-4 shadow-2 border-round">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header={school.currentStats} disabled></TabPanel>

          <TabPanel header={school.currentWeek} activeIndex>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.appointmens}
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeHours}</span>
                      <span>80</span>
                    </li>
                    <li className="flex justify-content-between list-item mx-4 py-2">
                      <span>{school.canceledHours}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.students}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalPersons}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.outPayment}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.instructors}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.unavailableInstructors}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.cars}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalNumber}</span>
                      <span>12</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeCars}</span>
                      <span>3</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.blockedCars}</span>
                      <span>0</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header={school.currentMonth}>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.appointmens}
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeHours}</span>
                      <span>80</span>
                    </li>
                    <li className="flex justify-content-between list-item mx-4 py-2">
                      <span>{school.canceledHours}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.students}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalPersons}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.outPayment}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.instructors}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.unavailableInstructors}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.cars}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalNumber}</span>
                      <span>12</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeCars}</span>
                      <span>3</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.blockedCars}</span>
                      <span>0</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header={school.today}>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.appointmens}
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeHours}</span>
                      <span>80</span>
                    </li>
                    <li className="flex justify-content-between list-item mx-4 py-2">
                      <span>{school.canceledHours}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.students}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalPersons}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.outPayment}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.instructors}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.unavailableInstructors}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.cars}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalNumber}</span>
                      <span>12</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeCars}</span>
                      <span>3</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.blockedCars}</span>
                      <span>0</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header={school.nextWeek}>
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.appointmens}
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="flex justify-content-between list-item border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeHours}</span>
                      <span>80</span>
                    </li>
                    <li className="flex justify-content-between list-item mx-4 py-2">
                      <span>{school.canceledHours}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.students}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalPersons}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.outPayment}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3 pr-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.instructors}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalHours}</span>
                      <span>400</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.docsIncomplete}</span>
                      <span>80</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.unavailableInstructors}</span>
                      <span>4</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-3">
                <div className="surface-card shadow-2 p-3 border-round">
                  <ul className="list-none p-0">
                    <li className="list-title border-bottom-1 border-200 mb-2 pb-2 font-bold text-lg">
                      {school.cars}
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.totalNumber}</span>
                      <span>12</span>
                    </li>
                    <li className="list-item flex justify-content-between border-bottom-1 border-200 mx-4 py-2">
                      <span>{school.freeCars}</span>
                      <span>3</span>
                    </li>
                    <li className="list-item flex justify-content-between mx-4 py-2">
                      <span>{school.blockedCars}</span>
                      <span>0</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default SchoolHomeStatistics;
