import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import ProfileIncomplete from "./ProfileIncomplete";
import styles from "./HeaderStudent.module.css";

export default function HeaderStudent() {
  const { globalState } = useContext(GlobalContext);
  const userState = globalState.user[0];
  const profileIncomplete = globalState.profileIncomplete[0];

  return (
    <header>
      {profileIncomplete ? <ProfileIncomplete /> : `Salut, ${userState?.name}`}
    </header>
  );
}
