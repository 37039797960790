import {FileUpload} from "primereact/fileupload";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

export default function StudentPayments() {
    return (
        <div>
            <div className="text-900 font-medium text-900 text-xl mb-3">Plăți</div>
            <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">Aici se pot viziona detalii privind plățile
                efectuate și cele ce urmează</p>
            <div className="surface-card shadow-2 border-round p-4">
                <ul className="list-none p-0 m-0">
                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3"
                        style={{
                            borderRadius: '10px',
                            backgroundColor: 'rgba(234,179,10,.1)',
                            borderColor: 'rgba(234,179,10,.5)'
                        }}>
                        <div>
                            <div>
                    <span
                        className="inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1"
                        style={{borderColor: 'rgba(234,179,10,.5)'}}>
                        <i className="pi pi-file text-yellow-700"></i>
                    </span>
                                <span className="text-yellow-700 font-bold ml-2">Plata integrală</span>
                            </div>
                            <p className="text-yellow-700 mt-2 mb-0">Data <span className="font-medium">10/09/2022</span>
                            </p>
                        </div>
                        <div
                            className="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0">
                        <span className="bg-yellow-400 text-yellow-900 font-bold text-sm py-1 px-2"
                              style={{borderRadius: '10px'}}>SPRE PLATĂ</span>
                            <div className="text-right ml-3">
                                <span className="text-yellow-700 font-bold">1200.00</span>
                                <p className="mt-1 mb-0 text-yellow-700">LEI</p>
                            </div>
                        </div>
                    </li>
                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3"
                        style={{
                            borderRadius: '10px',
                            backgroundColor: 'rgba(33,197,94,.1)',
                            borderColor: 'rgba(33,197,94,.5)'
                        }}>
                        <div>
                            <div>
                    <span
                        className="inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1"
                        style={{borderColor: 'rgba(33,197,94,.5)'}}>
                        <i className="pi pi-file text-green-600"></i>
                    </span>
                                <span className="text-green-700 font-bold ml-2">Rata</span>
                            </div>
                            <p className="text-green-700 mt-2 mb-0">Data <span className="font-medium">21/08/2022</span>
                            </p>
                        </div>
                        <div
                            className="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0">
                        <span className="bg-green-400 text-green-900 font-bold text-sm py-1 px-2"
                              style={{borderRadius: '10px'}}>Plătit</span>
                            <div className="text-right ml-3">
                                <span className="text-green-700 font-bold">150</span>
                                <p className="mt-1 mb-0 text-green-700">LEI</p>
                            </div>
                        </div>
                    </li>
                    <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3"
                        style={{
                            borderRadius: '10px',
                            backgroundColor: 'rgba(33,197,94,.1)',
                            borderColor: 'rgba(33,197,94,.5)'
                        }}>
                        <div>
                            <div>
                    <span
                        className="inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1"
                        style={{borderColor: 'rgba(33,197,94,.5)'}}>
                        <i className="pi pi-file text-green-600"></i>
                    </span>
                                <span className="text-green-700 font-bold ml-2">Rata</span>
                            </div>
                            <p className="text-green-700 mt-2 mb-0">Data <span className="font-medium">20/12/2021</span>
                            </p>
                        </div>
                        <div
                            className="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0">
                        <span className="bg-green-400 text-green-900 font-bold text-sm py-1 px-2"
                              style={{borderRadius: '10px'}}>Plătit</span>
                            <div className="text-right ml-3">
                                <span className="text-green-700 font-bold">250.00</span>
                                <p className="mt-1 mb-0 text-green-700">LEI</p>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    )
}