import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const InstructorCarExpenses = ({ expenses, latestExpenses, instructorId }) => {
  const filteredCarExpensees = expenses.filter(
    (expense) => expense.author_user_id === instructorId
  );

  const sortedFiveExpenses = filteredCarExpensees
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 5);

  return (
    <DataTable
      value={latestExpenses ? sortedFiveExpenses : filteredCarExpensees}
      responsiveLayout="scroll"
    >
      <Column field="car_id" header="Masina"></Column>
      <Column field="amount" header="Suma"></Column>
      <Column field="created_at" header="Data adaugarii"></Column>
      <Column field="paid_at" header="Data inregistrarii"></Column>
    </DataTable>
  );
};

export default InstructorCarExpenses;
