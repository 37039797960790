import { useState, useContext } from "react";
import GlobalContext from "../../context/GlobalContext";

import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";

const StudentProfileForm = () => {
  const [profileFormState, setProfileFormState] = useState({
    first_name: "",
    last_name: "",
    birthday: "",
    address: "",
  });

  const { globalState, setGlobalState } = useContext(GlobalContext);

  const handleFormChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "birthday") {
      const formattedDateValue = new Date(e.target.value)
        .toISOString()
        .substring(0, 10);
      value = formattedDateValue;
    }

    setProfileFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const sendUserData = async () => {
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    const url = "https://api.scoliauto.info/api/student";
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const bodyParameters = {
      ...profileFormState,
      uuid: "b044b617-4ea6-47dd-a9bd-7be95e73486a",
      driving_license_category: "B",
      has_criminal_record: 0,
      has_id_card: 1,
      has_psychiatrist_certificate: 0,
      status: "new_student",
      transmission_type: "manual",
      user_id: 1,
      id_card_expiration_date: "2023-03-21",
    };

    await axios
      .post(url, bodyParameters, config)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    sendUserData();
  };

  return (
    <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
      <form
        className="surface-card p-4 shadow-2 border-round"
        onSubmit={handleFormSubmit}
      >
        <div className="grid formgrid p-fluid">
          <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

          <div className="field mb-4 col-12 md:col-12">
            <label htmlFor="last_name" className="font-medium text-900">
              Nume:
            </label>

            <InputText
              id="last_name"
              type="text"
              name="last_name"
              onChange={handleFormChange}
              value={profileFormState.last_name}
              required
            />

            {/* {errors.email && <FormInputError errors={errors.email} />} */}
          </div>

          <div className="field mb-4 col-12 md:col-12">
            <label htmlFor="first_name" className="font-medium text-900">
              Prenume:
            </label>

            <InputText
              id="first_name"
              type="text"
              name="first_name"
              onChange={handleFormChange}
              value={profileFormState.first_name}
              required
            />

            {/* {errors.email && <FormInputError errors={errors.email} />} */}
          </div>

          <div className="field mb-4 col-12 md:col-12">
            <label htmlFor="first_name" className="font-medium text-900">
              Data nasterii:
            </label>

            <Calendar
              showIcon
              name="birthday"
              value={profileFormState.birthday}
              onChange={handleFormChange}
            ></Calendar>
            {/* <input
              type="date"
              name="birthday"
              min="1960-09-01"
              max="2022-09-31"
            /> */}

            {/* {errors.email && <FormInputError errors={errors.email} />} */}
          </div>

          <div className="field mb-4 col-12 md:col-12">
            <label htmlFor="address" className="font-medium text-900">
              Adresa:
            </label>

            <InputText
              id="address"
              type="text"
              name="address"
              onChange={handleFormChange}
              value={profileFormState.address}
              required
            />

            {/* {errors.email && <FormInputError errors={errors.email} />} */}
          </div>

          <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

          <div className="col-12">
            <Button label="Save Changes" className="w-auto mt-3" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default StudentProfileForm;
