export default function StudentDriver() {
    return (

        <div className="surface-card p-4 shadow-2 border-round">
            <div className="font-medium text-3xl text-900 mb-3">Școala mea</div>
            <div className="text-500 mb-5">Aici se vor afișa datele despre școală și școlarizare</div>
            <ul className="list-none p-0 m-0 border-top-1 surface-border">
                <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                    <div className="text-500 w-full md:w-2 font-medium">Categoria</div>
                    <div className="text-900 w-full md:w-10">B</div>
                </li>
                <li className="flex align-items-center py-3 px-2 flex-wrap">
                    <div className="text-500 w-full md:w-2 font-medium">Grupa</div>
                    <div className="text-900 w-full md:w-10 line-height-3">0426</div>
                </li>
                <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                    <div className="text-500 w-full md:w-2 font-medium">Contacte</div>
                    <div className="text-900 w-full md:w-10">Telefon:</div>
                    <div className="text-900 w-full md:w-10">Email:</div>
                </li>
                <li className="flex align-items-center py-3 px-2 flex-wrap">
                    <div className="text-500 w-full md:w-2 font-medium">Adresa</div>
                    <div className="text-900 w-full md:w-10 line-height-3">Iași, Piața Unirii, 32</div>
                </li>
                <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                    <div className="col-12 lg:col-4 p-3">
                        <div className="p-3 border-round shadow-2 flex align-items-center surface-card">
                            <div style={{width: '48px', height: '48px', borderRadius: '10px'}}
                                 className="bg-pink-100 inline-flex align-items-center justify-content-center mr-3">
                                <i className="pi pi-users text-pink-600 text-3xl"></i>
                            </div>
                            <div>
                                <span className="text-900 text-xl font-medium mb-2">Instructori</span>
                                <p className="mt-1 mb-0 text-600 font-medium text-sm">Instructorii școlii</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-4 p-3">
                    <div className="p-3 border-round shadow-2 flex align-items-center surface-card">
                        <div style={{width: '48px', height: '48px', borderRadius: '10px'}}
                             className="bg-teal-100 inline-flex align-items-center justify-content-center mr-3">
                            <i className="pi pi-car text-teal-600 text-3xl"></i>
                        </div>
                        <div>
                            <span className="text-900 text-xl font-medium mb-2">Mașini</span>
                            <p className="mt-1 mb-0 text-600 font-medium text-sm">Mașinile școlii</p>
                        </div>
                    </div>
                </div>
                </li>
            </ul>
        </div>

    )
}