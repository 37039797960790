import React, {useRef, useState} from 'react';
import {FileUpload} from "primereact/fileupload";
import {InputText} from "primereact/inputtext";

export default function StudentDocs() {
    const toast = useRef(null);

    const onBasicUpload = () => {
        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
    }

    const chooseOptions = {
        label: 'Încarcă',
        icon: 'pi pi-fw pi-images',
        iconOnly: false
    };

    return (

        <div>
            <div className="text-900 font-medium text-900 text-xl mb-3">Acte necesare</div>
            <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">Încarcă copii ale actelor de la dosar</p>

            <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12 md:col-6">
                    <label htmlFor="identity-card" className="font-medium text-900">Carte identitate</label>
                    <InputText id="identity-card" type="text" placeholder="Data expirarii"/>
                    <FileUpload mode="basic" chooseOptions={chooseOptions} url="./student/upload" name="student-ci"
                                accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload}/>

                </div>
                <div className="field mb-4 col-12 md:col-6">
                    <label htmlFor="identity-card" className="font-medium text-900">Fișa medicală</label>
                    <InputText id="identity-card" type="text" placeholder="Data expirarii"/>
                    <FileUpload mode="basic" chooseOptions={chooseOptions} url="./student/upload" name="student-medical"
                                accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload}/>
                </div>
                <div className="field mb-4 col-12 md:col-6">
                    <label htmlFor="psyhologic-approve" className="font-medium text-900">Aviz psihologic</label>
                    <InputText id="psyhologic-approve" type="text" placeholder="Data expirarii"/>
                    <FileUpload mode="basic" chooseOptions={chooseOptions} url="./student/upload" name="student-medical"
                                accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload}/>
                </div>
                <div className="field mb-4 col-12 md:col-6">
                    <label htmlFor="police-record" className="font-medium text-900">Cazier</label>
                    <InputText id="police-record" type="text" placeholder="Data expirarii"/>
                    <FileUpload mode="basic" chooseOptions={chooseOptions} url="./student/upload" name="student-medical"
                                accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload}/>
                </div>
                <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                <div className="field mb-4 col-12 md:col-6">
                    <label htmlFor="identity-card" className="font-medium text-900">Alte acte</label>
                    <InputText id="extra-doc1" type="text" placeholder="Denumire"/>
                    <InputText id="extra-doc1" type="text" placeholder="Data expirarii"/>
                    <FileUpload mode="basic" chooseOptions={chooseOptions} url="./student/upload" name="student-medical"
                                accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload}/>
                </div>
<div className="field mb-4 col-12 md:col-6">
                    <label htmlFor="identity-card" className="font-medium text-900">Alte acte</label>
                    <InputText id="extra-doc2" type="text" placeholder="Denumire"/>
                    <InputText id="extra-doc2" type="text" placeholder="Data expirarii"/>
                    <FileUpload mode="basic" chooseOptions={chooseOptions} url="./student/upload" name="student-medical"
                                accept="image/*" maxFileSize={1000000} onUpload={onBasicUpload}/>
                </div>


            </div>

        </div>
    )
}