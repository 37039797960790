import {roadmap} from "../../utils/lang/ro/components";

const RoadmapStepDetail = ({ stepIndex }) => {
  switch (stepIndex) {
    case 0:
      return (
        <div className="roadmap-card px-3 py-4 my-3">
          <h3 className="roadmap-card-title">{roadmap.completeData}</h3>
          <div className="title-separator"></div>
          <div className="roadmap-details">
            <p className="roadmap-details-subtitle">
                {roadmap.profileBefore}
            </p>
            <p className="roadmap-details-description">
                {roadmap.demoFirst}
            </p>
            <div className="btn-container">
              <button className="roadmap-details-btn">{roadmap.editProfile}</button>
            </div>
          </div>
        </div>
      );

    case 1:
      return (
        <div className="roadmap-card px-3 py-4 my-3">
          <h3 className="roadmap-card-title">{roadmap.addDocs}</h3>
          <div className="title-separator"></div>
          <div className="roadmap-details">
            <p className="roadmap-details-subtitle">
                {roadmap.docsBefore}
            </p>
            <p className="roadmap-details-description">
                {roadmap.demoFirst}
            </p>
            <div className="btn-container">
              <button className="roadmap-details-btn">{roadmap.addDocs}</button>
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="roadmap-card px-3 py-4 my-3">
          <h3 className="roadmap-card-title">{roadmap.makePayment}</h3>
          <div className="title-separator"></div>

          <div className="roadmap-details">
            <p className="roadmap-details-subtitle">
                {roadmap.paymentBefore}
            </p>
            <p className="roadmap-details-description">
                {roadmap.demoFirst}
            </p>
            <div className="btn-container">
              <button className="roadmap-details-btn">{roadmap.makePayment}</button>
            </div>
          </div>
        </div>
      );

    case 3:
      return (
        <div className="roadmap-card px-3 py-4 my-3">
          <h3 className="roadmap-card-title">{roadmap.theory}</h3>
          <div className="title-separator"></div>

          <div className="roadmap-details">
            <p className="roadmap-details-subtitle">
                {roadmap.theoryBefore}
            </p>
            <p className="roadmap-details-description">
                {roadmap.theoryAfter}
            </p>
            <div className="btn-container">
              <button className="roadmap-details-btn">{roadmap.theoryStart}</button>
            </div>
          </div>
        </div>
      );

    case 4:
      return (
        <div className="roadmap-card px-3 py-4 my-3">
          <h3 className="roadmap-card-title">{roadmap.driving}</h3>
          <div className="title-separator"></div>

          <div className="roadmap-details">
            <p className="roadmap-details-subtitle">
                {roadmap.drivingBefore}
            </p>
            <p className="roadmap-details-description">
                {roadmap.drivingAfter}
            </p>
            <div className="btn-container">
              <button className="roadmap-details-btn">{roadmap.driving}</button>
            </div>
          </div>
        </div>
      );

    case 5:
      return (
        <div className="roadmap-card px-3 py-4 my-3">
          <h3 className="roadmap-card-title">{roadmap.exam}</h3>
          <div className="title-separator"></div>
          <div className="roadmap-details">
            <p className="roadmap-details-subtitle">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Deserunt, sunt?
            </p>
            <p className="roadmap-details-description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reiciendis ipsa culpa commodi incidunt, ex nihil quae labore ut
              deserunt nam.
            </p>
            <div className="btn-container">
              <button className="roadmap-details-btn">{roadmap.schedule}</button>
            </div>
          </div>
        </div>
      );

    default:
      return <p>{roadmap.selectStep}</p>;
  }
};

export default RoadmapStepDetail;
