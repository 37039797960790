const ProfileIncomplete = () => {
  return (
    <header className="flex align-items-start p-4 bg-pink-100 border-round border-1 border-pink-300">
      <i className="pi pi-times-circle text-pink-900 text-2xl mr-3"></i>
      <div className="mr-3">
        <div className="text-pink-900 font-medium text-xl mb-3 line-height-1">
          Completeaza profilul
        </div>
        <ul className="m-0 p-0 text-pink-700 ml-3">
          <li className="p-1">Acceseaza sectiunea profil</li>
          <li className="p-1">Adauga documentele necesare</li>
          <li className="p-1">Asteapta confirmarea de la scoala</li>
        </ul>
      </div>
      <div className="ml-auto">
        <a
          className="inline-flex align-items-center justify-content-center ml-auto border-circle hover:bg-pink-50 no-underline cursor-pointer transition-colors transition-duration-150"
          style={{ width: "1.5rem", height: "1.5rem" }}
        >
          <i className="pi pi-times text-pink-900"></i>
        </a>
      </div>
    </header>
  );
};

export default ProfileIncomplete;
