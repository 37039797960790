import { createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RegisterContext = createContext();

export const RegisterProvider = ({ children }) => {
  const initState = {
    phone: "",
    password: "",
    password_confirmation: "",
    email: "",
    type: "student",
    gdpr: "",
  };

  const [registerFormState, setRegisterFormState] = useState(initState);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [errors, setErrors] = useState({});

  /* Runs whenever an input is changed */
  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setRegisterFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // console.log(registerFormState);
  };

  /* Submits the form */
  const handleFormSubmit = (e) => {
    e.preventDefault();
    registerUser();
  };

  const navigate = useNavigate();

  /* Sends the data to the API upon form submission */
  const registerUser = () => {
    const url = "https://api.scoliauto.info/api/register";

    axios
      .post(url, registerFormState)
      .then((response) => {
        setAccessToken(response.data.accessToken);
        localStorage.setItem("accessToken", JSON.stringify(accessToken));
        // console.log(response);
        navigate("/student");
      })
      .catch((error) => {
        // console.log(error.response.data.message);
        // console.log(error.response.data.errors);
        setErrors(error.response.data.errors);
      });
  };

  return (
    <RegisterContext.Provider
      value={{
        registerFormState,
        setRegisterFormState,
        initState,
        isLoading,
        setIsLoading,
        showConfirmation,
        setShowConfirmation,
        handleFormChange,
        handleFormSubmit,
        errors,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterContext;
