import { useState, createContext, useContext, useEffect } from "react";
import GlobalContext from "./GlobalContext";

import axios from "axios";

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isStudent, setIsStudent] = useState(false); //false for school layout
  const [isInstructor, setIsInstructor] = useState(false); // for school layout
  const [isSchool, setIsSchool] = useState(false);
  const [profileIncomplete, setProfileIncomplete] = useState(false);
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");

  const { globalState, setGlobalState } = useContext(GlobalContext);

  const API_URL = "https://api.scoliauto.info/api/user";
  const STUDENT_TYPE = "student";
  const API_GET_STUDENT = "student";

  const fetchUserData = async () => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));

    if (token) {
      await axios
        .get(API_URL, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setUserType(response.data.type);
          setIsLogin(true);
          setUserId(response.data.id);
          const profileIncomplete = response.data.has_profile_completed
            ? true
            : false;
          setProfileIncomplete(profileIncomplete);
          setGlobalState((prevState) => ({
            ...prevState,
            profileIncomplete,
          }));
        });
    }
  };

  useEffect(() => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    if (token) {
      setIsLogin(true);
      fetchUserData();
    }
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        isLogin,
        isStudent,
        isInstructor,
        profileIncomplete,
        setIsLogin,
        userType,
        setUserType,
        setIsStudent,
        setIsInstructor,
        setIsSchool,
        userId,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;
