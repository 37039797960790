import { Card } from "primereact/card";
import { Image } from "primereact/image";
import "./DocumentsList.css";

const StudentDocuments = ({ documents }) => {
  return (
    <>
      {documents.map((document) => {
        const getDocumentName = () => {
          switch (document.type) {
            case "psychiatrist_certificate":
              return "Aviz psihologic";
            case "id_card":
              return "Carte de identitate";
            case "criminal_record":
              return "Cazier";
            case "medical_exam":
              return "Aviz medical";
            case "instructor_license":
              return "Atestat instructor";
          }
        };

        return (
          <Card
            key={document.uuid}
            className="mb-4 document-card"
            title={getDocumentName()}
          >
            <div className="flex">
              <div className="document-info mr-5">
                <p>Seria: {document.series}</p>
                <p>Data de expirare: {document.expiration_date}</p>
              </div>

              <Image
                src={document.file_url}
                alt={document.type}
                width="250"
                preview
                className="document-preview"
                onError={(event) => (event.target.style.display = "none")}
              />
            </div>
            {/* {document.media.map((item, i) => {
              console.log(item)
            })} */}
          </Card>
        );
      })}
    </>
  );
};

export default StudentDocuments;
