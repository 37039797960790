import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Swal from "sweetalert2";

const CarsTableBtns = (rowData) => {
  const navigate = useNavigate();
  const carsTableToast = useRef(null);
  const { id } = rowData;

  const handleEditCar = (rowData, navigate) => {
    navigate(`/school/cars/${id}`);
  };

  const deleteCar = (car) => {
    console.log("AICI", id, rowData);

    const API_URL = `https://api.scoliauto.info/api/school/cars/${id}`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .delete(API_URL, config)
      .then((res) => {
        console.log(res);
        carsTableToast.current.show({
          severity: "success",
          detail: "Masina a fost stearsa cu succes!",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteCar = (car) => {
    Swal.fire({
      title: "Confirmati stergerea masinii",
      text: `${car.registration_number}`,
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Introduceti numarul de inmatriculare a masinii!";
        }
      },
    })
      .then((result) => {
        if (result.value === car.registration_number) {
          deleteCar(car);
          Swal.fire(`Masina a fost stearsa!`, "", "success");
        } else {
          Swal.fire("Numarul introdus este incorect!", "", "error");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="flex">
      <Toast
        ref={carsTableToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success mr-2"
        onClick={() => handleEditCar(rowData, navigate)}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => handleDeleteCar(rowData)}
      />
    </div>
  );
};

export default CarsTableBtns;
