import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const CarExpensesTable = ({ getCarExpenses, carExpenses }) => {
  const [newReceiptModal, setNewReceiptModal] = useState(false);

  const filterTable = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  /* Modal helpers */
  const dialogFuncMap = {
    newReceiptModal: setNewReceiptModal,
  };

  const onClick = (name, data) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  useEffect(() => {
    getCarExpenses();
  }, []);

  return (
    <div className="card w-full">
      <Dialog
        header="Adauga chitanta"
        visible={newReceiptModal}
        style={{ width: "30vw" }}
        onHide={() => onHide("newReceiptModal")}
        headerStyle={{ textAlign: "center" }}
      >
        <p>Adauga bon</p>
      </Dialog>

      <DataTable
        value={carExpenses}
        selectionMode="single"
        stripedRows
        responsiveLayout="scroll"
      >
        <Column
          field="id"
          header="ID Cheltuiala"
          sortable
          filter={true}
          filterFunction={filterTable}
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
        ></Column>
        <Column
          field="author_user_id"
          header="Creata de userul"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="car_id"
          header="ID Masina"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="amount"
          header="Suma"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="type"
          header="Tip"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="paid_at"
          header="Data platii"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          header="Copie bon"
          body={(rowData) => {
            return (
              <Button
                label="Adauga"
                onClick={() => onClick("newReceiptModal", rowData)}
                className="p-button-link"
              />
            );
          }}
        ></Column>
        <Column
          field="created_at"
          header="Data inregistrarii"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        {/* <Column
          body={StudentTableBtns}
          exportable={false}
          style={{ minWidth: "8rem" }}
        ></Column> */}
      </DataTable>
    </div>
  );
};

export default CarExpensesTable;
