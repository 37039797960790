export const stepThreeOptions = {
  instructorOptions: [
    { label: "Dacian Ciolos", value: "dacian-ciolos" },
    { label: "Emil Boc", value: "emil-boc" },
    { label: "Instructor 3", value: "instructor-3" },
  ],
  scheduleOptions: [
    { label: "09:00-11:00", value: "9-11" },
    { label: "11:00-13:00", value: "11-13" },
    { label: "13:00-15:00", value: "13-15" },
    { label: "17:00-19:00", value: "17-19" },
  ],
  transmissionOptions: [
    { label: "Manuala", value: "manual" },
    { label: "Automata", value: "automatic" },
  ],
  carOptions: [
    {
      label: "Dacia Logan",
      value: "dacia-logan",
    },
    {
      label: "Opel Astra",
      value: "opel-astra",
    },
    {
      label: "Mazda 3",
      value: "mazda-3",
    },
    {
      label: "Ford Puma",
      value: "ford-puma",
    },
    {
      label: "Seat Arosa",
      value: "seat-arosa",
    },
  ],
  info_1: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  info_2: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  info_3: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  info_4: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  licenseType: [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
  ],
  promoCode: [
    {
      label: "Promo 1",
      value: "promo-1",
    },
    {
      label: "Promo 2",
      value: "promo-2",
    },
    {
      label: "Promo 3",
      value: "promo-3",
    },
  ],
};

export const stepFourOptions = {
  paymentRates: [
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "150", value: "150" },
  ],
  paymentTypes: [
    {
      label: "Taxa scolarizare",
      value: "school_fee",
    },
    {
      label: "Ore suplimentare",
      value: "extra_hours_fee",
    },
  ],
};

export const stepThreeInstructorOptions = {
  licenseSince: [
    { label: "1 an", value: "1" },
    { label: "2 ani", value: "2" },
    { label: "3 ani", value: "3" },
  ],
  instructorSince: [
    { label: "1 an", value: "1" },
    { label: "2 ani", value: "2" },
    { label: "3 ani", value: "3" },
  ],
  gender: [
    { label: "Masculin", value: "m" },
    { label: "Feminin", value: "f" },
  ],
  coursePrice: [
    { label: "$10", value: "10" },
    { label: "$15", value: "15" },
    { label: "$20", value: "20" },
  ],
  info_1: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  info_2: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  info_3: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
  info_4: [
    { label: "Optiune 1", value: "option-1" },
    { label: "Optiune 2", value: "option-2" },
    { label: "Optiune 3", value: "option-3" },
  ],
};

export const collaborationTypes = [
  { label: "Angajat", value: "employee" },
  { label: "Subcontractat", value: "contracted" },
];

export const genderOptions = [
  { label: "Masculin", value: "M" },
  { label: "Feminin", value: "F" },
];

export const weekendCoursesOptions = [
  { label: "Da", value: true },
  { label: "Nu", value: false },
];

export const uploadDocumentTypes = [
  { label: "Carte de identitate", value: "id_card" },
  { label: "Cazier", value: "criminal_record" },
  { label: "Aviz psihologic", value: "psychiatrist_certificate" },
  { label: "Fisa medicala", value: "medical_exam" },
];

export const uploadInstructorDocumentTypes = [
  { label: "Carte de identitate", value: "id_card" },
  { label: "Cazier", value: "criminal_record" },
  { label: "Aviz psihologic", value: "psychiatrist_certificate" },
  { label: "Fisa medicala", value: "medical_exam" },
  { label: "Atestat instructor", value: "instructor_license" },
];

export const licenseTypes = [
  { name: "A", code: "a" },
  { name: "B", code: "b" },
  { name: "C", code: "c" },
];
