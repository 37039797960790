import { Routes } from "react-router-dom";
import { MainRoutes, StudentRoutes, SchoolRoutes } from "./routes";

import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";

const App = () => {
  return (
    <Routes>
      {MainRoutes}
      {StudentRoutes}
      {SchoolRoutes}
    </Routes>
  );
};

export default App;
