export const myVar = "InsertMyVar";

export const commonVars = {
    home: "Acasa",
    theory: "Legislație",
    driving: "Conducere",

};

export const instructor = {
    completeProfile: "Completeaza profilul",
    accessProfile: "Acceseaza sectiunea profil",
    addDocs: "Adauga documentele necesare",
    waitConfirmation: "Asteapta confirmarea de la scoala",
    home: "Acasa",
    theory: "Legislație",
    driving: "Conducere",

};

export const login = {
    email: "Email",
    pass: "Parola",
    forgotPass: "Ai uitat parola?",
    createAccount: "Creaza cont",

};

export const school = {
    waka: "2022 WAKATECH All rights reserved",
    school: "ȘCOALA",
    appointments: "Programări",
    students: "Elevi",
    instructors: "Instructori",
    administration: "ADMINISTRARE",
    payments: "Plăți",
    cars: "Mașini",
    messages: "Mesaje",
    courses: "Cursuri",
    settings: "SETĂRI",
    profile: "Profil",
    application: "Aplicație",

};

