import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import FormInputError from "../../../components/Utils/FormInputError";

import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";

import "./AddNewCar.css";

const AddNewCar = () => {
  const [newCar, setNewCar] = useState({
    registration_number: "",
    make: "",
    model: "",
    registration_year: "",
    license_category: "",
    VIN: "",
  });

  const [updatedNewCar, setUpdatedNewCar] = useState({});

  const [newCarId, setNewCarId] = useState(null);

  const [newCarExtraData, setNewCarExtraData] = useState({
    transmission_type: "",
    fuel_type: "",
    cubic_capacity: "",
    appointment_price: "",
    extra_options: "",
  });

  const [newCarFiles, setNewCarFiles] = useState({
    ITP: null,
    ITP_series: "",
    ITP_expiration_date: "",
    ITP_url: "",
    RCA: null,
    RCA_series: "",
    RCA_expiration_date: "",
    RCA_url: "",
    registration_certificate: null,
    registration_certificate_series: "",
    registration_certificate_expiration_date: "",
    registration_certificate_url: "",
  });
  const [errors, setErrors] = useState({});
  const [newCarActiveIndex, setNewCarActiveIndex] = useState(0);
  const [carStepTwoUnlocked, setCarStepTwoUnlocked] = useState(false);

  const newCarToast = useRef(null);
  const navigate = useNavigate();

  /* Helper functions */
  const handleNewCarSubmit = async (e) => {
    e.preventDefault();

    const parsedDate = new Date(newCar.registration_year).getFullYear();
    const objToSend = {
      ...newCar,
      registration_year: parsedDate,
    };

    const API_URL = "https://api.scoliauto.info/api/school/cars";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    await axios
      .post(API_URL, objToSend, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        console.log(res.data);
        newCarToast.current.show({
          severity: "success",
          detail: "Masina a fost creata!",
          life: 3000,
        });

        setNewCarId(res.data.data.id);
        setCarStepTwoUnlocked(true);
        stateFormReset(newCar, setNewCar);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        newCarToast.current.show({
          severity: "error",
          summary: "Eroare",
          detail: "Masina nu s-a adaugat",
          life: 3000,
        });
        console.log(error);
      });
  };

  const handleNewCarFormChange = (e) => {
    setErrors({});

    const name = e.target.name;
    let value = e.target.value;

    setNewCar((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleNewCarExtrasChange = (e) => {
    setErrors({});

    const name = e.target.name;
    let value = e.target.value;

    // console.log(name, value);

    setNewCarExtraData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleNewCarExtrasSubmit = (e) => {
    e.preventDefault();
    const API_URL = `https://api.scoliauto.info/api/school/cars/${newCarId}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));

    const contToDocs =
      e.nativeEvent.submitter.id === "continueCarDocuments" ? true : false;

    const parsedExtraOptions = newCarExtraData.extra_options.length
      ? newCarExtraData.extra_options.map((option) => option.name)
      : [];

    const objToSend = {
      ...newCarExtraData,
      extra_options: parsedExtraOptions,
    };

    axios
      .patch(API_URL, objToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        newCarToast.current.show({
          severity: "success",
          detail: "Masina a fost actualizata!",
          life: 3000,
        });
        setUpdatedNewCar(res.data.data);
        setCarStepTwoUnlocked(false);
        stateFormReset(newCarExtraData, setNewCarExtraData);
        setErrors({});
        contToDocs && setNewCarActiveIndex(1);
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        newCarToast.current.show({
          severity: "error",
          summary: "Eroare",
          detail: "Masina nu s-a actualizat...",
          life: 3000,
        });
        console.log(error);
      });

    // console.log(objToSend);
  };

  const handleNewCarDocumentsSubmit = async (e) => {
    e.preventDefault();

    const API_URL =
      "https://api.scoliauto.info/api/school/documents/car/upload";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const formData = new FormData();

    const filesToSend = [
      {
        file: newCarFiles.ITP,
        expiration_date: newCarFiles.ITP_expiration_date,
        series: newCarFiles.ITP_series,
        type: "ITP",
      },
      {
        file: newCarFiles.RCA,
        expiration_date: newCarFiles.RCA_expiration_date,
        series: newCarFiles.RCA_series,
        type: "RCA",
      },
      {
        file: newCarFiles.registration_certificate,
        expiration_date: newCarFiles.registration_certificate_expiration_date,
        series: newCarFiles.registration_certificate_series,
        type: "registration_certificate",
      },
    ];

    formData.append("car_id", newCarId);

    filesToSend.forEach((file, i) => {
      formData.append(`documents[${i}][file]`, file.file);
      formData.append(`documents[${i}][expiration_date]`, file.expiration_date);
      formData.append(`documents[${i}][series]`, file.series);
      formData.append(`documents[${i}][type]`, file.type);
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(API_URL, formData, config)
      .then((response) => {
        console.log(response.data);
        newCarToast.current.show({
          severity: "success",
          detail: "Documentele masinii au fost adaugate!",
          life: 3000,
        });
        setNewCarActiveIndex(0);
        setErrors({});
        stateFormReset(newCarFiles, setNewCarFiles);
        navigate(`/school/cars/${newCarId}`);
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        newCarToast.current.show({
          severity: "error",
          summary: "Eroare",
          detail: "Documentele nu s-au adaugat...",
          life: 3000,
        });
      });
  };

  const handleNewCarDocumentsChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setErrors({});

    const fileInputs = ["RCA", "ITP", "registration_certificate"];
    const callendarInputs = [
      "RCA_expiration_date",
      "ITP_expiration_date",
      "registration_certificate_expiration_date",
    ];

    if (callendarInputs.includes(name)) {
      const formattedDateValue = formatDate(e.target.value);
      value = formattedDateValue;
    }

    setNewCarFiles(() => {
      if (fileInputs.includes(name)) {
        const file = e.target.files[0];
        const fileUrl = file ? URL.createObjectURL(file) : "";
        const fileUrlState = name + "_url";

        console.log("AICI CRAPA", name, value, file);

        return {
          ...newCarFiles,
          [name]: file,
          [fileUrlState]: fileUrl,
        };
      } else {
        return {
          ...newCarFiles,
          [name]: value,
        };
      }
    });
  };

  const formatDate = (inputDate) => {
    let date, month, year;

    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
    date = date.toString().padStart(2, "0");
    month = month.toString().padStart(2, "0");

    return `${year}-${month}-${date}`;
  };

  const stateFormReset = (stateObject, stateSetter) => {
    for (let key in stateObject) {
      stateSetter((prevState) => {
        return {
          ...prevState,
          [key]: "",
        };
      });
    }
  };

  /* Helper variables */
  const categoryType = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
  ];

  const transmissionType = [
    { label: "Manuala", value: "manual" },
    { label: "Automata", value: "automatic" },
  ];

  const fuelType = [
    { label: "Benzina", value: "petrol" },
    { label: "Diesel", value: "diesel" },
    { label: "Hybrid", value: "hybrid" },
    { label: "Electric", value: "electric" },
    { label: "GPL", value: "gpl" },
  ];

  const newCarOptionals = [
    { name: "Aer Conditionat", code: "ac" },
    { name: "Senzori parcare fata", code: "seonzori_parcare_fata" },
    { name: "Senzori parcare spate", code: "seonzori_parcare_spate" },
    { name: "Camera spate", code: "camera" },
    { name: "Lane assist", code: "lane_assist" },
  ];

  return (
    <>
      <Toast
        ref={newCarToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <p className="bg-blue-100 text-xl font-bold m-0 text-center py-3">
        Adaugare masina noua
      </p>
      <div className="w-full px-5 pt-3 add-new-car-steps">
        <TabView
          activeIndex={newCarActiveIndex}
          onTabChange={(e) => setNewCarActiveIndex(e.index)}
        >
          <TabPanel header="Date masina">
            <div className="surface-card p-4 shadow-2 border-round flex">
              <div className="grid w-full gap-8">
                <div className="col px-5 relative">
                  {carStepTwoUnlocked && (
                    <div className="step-two-overlay">
                      <i className="pi pi-lock"></i>
                    </div>
                  )}

                  <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                    Date masina
                  </h3>

                  <form onSubmit={handleNewCarSubmit}>
                    <div className="grid formgrid p-fluid">
                      {errors.registration_number && (
                        <FormInputError errors={errors.registration_number} />
                      )}
                      <InputText
                        id="registrationNumber"
                        type="text"
                        className="mb-3"
                        name="registration_number"
                        onChange={handleNewCarFormChange}
                        value={newCar.registration_number}
                        placeholder="Numar inmatriculare"
                      />
                      {errors.make && <FormInputError errors={errors.make} />}
                      <InputText
                        id="carBrand"
                        type="text"
                        className="mb-3"
                        name="make"
                        placeholder="Marca"
                        onChange={handleNewCarFormChange}
                        value={newCar.make}
                      />
                      {errors.model && (
                        <FormInputError noMargin errors={errors.model} />
                      )}
                      <InputText
                        id="carModel"
                        type="text"
                        className="mb-3"
                        name="model"
                        placeholder="Model"
                        onChange={handleNewCarFormChange}
                        value={newCar.model}
                      />
                      {errors.VIN && <FormInputError errors={errors.VIN} />}
                      <InputText
                        id="carVin"
                        type="text"
                        className="mb-3"
                        name="VIN"
                        placeholder="VIN"
                        onChange={handleNewCarFormChange}
                        value={newCar.VIN}
                      />

                      <div className="flex w-full">
                        <div className="col pl-0">
                          {errors.license_category && (
                            <FormInputError errors={errors.license_category} />
                          )}
                          <Dropdown
                            name="license_category"
                            placeholder="Categorie"
                            options={categoryType}
                            value={newCar.license_category}
                            onChange={handleNewCarFormChange}
                            className="flex-1"
                          />
                        </div>
                        <div className="col pr-0">
                          {errors.registration_year && (
                            <FormInputError errors={errors.registration_year} />
                          )}
                          <Calendar
                            id="yearpicker"
                            value={newCar.registration_year}
                            placeholder="Anul fabricatiei"
                            onChange={handleNewCarFormChange}
                            view="year"
                            dateFormat="yy"
                            name="registration_year"
                          />
                        </div>
                      </div>

                      <Button
                        type="submit"
                        label="Adauga masina"
                        className="bg-green-400 border-0 font-medium w-10rem mt-3"
                      />
                    </div>
                  </form>
                </div>

                <div className="col px-5 relative">
                  {!carStepTwoUnlocked && (
                    <div className="step-two-overlay">
                      <i className="pi pi-lock"></i>
                    </div>
                  )}

                  <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                    Date suplimentare
                  </h3>

                  <form
                    onSubmit={handleNewCarExtrasSubmit}
                    className={!carStepTwoUnlocked ? "disabled-form" : ""}
                  >
                    <div className="flex w-full">
                      <div className="col pl-0">
                        <Dropdown
                          className="col-12 md:col-12 p-0"
                          name="transmission_type"
                          placeholder="Cutie preferata"
                          options={transmissionType}
                          value={newCarExtraData.transmission_type}
                          onChange={handleNewCarExtrasChange}
                        />
                      </div>
                      <div className="col pr-0">
                        <Dropdown
                          className="col-12 md:col-12 p-0"
                          name="fuel_type"
                          placeholder="Carburant"
                          options={fuelType}
                          value={newCarExtraData.fuel_type}
                          onChange={handleNewCarExtrasChange}
                        />
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="col pl-0">
                        <InputText
                          id="cmc"
                          type="text"
                          className="w-full"
                          name="cubic_capacity"
                          onChange={handleNewCarExtrasChange}
                          value={newCarExtraData.cubic_capacity}
                          placeholder="Capacitate cilindrica"
                        />
                      </div>
                      <div className="col pr-0">
                        <InputNumber
                          inputId="carPricePerAppointment"
                          value={newCarExtraData.appointment_price}
                          onValueChange={handleNewCarExtrasChange}
                          className="w-full"
                          placeholder="Pret sedinta"
                          name="appointment_price"
                        />
                      </div>
                    </div>

                    <div className="col px-0">
                      <MultiSelect
                        value={newCarExtraData.extra_options}
                        onChange={handleNewCarExtrasChange}
                        options={newCarOptionals}
                        optionLabel="name"
                        placeholder="Selectati optiuni masina"
                        className="w-full"
                        maxSelectedLabels={3}
                        name="extra_options"
                      />
                    </div>

                    <Button
                      type="submit"
                      label="Trimite date"
                      className="bg-green-400 border-0 font-medium w-10rem mt-2 mr-4"
                    />
                    <Button
                      id="continueCarDocuments"
                      type="submit"
                      label="Adauga acte"
                      className="bg-primary border-0 font-medium w-10rem mt-2"
                    />
                  </form>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Acte masina" disabled>
            <div className="surface-card p-4 shadow-2 border-round pb-6">
              <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                Acte doveditoare
              </h3>

              <form
                onSubmit={handleNewCarDocumentsSubmit}
                className="flex flex-wrap"
              >
                <div className="mb-1 col-12 md:col-6 flex">
                  <div className="flex flex-column mr-3">
                    <InputText
                      id="registrationNumberFile"
                      type="text"
                      name="registration_certificate_series"
                      onChange={handleNewCarDocumentsChange}
                      value={newCarFiles.registration_certificate_series}
                      placeholder="Seria / Nr. certificat inmatriculare"
                      className="mb-3"
                    />

                    <Calendar
                      id="registration_number_expiration_date"
                      value={
                        newCarFiles.registration_certificate_expiration_date
                      }
                      name="registration_certificate_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewCarDocumentsChange}
                      minDate={new Date()}
                      className="mb-3"
                    ></Calendar>

                    {/* {errors["documents.0.file"] && (
                      <FormInputError
                        errors={[
                          "Incarcati fisierul pentru certificat de inmatriculare!",
                        ]}
                      />
                    )} */}

                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="registration_certificate"
                        onChange={handleNewCarDocumentsChange}
                        className="mr-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>
                  </div>

                  <div className="document-preview">
                    {!newCarFiles.registration_certificate_url && (
                      <i className="pi pi-image"></i>
                    )}
                    <img
                      src={newCarFiles.registration_certificate_url}
                      alt="Certificat de inmatriculare"
                    />
                  </div>
                </div>

                <div className="mb-1 col-12 md:col-6 flex">
                  <div className="flex flex-column mr-3">
                    <InputText
                      id="insuranceNumber"
                      type="text"
                      name="RCA_series"
                      onChange={handleNewCarDocumentsChange}
                      value={newCarFiles.RCA_series}
                      required
                      placeholder="Seria / Nr. asigurare"
                      className="mb-3"
                    />

                    <Calendar
                      id="insuranceNumberExpirationDate"
                      value={newCarFiles.RCA_expiration_date}
                      name="RCA_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewCarDocumentsChange}
                      minDate={new Date()}
                      className="mb-3"
                    ></Calendar>

                    {/* {errors["documents.1.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru RCA!"]}
                      />
                    )} */}

                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="RCA"
                        onChange={handleNewCarDocumentsChange}
                        className="mr-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>
                  </div>

                  <div className="document-preview ">
                    {!newCarFiles.RCA_url && <i className="pi pi-image"></i>}
                    <img src={newCarFiles.RCA_url} alt="Asigurare" />
                  </div>
                </div>

                <div className="mb-1 col-12 md:col-5 flex">
                  <div className="flex flex-column mr-3">
                    <InputText
                      id="itpFile"
                      type="text"
                      name="ITP_series"
                      onChange={handleNewCarDocumentsChange}
                      value={newCarFiles.itp_series}
                      required
                      placeholder="Seria / Nr. inspectie tehnica periodica"
                      className="mb-3"
                    />

                    <Calendar
                      id="itpExpirationDate"
                      value={newCarFiles.ITP_expiration_date}
                      name="ITP_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewCarDocumentsChange}
                      minDate={new Date()}
                      className="mb-3"
                    ></Calendar>

                    {/* {errors["documents.2.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru ITP!"]}
                      />
                    )} */}

                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="ITP"
                        onChange={handleNewCarDocumentsChange}
                        className="mr-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>
                  </div>

                  <div className="document-preview">
                    {!newCarFiles.ITP_url && <i className="pi pi-image"></i>}
                    <img src={newCarFiles.ITP_url} alt="ITP" />
                  </div>
                </div>

                <div className="col-12 md:col-12">
                  <Button
                    type="submit"
                    label="Adauga acte"
                    className="bg-green-400 border-0 font-medium w-10rem"
                  />
                </div>
              </form>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default AddNewCar;
