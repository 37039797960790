import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CarsTableBtns from "./CarsTableBtns";

const Cars = () => {
  const [carsData, setCarsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCar, setSelectedCar] = useState("");
  const carsTableToast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCarsData();
  }, []);

  const fetchCarsData = async () => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const API_URL = `https://api.scoliauto.info/api/school/cars`;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    axios
      .get(API_URL, config)
      .then((res) => {
        const data = res.data;
        console.log(data);
        setCarsData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigateToSelected = (selectedCar) => {
    navigate(`/school/cars/${selectedCar.id}`);
  };

  const filterTable = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  return (
    <>
      <Toast
        ref={carsTableToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <div className="card">
        <DataTable
          value={carsData.available}
          selectionMode="single"
          onSelectionChange={(e) => navigateToSelected(e.value)}
          stripedRows
          responsiveLayout="scroll"
        >
          <Column
            field="id"
            header="ID"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="make"
            header="Marca"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="model"
            header="Model"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="registration_number"
            header="Nr. de inmatriculare"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="fuel_type"
            header="Combustibil"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="power"
            header="Putere (CP)"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="transmission_type"
            header="Cutie"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="documents.0.expiration_date"
            header="Data exp. ITP"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="documents.1.expiration_date"
            header="Data exp. RCA"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            body={CarsTableBtns}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default Cars;
