import "./FormInputError.css";

const FormInputError = ({ errors }) => {
  // console.log(errors);
  return (
    <ul className="form-error_list">
      {errors?.map((error, i) => (
        <li className="form-error_item" key={i}>
          {error}
        </li>
      ))}
    </ul>
  );
};

export default FormInputError;
