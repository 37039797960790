import axios from "axios";
import { useState, createContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

const SchoolLayoutContext = createContext();

export const SchoolLayoutProvider = ({ children }) => {
  /* Student state */
  const [addNewStudentActiveIndex, setAddNewStudentActiveIndex] = useState(0);
  const [addNewStudent, setAddNewStudent] = useState({
    newStudentBasicInfo: {
      email: "",
      phone: "",
    },
    newStudentUtils: {
      first_name: "",
      last_name: "",
      birthday: "",
      group_name: "",
      address: "",
      personal_id_number: "",
      transmission_type: "",
      driving_license_category: "",
    },
    newStudentDocuments: {
      id_card: null,
      id_card_series: "",
      id_card_expiration_date: "",
      id_card_url: "",
      criminal_record: null,
      criminal_record_series: "",
      criminal_record_expiration_date: "",
      criminal_record_url: "",
      medical_exam: null,
      medical_exam_series: "",
      medical_exam_expiration_date: "",
      medical_exam_url: "",
      psychiatrist_certificate: null,
      psychiatrist_certificate_series: "",
      psychiatrist_certificate_expiration_date: "",
      psychiatrist_certificate_url: "",
    },
    paymentInfo: {
      total_amount_to_pay: 1500,
      current_amount_payed: "",
      type: "",
    },
  });

  /* Unlocks second form in "Add new student" first step */
  const [studentStepTwoUnlocked, setStudentStepTwoUnlocked] = useState(false);
  const [errors, setErrors] = useState({});
  const [newUserId, setNewUserId] = useState("");
  const [newStudentId, setNewStudentId] = useState("");

  /* Instructor state */
  const [addNewInstructorActiveIndex, setAddNewInstructorActiveIndex] =
    useState(0);
  const [instructorStepTwoUnlocked, setInstructorStepTwoUnlocked] =
    useState(false);

  const [newInstructorId, setNewInstructorId] = useState("");

  const [newInstructor, setAddNewInstructor] = useState({
    newInstructorBasicInfo: {
      email: "",
      phone: "",
      type: "instructor",
    },
    newInstructorUtils: {
      first_name: "",
      last_name: "",
      address: "",
      personal_id_number: "",
      driver_license_seniority: "",
      has_weekend_schedule: "",
      instructorship_seniority: "",
      gender: "",
      price_per_appointment: "",
      driving_license_categories: [],
      collaboration_type: "",
    },
    newInstructorDocuments: {
      id_card: null,
      id_card_series: "",
      id_card_expiration_date: "",
      id_card_url: "",
      criminal_record: null,
      criminal_record_series: "",
      criminal_record_expiration_date: "",
      criminal_record_url: "",
      medical_exam: null,
      medical_exam_series: "",
      medical_exam_expiration_date: "",
      medical_exam_url: "",
      psychiatrist_certificate: null,
      psychiatrist_certificate_series: "",
      psychiatrist_certificate_expiration_date: "",
      psychiatrist_certificate_url: "",
      instructor_license: null,
      instructor_license_series: "",
      instructor_license_expiration_date: "",
      instructor_license_url: "",
    },
  });

  const navigate = useNavigate();

  const callendarInputs = [
    "birthday",
    "date_of_birth",
    "id_card_expiration_date",
    "criminal_record_expiration_date",
    "medical_exam_expiration_date",
    "psychiatrist_certificate_expiration_date",
    "instructor_license_expiration_date",
  ];

  const fileInputs = [
    "id_card",
    "criminal_record",
    "medical_exam",
    "psychiatrist_certificate",
    "payment_evidence",
    "instructor_license",
  ];

  /* Runs whenever an input is changed */
  /*TODO: FIND A WAY TO NORMALIZE THE DATE - .toISOString returns the date by 1 day off*/
  const handleNewStudentFormChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setErrors({});

    //Formats date
    if (callendarInputs.includes(name)) {
      const formattedDateValue = new Date(e.target.value)
        .toISOString()
        .substring(0, 10);
      value = formattedDateValue;
    }

    setAddNewStudent((prevState) => {
      if (addNewStudentActiveIndex === 0) {
        if (studentStepTwoUnlocked) {
          return {
            ...prevState,
            newStudentUtils: {
              ...prevState.newStudentUtils,
              [name]: value,
            },
          };
        } else {
          return {
            ...prevState,
            newStudentBasicInfo: {
              ...prevState.newStudentBasicInfo,
              [name]: value,
            },
          };
        }
      } else if (addNewStudentActiveIndex === 1) {
        if (fileInputs.includes(name)) {
          const file = e.target.files[0];
          const fileUrl = file ? URL.createObjectURL(file) : "";
          const fileUrlState = name + "_url";

          return {
            ...prevState,
            newStudentDocuments: {
              ...prevState.newStudentDocuments,
              [name]: file,
              [fileUrlState]: fileUrl,
            },
          };
        } else {
          return {
            ...prevState,
            newStudentDocuments: {
              ...prevState.newStudentDocuments,
              [name]: value,
            },
          };
        }
      } else if (addNewStudentActiveIndex === 2) {
        if (fileInputs.includes(name)) {
          const file = e.target.files[0];
          const fileUrl = file ? URL.createObjectURL(file) : "";
          const fileUrlState = name + "_url";

          return {
            ...prevState,
            paymentInfo: {
              ...prevState.paymentInfo,
              [name]: file,
              [fileUrlState]: fileUrl,
            },
          };
        } else {
          return {
            ...prevState,
            paymentInfo: {
              ...prevState.paymentInfo,
              [name]: value,
            },
          };
        }
      }
    });
  };

  const handleNewInstructorFormChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setErrors({});

    //Formats date
    if (callendarInputs.includes(name)) {
      const formattedDateValue = new Date(e.target.value)
        .toISOString()
        .substring(0, 10);
      value = formattedDateValue;
    }

    setAddNewInstructor((prevState) => {
      if (addNewInstructorActiveIndex === 0) {
        if (instructorStepTwoUnlocked) {
          return {
            ...prevState,
            newInstructorUtils: {
              ...prevState.newInstructorUtils,
              [name]: value,
            },
          };
        } else {
          return {
            ...prevState,
            newInstructorBasicInfo: {
              ...prevState.newInstructorBasicInfo,
              [name]: value,
            },
          };
        }
      } else {
        if (fileInputs.includes(name)) {
          const file = e.target.files[0];
          const fileUrl = file ? URL.createObjectURL(file) : "";
          const fileUrlState = name + "_url";

          return {
            ...prevState,
            newInstructorDocuments: {
              ...prevState.newInstructorDocuments,
              [name]: file,
              [fileUrlState]: fileUrl,
            },
          };
        } else {
          return {
            ...prevState,
            newInstructorDocuments: {
              ...prevState.newInstructorDocuments,
              [name]: value,
            },
          };
        }
      }
    });
  };

  /* Clear only invalid inputs after a form has been submitted */
  const clearInvalidInputs = (errors, stateProp, userType) => {
    console.log(stateProp, errors);

    const errorsArr = [];
    for (let error in errors) {
      errorsArr.push(error);
    }

    if (userType === "student") {
      const objToChange = addNewStudent[stateProp];

      for (let key in objToChange) {
        if (!errorsArr.includes(key)) {
          setAddNewStudent((prevState) => {
            return {
              ...prevState,
              [stateProp]: {
                ...prevState[stateProp],
              },
            };
          });
        } else {
          setAddNewStudent((prevState) => {
            return {
              ...prevState,
              [stateProp]: {
                ...prevState[stateProp],
                [key]: "",
              },
            };
          });
        }
      }
    }

    if (userType === "instructor") {
      const objToChange = newInstructor[stateProp];
      for (let key in objToChange) {
        if (!errorsArr.includes(key)) {
          setAddNewInstructor((prevState) => {
            return {
              ...prevState,
              [stateProp]: {
                ...prevState[stateProp],
              },
            };
          });
        } else {
          setAddNewInstructor((prevState) => {
            return {
              ...prevState,
              [stateProp]: {
                ...prevState[stateProp],
                [key]: "",
              },
            };
          });
        }
      }
    }
  };

  /* Clear an entire state object */
  const resetFormState = (stateProp, stateSetter, userType) => {
    const objToReset =
      userType === "student"
        ? addNewStudent[stateProp]
        : newInstructor[stateProp];

    for (let key in objToReset) {
      stateSetter((prevState) => {
        return {
          ...prevState,
          [stateProp]: {
            ...prevState[stateProp],
            [key]: "",
          },
        };
      });
    }
  };

  /* School layout toast */
  const schoolLayoutToast = useRef(null);
  const displayToast = (severity, summary, detail) => {
    schoolLayoutToast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  /* Used by both CREATE NEW STUDENT and CREATE NEW INSTRUCTOR */
  const createUser = async (userType) => {
    const API_URL = "https://api.scoliauto.info/api/school/users";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const requestBody =
      userType === "student"
        ? { ...addNewStudent.newStudentBasicInfo, type: "student" }
        : { ...newInstructor.newInstructorBasicInfo, type: "instructor" };

    await axios
      .post(API_URL, requestBody, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const id = response.data.data.id;

        if (userType === "student") {
          setNewUserId(id);
          setStudentStepTwoUnlocked(true);
        } else {
          setNewUserId(id);
          setInstructorStepTwoUnlocked(true);
        }

        displayToast(
          "success",
          "Succes!",
          "Utilizatorul a fost creat cu succes!"
        );

        userType === "student"
          ? resetFormState("newStudentBasicInfo", setAddNewStudent, "student")
          : resetFormState(
              "newInstructorBasicInfo",
              setAddNewInstructor,
              "instructor"
            );

        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        // console.log(error.response.data.errors);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Utilizatorul nu s-a adaugat."
        );
        userType === "student"
          ? clearInvalidInputs(
              error.response.data.errors,
              "newStudentBasicInfo",
              "student"
            )
          : clearInvalidInputs(
              error.response.data.errors,
              "newInstructorBasicInfo",
              "instructor"
            );
      });
  };

  /* Functions which run when each ADD NEW STUDENT form is submitted */
  const createStudent = async (e) => {
    const contToDocs =
      e.nativeEvent.submitter.id === "continueStudentDocuments" ? true : false;

    const API_URL = "https://api.scoliauto.info/api/school/students";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const studentData = {
      ...addNewStudent.newStudentUtils,
      user_id: newUserId,
    };

    await axios
      .post(API_URL, studentData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response);
        contToDocs && setAddNewStudentActiveIndex(1);
        displayToast("success", "Succes!", "Studentul a fost creat cu succes!");
        setStudentStepTwoUnlocked(false);
        setNewStudentId(response.data.data.id);

        resetFormState("newStudentUtils", setAddNewStudent, "student");
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Utilizatorul nu s-a adaugat."
        );
        clearInvalidInputs(
          error.response.data.errors,
          "newStudentUtils",
          "student"
        );
      });
  };

  const submitStudentFilesInfo = async (e) => {
    //Start for separating CONT WITHOUT PAYMENT/CONT WITH PAYMENT option
    const contToPayment =
      e.nativeEvent.submitter.id === "continueToPaymentBtn" ? true : false;

    const API_URL = "https://api.scoliauto.info/api/school/documents/upload";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const formData = new FormData();
    const filesToSend = [
      {
        file: addNewStudent.newStudentDocuments.id_card,
        expiration_date:
          addNewStudent.newStudentDocuments.id_card_expiration_date,
        series: addNewStudent.newStudentDocuments.id_card_series,
        type: "id_card",
      },
      {
        file: addNewStudent.newStudentDocuments.criminal_record,
        expiration_date:
          addNewStudent.newStudentDocuments.criminal_record_expiration_date,
        series: addNewStudent.newStudentDocuments.criminal_record_series,
        type: "criminal_record",
      },
      {
        file: addNewStudent.newStudentDocuments.psychiatrist_certificate,
        expiration_date:
          addNewStudent.newStudentDocuments
            .psychiatrist_certificate_expiration_date,
        series:
          addNewStudent.newStudentDocuments.psychiatrist_certificate_series,
        type: "psychiatrist_certificate",
      },
      {
        file: addNewStudent.newStudentDocuments.medical_exam,
        expiration_date:
          addNewStudent.newStudentDocuments.medical_exam_expiration_date,
        series: addNewStudent.newStudentDocuments.medical_exam_series,
        type: "medical_exam",
      },
    ];

    formData.append("user_id", newUserId);

    filesToSend.forEach((file, i) => {
      formData.append(`documents[${i}][file]`, file.file);
      formData.append(`documents[${i}][expiration_date]`, file.expiration_date);
      formData.append(`documents[${i}][series]`, file.series);
      formData.append(`documents[${i}][type]`, file.type);
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(API_URL, formData, config)
      .then((response) => {
        console.log(response.data);
        contToPayment && setAddNewStudentActiveIndex(2);
        !contToPayment && setAddNewStudentActiveIndex(0);
        resetFormState("newStudentDocuments", setAddNewStudent, "student");
        displayToast(
          "success",
          "Succes!",
          "Modificarile au fost salvate cu succes!"
        );
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        console.log(error);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Utilizatorul nu s-a adaugat."
        );
      });
  };

  const submitStudentPayment = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/payments";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const paymentInfoConfig = {
      /* //TODO: THE AMOUNT BELOW HAS TO BE CHANGED ONCE WE CAN FETCH THE AMOUNT TO BE PAYED */
      student_id: newStudentId,
      total_amount_to_pay: 1500,
      current_amount_payed: parseFloat(
        addNewStudent.paymentInfo.current_amount_payed
      ),
      type: addNewStudent.paymentInfo.type,
    };

    await axios
      .post(API_URL, paymentInfoConfig, config)
      .then((response) => {
        console.log(response.data);
        resetFormState("paymentInfo", setAddNewStudent, "student");
        setAddNewStudentActiveIndex(0);
        displayToast("success", "Succes!", "Plata a fost inregistrata!");
        navigate(`/school/students/${newStudentId}`);
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Eroare inregistrare plata...",
          "Plata nu s-a inregistrat."
        );
      });
  };

  const handleStudentStepOneSubmit = (e) => {
    e.preventDefault();
    createUser("student");
  };

  const handleStudentStepTwoSubmit = (e) => {
    e.preventDefault();
    createStudent(e);
  };

  const handleStudentStepThreeSubmit = (e) => {
    e.preventDefault();
    submitStudentFilesInfo(e);
  };

  const handleStudentStepFourSubmit = (e) => {
    e.preventDefault();
    submitStudentPayment();
  };

  /* Functions which run when each ADD NEW INSTRUCTOR form is submitted */
  const createInstructor = async (e) => {
    const contToDocs =
      e.nativeEvent.submitter.id === "continueInstructorDocuments"
        ? true
        : false;

    const API_URL = "https://api.scoliauto.info/api/school/instructors";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    const instructorLicenseArr = newInstructor.newInstructorUtils
      .driving_license_categories.length
      ? newInstructor.newInstructorUtils.driving_license_categories.map(
          (category) => category.name
        )
      : [];
    const instructorData = {
      ...newInstructor.newInstructorUtils,
      user_id: newUserId,
      driving_license_categories: instructorLicenseArr,
    };

    await axios
      .post(API_URL, instructorData, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response);
        contToDocs && setAddNewInstructorActiveIndex(1);
        displayToast(
          "success",
          "Succes!",
          "Instructorul a fost creat cu succes!"
        );
        setInstructorStepTwoUnlocked(false);
        setNewInstructorId(response.data.data.id);
        resetFormState("newInstructorUtils", setAddNewInstructor, "instructor");
        setErrors({});
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Instructorul nu s-a creat."
        );
        clearInvalidInputs(
          error.response.data.errors,
          "newInstructorUtils",
          "instructor"
        );
      });
  };

  const submitInstructorFilesInfo = async (e) => {
    const API_URL = "https://api.scoliauto.info/api/school/documents/upload";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const formData = new FormData();
    const filesToSend = [
      {
        file: newInstructor.newInstructorDocuments.id_card,
        expiration_date:
          newInstructor.newInstructorDocuments.id_card_expiration_date,
        series: newInstructor.newInstructorDocuments.id_card_series,
        type: "id_card",
      },
      {
        file: newInstructor.newInstructorDocuments.criminal_record,
        expiration_date:
          newInstructor.newInstructorDocuments.criminal_record_expiration_date,
        series: newInstructor.newInstructorDocuments.criminal_record_series,
        type: "criminal_record",
      },
      {
        file: newInstructor.newInstructorDocuments.psychiatrist_certificate,
        expiration_date:
          newInstructor.newInstructorDocuments
            .psychiatrist_certificate_expiration_date,
        series:
          newInstructor.newInstructorDocuments.psychiatrist_certificate_series,
        type: "psychiatrist_certificate",
      },
      {
        file: newInstructor.newInstructorDocuments.medical_exam,
        expiration_date:
          newInstructor.newInstructorDocuments.medical_exam_expiration_date,
        series: newInstructor.newInstructorDocuments.medical_exam_series,
        type: "medical_exam",
      },
      {
        file: newInstructor.newInstructorDocuments.instructor_license,
        expiration_date:
          newInstructor.newInstructorDocuments
            .instructor_license_expiration_date,
        series: newInstructor.newInstructorDocuments.instructor_license_series,
        type: "instructor_license",
      },
    ];

    formData.append("user_id", newUserId);

    filesToSend.forEach((file, i) => {
      formData.append(`documents[${i}][file]`, file.file);
      formData.append(`documents[${i}][expiration_date]`, file.expiration_date);
      formData.append(`documents[${i}][series]`, file.series);
      formData.append(`documents[${i}][type]`, file.type);
    });

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(API_URL, formData, config)
      .then((response) => {
        console.log(response.data);
        displayToast(
          "success",
          "Succes!",
          "Modificarile au fost salvate cu succes!"
        );
        setAddNewInstructorActiveIndex(0);
        navigate(`/school/instructors/${newInstructorId}`);
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Documentele nu s-au adaugat."
        );
      });
  };

  const handleInstructorStepOneSubmit = (e) => {
    e.preventDefault();
    createUser();
  };

  const handleInstructorStepTwoSubmit = (e) => {
    e.preventDefault();
    createInstructor(e);
  };

  const handleInstructorStepThreeSubmit = (e) => {
    e.preventDefault();
    submitInstructorFilesInfo();
  };

  return (
    <SchoolLayoutContext.Provider
      value={{
        addNewStudent,
        setAddNewStudent,
        handleNewStudentFormChange,
        addNewStudentActiveIndex,
        setAddNewStudentActiveIndex,
        newInstructor,
        setAddNewInstructor,
        handleNewInstructorFormChange,
        addNewInstructorActiveIndex,
        setAddNewInstructorActiveIndex,
        handleStudentStepOneSubmit,
        handleStudentStepTwoSubmit,
        handleStudentStepThreeSubmit,
        studentStepTwoUnlocked,
        errors,
        schoolLayoutToast,
        handleInstructorStepOneSubmit,
        instructorStepTwoUnlocked,
        handleInstructorStepTwoSubmit,
        handleInstructorStepThreeSubmit,
        handleStudentStepFourSubmit,
        resetFormState,
      }}
    >
      {children}
    </SchoolLayoutContext.Provider>
  );
};

export default SchoolLayoutContext;
