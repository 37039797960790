import HeaderStudent from "./HeaderStudent/HeaderStudent";
import FooterStudent from "./FooterStudent/FooterStudent";
import "./StudentLayout.css";

const StudentLayout = ({ props }) => {
  return (
    <>
      <HeaderStudent />
      {/*{profileIncomplete &&  window.location.pathname!=='/student/profile' ? <Navigate replace to='/student/profile'/> : ''}*/}
      <div className="body-student container">{props.children}</div>
      <FooterStudent />
    </>
  );
};

export default StudentLayout;
