import { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import FormInputError from "../../../components/Utils/FormInputError";
import CarExpensesTable from "./CarExpensesTable";
import CarServiceTable from "./CarServiceTable";
import CarDocumentsTable from "./CarDocumentsTable";
import LayoutContext from "../../../context/LayoutContext";

import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Chart } from "primereact/chart";

const Car = () => {
  const [carData, setCarData] = useState({});
  const [carPreviewActiveIndex, setCarPreviewActiveIndex] = useState(0);
  const [optionalCarDataModal, setOptionalCarDataModal] = useState(false);
  const [newExpenseModal, setNewExpenseModal] = useState(false);
  const [newMaintenanceModal, setNewMaintenanceModal] = useState(false);
  const [newCarDocumentModal, setNewCarDocumentModal] = useState(false);
  const [position, setPosition] = useState("center");
  const [optionalCarData, setOptionalCarData] = useState({
    instructors_ids: "",
    price_per_appointment: "",
    extra_options: "",
  });
  const [carInstructors, setCarInstructors] = useState([]);
  const [carDocuments, setCarDocuments] = useState({
    file: null,
    series: "",
    expiration_date: "",
    type: "",
  });
  const [documentUrl, setDocumentUrl] = useState("");
  const [fetchedCarDocuments, setFetchedCarDocuments] = useState([]);
  const [newCarExpense, setNewCarExpense] = useState({
    amount: "",
    paid_at: "",
    type: "",
  });
  const [carExpenses, setCarExpenses] = useState({});
  const [newCarMaintenance, setNewCarMaintenance] = useState({
    mileage: "",
    service_centre: "",
    expense_amount: "",
  });
  const [carMaintenances, setCarMaintenances] = useState({});

  const [errors, setErrors] = useState({});

  const { id } = useParams();
  const { userId } = useContext(LayoutContext);
  const viewCarToast = useRef(null);

  /* Modal helpers */
  const dialogFuncMap = {
    optionalCarDataModal: setOptionalCarDataModal,
    newExpenseModal: setNewExpenseModal,
    newMaintenanceModal: setNewMaintenanceModal,
    newCarDocumentModal: setNewCarDocumentModal,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  /* Helper variables */
  const chartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Sedinte",
        backgroundColor: "#42A5F5",
        data: [65, 59, 80, 81, 56, 55, 40],
      },
      {
        label: "Kilometri",
        backgroundColor: "#FFA726",
        data: [28, 48, 40, 19, 86, 27, 90],
      },
      {
        label: "Cheltuieli",
        backgroundColor: "#cc0000",
        data: [5, 10, 15, 12, 7, 30, 25],
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };

  const newCarOptionals = [
    { name: "Aer Conditionat", code: "ac" },
    { name: "Senzori parcare fata", code: "seonzori_parcare_fata" },
    { name: "Senzori parcare spate", code: "seonzori_parcare_spate" },
    { name: "Camera spate", code: "camera" },
    { name: "Lane assist", code: "lane_assist" },
  ];

  const uploadDocumentTypes = [
    { label: "RCA", value: "RCA" },
    { label: "ITP", value: "ITP" },
    {
      label: "Certificat de inmatriculare",
      value: "registration_certificate",
    },
  ];

  const expenseTypes = [
    { value: "current", label: "Recurenta" },
    { value: "periodic", label: "Periodica" },
    { value: "one_time", label: "Unica" },
  ];

  const fetchCarData = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/cars/${id}`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    await axios
      .get(API_URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const { data } = response.data;
        console.log(data);
        setCarData(data);
        setFetchedCarDocuments(data.documents);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .get("https://api.scoliauto.info/api/school/instructors", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const instructors = response.data.data;
        const instructorOptions = instructors.map((instructor) => {
          return {
            label: `${instructor.first_name} ${instructor.last_name}`,
            value: instructor.id,
          };
        });
        setCarInstructors(instructorOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* Helper functions */
  const padTo2Digits = (num) => num.toString().padStart(2, "0");
  const formatDate = (date) => {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  };

  /* Car modals handlers */
  const handleOptionalCarInfoChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setOptionalCarData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleOptionalCarInfoUpdate = (e) => {
    const API_URL = `https://api.scoliauto.info/api/school/cars/${id}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));

    const parsedExtraOptions = optionalCarData.extra_options.length
      ? optionalCarData.extra_options.map((option) => option.name)
      : [];

    const objToSend = {
      ...optionalCarData,
      extra_options: parsedExtraOptions,
      instructors_ids: [optionalCarData.instructors_ids],
    };

    axios
      .patch(API_URL, objToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        viewCarToast.current.show({
          severity: "success",
          detail: "Masina a fost actualizata!",
          life: 3000,
        });
        setCarData(res.data.data);
        onHide("optionalCarDataModal");
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        viewCarToast.current.show({
          severity: "error",
          summary: "Eroare",
          detail: "Masina nu s-a actualizat...",
          life: 3000,
        });
        console.log(error);
      });
  };

  const handleOptionalCarInfoSubmit = (e) => {
    e.preventDefault();
    handleOptionalCarInfoUpdate();
  };

  /* CAR EXPENSES TAB */

  /* Fetch car expense data */
  const getCarExpenses = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/car_expenses?car_id=${id}`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    await axios
      .get(API_URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const { data } = response.data;
        setCarExpenses(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewCarExpenseChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "paid_at") {
      const formattedDateValue = formatDate(new Date(e.target.value));
      value = formattedDateValue;
    }

    setErrors({});

    setNewCarExpense((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleNewCarExpenseSubmit = (e) => {
    e.preventDefault();
    console.log(newCarExpense);
    createCarExpense();
  };

  const createCarExpense = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/car_expenses`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const objToSend = {
      ...newCarExpense,
      author_user_id: userId,
      car_id: id,
    };

    await axios
      .post(API_URL, objToSend, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(() => {
        onHide("newExpenseModal");
        setNewCarExpense({});
        getCarExpenses();
        viewCarToast.current.show({
          severity: "success",
          detail: "Revizia a fost adaugata!",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        viewCarToast.current.show({
          severity: "error",
          detail: "Revizia nu s-a adaugat...",
          life: 3000,
        });
      });
  };

  /* CAR MAINTENANCES TAB */

  /* Fetch car maintenances data */
  const getCarMaintenances = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/car_maintenances?car_id=${id}`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    await axios
      .get(API_URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        const { data } = response.data;
        console.log(data);
        setCarMaintenances(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewCarMaintenanceChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setErrors({});

    setNewCarMaintenance((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleNewCarMaintenanceSubmit = (e) => {
    e.preventDefault();
    createCarMaintenance();
  };

  const createCarMaintenance = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/car_maintenances`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const objToSend = {
      ...newCarMaintenance,
      user_id: userId,
      car_id: id,
    };

    await axios
      .post(API_URL, objToSend, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(() => {
        onHide("newMaintenanceModal");
        setNewCarMaintenance({});
        getCarMaintenances();
        viewCarToast.current.show({
          severity: "success",
          detail: "Revizia a fost adaugata",
          life: 3000,
        });
      })
      .catch((error) => {
        // console.log(error);
        setErrors(error.response.data.errors);
        viewCarToast.current.show({
          severity: "error",
          detail: "Revizia nu s-a adaugat...",
          life: 3000,
        });
      });
  };

  /* CAR DOCUMENTS TAB */
  const onCarDocumentsChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setErrors({});

    if (name === "expiration_date") {
      const formattedDateValue = new Date(e.target.value)
        .toISOString()
        .substring(0, 10);
      value = formattedDateValue;
    }

    setCarDocuments((prevState) => {
      if (name === "file") {
        const file = e.target.files[0];
        const fileUrl = URL.createObjectURL(file);
        setDocumentUrl(fileUrl);

        return {
          ...prevState,
          file,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleCarDocumentsSubmit = async (e) => {
    e.preventDefault();

    const API_URL =
      "https://api.scoliauto.info/api/school/documents/car/upload";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const formData = new FormData();
    formData.append("car_id", id);
    formData.append(`documents[0][file]`, carDocuments.file);
    formData.append(
      `documents[0][expiration_date]`,
      carDocuments.expiration_date
    );
    formData.append(`documents[0][series]`, carDocuments.series);
    formData.append(`documents[0][type]`, carDocuments.type);

    await axios
      .post(API_URL, formData, config)
      .then((response) => {
        const { data } = response.data;
        console.log(data);
        onHide("newCarDocumentModal");
        setCarDocuments({});
        setDocumentUrl("");
        fetchCarData();
        viewCarToast.current.show({
          severity: "success",
          detail: "Actul a fost adaugat!",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        viewCarToast.current.show({
          severity: "error",
          detail: "Documentul nu s-a adaugat...",
          life: 3000,
        });
      });
  };

  /* Render helpers */
  const renderCarOptions = () => {
    if (carData.extra_options) {
      const parsedOptions =
        typeof carData.extra_options === "string"
          ? JSON.parse(carData.extra_options)
          : carData.extra_options;
      if (parsedOptions.length) {
        return parsedOptions.map((option, i) => {
          return (
            <span className="mr-2" key={`car-option-${i}`}>
              {option},
            </span>
          );
        });
      } else {
        return <span className="flex-grow-1 text-right"> - </span>;
      }
    }
  };

  useEffect(() => {
    fetchCarData();
  }, []);

  return (
    <div className="w-full px-5 pt-3 view-car">
      <Toast
        ref={viewCarToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <TabView
        activeIndex={carPreviewActiveIndex}
        onTabChange={(e) => setCarPreviewActiveIndex(e.index)}
      >
        <TabPanel header="Date generale">
          <div className="surface-card p-4 shadow-2 border-round flex mb-5">
            <div className="col px-5">
              <h3 className="card-title border-bottom-1 border-200 pb-3 mr-4">
                Date generale
              </h3>
              <div className="flex flex-wrap gap-4">
                <div className="col-3 car-picture flex align-items-center justify-content-center">
                  <i className="pi pi-car text-8xl"></i>
                </div>

                <div className="col-4">
                  <div className="surface-card p-4 shadow-2 border-round">
                    <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between">
                      <span className="card-title font-bold text-lg">
                        Date inmatriculare
                      </span>
                    </div>

                    <div className="card-body">
                      <ul className="list-none p-3 m-0">
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>VIN:</span>
                          <span className="text-right">{carData.VIN}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Numar inmatriculare:</span>
                          <span>{carData.registration_number}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Marca/Model:</span>
                          <span>
                            {carData.make} {carData.model}
                          </span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Categorie:</span>
                          <span>{carData.license_caregory || "-"}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>An fabricatie:</span>
                          <span>{carData.registration_year}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Volum (cmc):</span>
                          <span>{carData.cubic_capacity}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Combustibil:</span>
                          <span>{carData.fuel_type}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="surface-card p-4 shadow-2 border-round">
                    <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                      <span className="card-title font-bold text-lg">
                        Date suplimentare
                      </span>

                      <Button
                        label="Modifica"
                        onClick={() => onClick("optionalCarDataModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                      />
                      <Dialog
                        header="Editeaza datele suplimentare ale masinii"
                        visible={optionalCarDataModal}
                        style={{ width: "30vw" }}
                        onHide={() => onHide("optionalCarDataModal")}
                        headerStyle={{ textAlign: "center" }}
                      >
                        <form
                          onSubmit={handleOptionalCarInfoSubmit}
                          className="surface-50 p-4 border-round-lg"
                        >
                          <div className="formgrid p-fluid">
                            <div className="form-group">
                              <label
                                htmlFor="editCarInstructor"
                                className="font-medium text-600 ml-2"
                              >
                                Instructor
                              </label>
                              <Dropdown
                                id="editCarInstructor"
                                options={carInstructors}
                                className="mb-3"
                                name="instructors_ids"
                                onChange={handleOptionalCarInfoChange}
                                value={optionalCarData.instructors_ids}
                                placeholder="Instructor"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="editCarAppointmentPrice"
                                className="font-medium text-600 ml-2"
                              >
                                Pret pe sedinta
                              </label>
                              <InputNumber
                                id="editCarAppointmentPrice"
                                className="mb-3"
                                name="price_per_appointment"
                                onValueChange={handleOptionalCarInfoChange}
                                value={optionalCarData.price_per_appointment}
                                placeholder="Pret sedinta"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="carExtraOptions"
                                className="font-medium text-600 ml-2"
                              >
                                Optiuni
                              </label>
                              <MultiSelect
                                id="carExtraOptions"
                                value={optionalCarData.extra_options}
                                onChange={handleOptionalCarInfoChange}
                                options={newCarOptionals}
                                optionLabel="name"
                                placeholder="Selectati optiuni masina"
                                className="w-full"
                                maxSelectedLabels={3}
                                name="extra_options"
                              />
                            </div>
                          </div>
                          <Button
                            type="submit"
                            label="Actualizeaza date"
                            className="block mx-auto mt-4"
                          />
                        </form>
                      </Dialog>
                    </div>

                    <div className="card-body">
                      <ul className="list-none p-3 m-0">
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Kilometraj total:</span>
                          <span>{carData.mileage}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Acte expirate:</span>
                          <span>{carData.expired_documents_count}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Instructor:</span>
                          {carData.instructors?.length > 0
                            ? `${carData.instructors[0].last_name} ${carData.instructors[0].first_name}`
                            : "-"}
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Pret sedinta:</span>
                          <span>{carData.price_per_appointment || "-"}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Urmatoarea revizie tehnica:</span>
                          <span>-</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                          <span>Transmisie:</span>
                          <span>{carData.transmission_type}</span>
                        </li>
                        <li className="border-bottom-1 border-200 py-3 flex flex-wrap">
                          <span className="mr-2">Dotari:</span>
                          {renderCarOptions()}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="surface-card surface-card p-4 shadow-2 border-round flex flex-wrap justify-content-center">
            <span className="col-12 card-title font-bold text-lg mb-4">
              Statistici masina IS-01-ABC
            </span>

            <div className="col-5 surface-card p-4 shadow-2 border-round mr-5">
              <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between card-title font-bold text-lg">
                Statistici luna curenta
              </div>

              <div className="card-body">
                <ul className="list-none p-3 m-0">
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Rulaj (luna precedenta):</span>
                    <span>0</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Numar sedinte:</span>
                    <span>43</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Numar elevi activi:</span>
                    <span>12</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Cheltuieli:</span>
                    <span>780</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-5 surface-card p-4 shadow-2 border-round">
              <div className="card-header border-bottom-1 border-200 pb-3 card-title font-bold text-lg">
                Grafic
              </div>

              <div className="card-body">
                <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel header="Cheltuieli">
          <div className="surface-card shadow-2 border-round flex flex-wrap justify-content-center">
            <div className="card-header col px-5 flex justify-content-between align-items-center">
              <h3 className="card-title border-bottom-1 border-200 pb-3 flex-1 mr-4">
                Cheltuieli
              </h3>

              <Button
                label="Adauga cheltuiala"
                onClick={() => onClick("newExpenseModal")}
                className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
              />
              <Dialog
                header="Adauga o cheltuiala noua"
                visible={newExpenseModal}
                style={{ width: "30vw" }}
                onHide={() => onHide("newExpenseModal")}
                headerStyle={{ textAlign: "center" }}
              >
                <form
                  onSubmit={handleNewCarExpenseSubmit}
                  className="surface-50 p-4 border-round-lg"
                >
                  <div className="formgrid p-fluid">
                    <div className="form-group">
                      {errors?.type && <FormInputError errors={errors?.type} />}
                      <label
                        htmlFor="expenseType"
                        className="font-medium text-600 ml-2"
                      >
                        Tip cheltuiala
                      </label>
                      <Dropdown
                        id="expenseType"
                        options={expenseTypes}
                        className="mb-3"
                        name="type"
                        onChange={handleNewCarExpenseChange}
                        value={newCarExpense.type}
                        placeholder="Tip cheltuiala"
                      />
                    </div>

                    <div className="form-group">
                      {errors?.amount && (
                        <FormInputError errors={errors.amount} />
                      )}

                      <label
                        htmlFor="newExpenseAmount"
                        className="font-medium text-600 ml-2"
                      >
                        Suma
                      </label>

                      <InputNumber
                        id="newExpenseAmount"
                        className="mb-3"
                        name="amount"
                        onValueChange={handleNewCarExpenseChange}
                        value={newCarExpense.amount}
                        placeholder="Suma"
                      />
                    </div>

                    <div className="form-group">
                      {errors?.paid_at && (
                        <FormInputError errors={errors?.paid_at} />
                      )}

                      <label
                        htmlFor="newCarExpenseDate"
                        className="font-medium text-600 ml-2"
                      >
                        Data realizarii
                      </label>
                      <Calendar
                        id="newCarExpenseDate"
                        value={newCarExpense.paid_at}
                        name="paid_at"
                        placeholder="Data realizarii"
                        dateFormat="yy/mm/dd"
                        showIcon={true}
                        onChange={handleNewCarExpenseChange}
                        className="mb-3"
                        maxDate={new Date()}
                      ></Calendar>
                    </div>
                  </div>
                  <Button className="block mx-auto mt-4">
                    Adauga cheltuiala
                  </Button>
                </form>
              </Dialog>
            </div>

            <CarExpensesTable
              getCarExpenses={getCarExpenses}
              carExpenses={carExpenses}
            />
          </div>
        </TabPanel>

        <TabPanel header="Revizii tehnice">
          <div className="surface-card shadow-2 border-round flex flex-wrap justify-content-center">
            <div className="card-header col px-5 flex justify-content-between align-items-center flex-auto">
              <h3 className="card-title border-bottom-1 border-200 pb-3 flex-1 mr-4">
                Revizii tehnice
              </h3>
              <Button
                label="Adauga revizie"
                onClick={() => onClick("newMaintenanceModal")}
                className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
              />
              <Dialog
                header="Adauga o revizie noua"
                visible={newMaintenanceModal}
                style={{ width: "30vw" }}
                onHide={() => onHide("newMaintenanceModal")}
                headerStyle={{ textAlign: "center" }}
              >
                <form
                  onSubmit={handleNewCarMaintenanceSubmit}
                  className="surface-50 p-4 border-round-lg"
                >
                  <div className="formgrid p-fluid">
                    <div className="form-group">
                      {errors?.mileage && (
                        <FormInputError errors={errors.mileage} />
                      )}
                      <label
                        htmlFor="expenseType"
                        className="font-medium text-600 ml-2"
                      >
                        Kilometraj:
                      </label>
                      <InputNumber
                        id="newMaintenanceMileage"
                        className="mb-3"
                        name="mileage"
                        onValueChange={handleNewCarMaintenanceChange}
                        value={newCarMaintenance.mileage}
                        placeholder="Kilometraj"
                      />
                    </div>

                    <div className="form-group">
                      {errors?.service_centre && (
                        <FormInputError errors={errors.service_centre} />
                      )}

                      <label
                        htmlFor="newCarServiceCentre"
                        className="font-medium text-600 ml-2"
                      >
                        Service
                      </label>

                      <InputText
                        id="newCarServiceCentre"
                        className="mb-3"
                        name="service_centre"
                        onChange={handleNewCarMaintenanceChange}
                        value={newCarMaintenance.service_centre}
                        placeholder="Nume service"
                      />
                    </div>

                    <div className="form-group">
                      {errors?.expense_amount && (
                        <FormInputError errors={errors.expense_amount} />
                      )}

                      <label
                        htmlFor="newCarMaintenanceAmount"
                        className="font-medium text-600 ml-2"
                      >
                        Costul reviziei
                      </label>
                      <InputNumber
                        id="newCarMaintenanceAmount"
                        value={newCarMaintenance.expense_amount}
                        name="expense_amount"
                        placeholder="Costul reviziei"
                        onValueChange={handleNewCarMaintenanceChange}
                      />
                    </div>
                  </div>
                  <Button className="block mx-auto mt-4">Adauga revizie</Button>
                </form>
              </Dialog>
            </div>
            <CarServiceTable
              getCarMaintenances={getCarMaintenances}
              carMaintenances={carMaintenances}
            />
          </div>
        </TabPanel>

        <TabPanel header="Acte">
          <div className="surface-card shadow-2 border-round flex flex-wrap justify-content-center">
            <div className="card-header col px-5 flex justify-content-between align-items-center flex-auto">
              <h3 className="card-title border-bottom-1 border-200 pb-3 flex-1 mr-4">
                Acte
              </h3>
              <Button
                label="Adauga act"
                onClick={() => onClick("newCarDocumentModal")}
                className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
              />
              <Dialog
                header="Adauga document nou"
                visible={newCarDocumentModal}
                style={{ width: "30vw" }}
                onHide={() => onHide("newCarDocumentModal")}
                headerStyle={{ textAlign: "center" }}
              >
                <form
                  onSubmit={handleCarDocumentsSubmit}
                  className="flex flex-column surface-50 p-4 border-round-lg"
                >
                  {errors["documents.0.type"] && (
                    <FormInputError errors={errors["documents.0.type"]} />
                  )}
                  <Dropdown
                    options={uploadDocumentTypes}
                    placeholder="Tipul actului"
                    name="type"
                    className="h-3rem mb-3"
                    value={carDocuments.type}
                    onChange={onCarDocumentsChange}
                  />

                  {errors["documents.0.series"] && (
                    <FormInputError errors={errors["documents.0.series"]} />
                  )}

                  <InputText
                    type="text"
                    name="series"
                    onChange={onCarDocumentsChange}
                    value={carDocuments.series}
                    placeholder="Seria actului"
                    className="mb-3"
                  />

                  <Calendar
                    id="file_expiration_date"
                    value={carDocuments.expiration_date}
                    name="expiration_date"
                    placeholder="Data expirarii"
                    dateFormat="dd/mm/yy"
                    showIcon={true}
                    onChange={onCarDocumentsChange}
                    className="mb-3"
                    minDate={new Date()}
                  ></Calendar>

                  {errors["documents.0.file"] && (
                    <FormInputError errors={errors["documents.0.file"]} />
                  )}
                  <label className="custom-file-upload w-max">
                    <input
                      type="file"
                      name="file"
                      onChange={onCarDocumentsChange}
                      className="mb-3"
                    />
                    <i className="pi pi-file"></i> Incarca
                  </label>

                  <div className="document-preview mt-3">
                    {!documentUrl && <i className="pi pi-image"></i>}
                    <img src={documentUrl} alt="Buletin" />
                  </div>

                  <Button className="block mx-auto mt-4">Adauga act</Button>
                </form>
              </Dialog>
            </div>
            <CarDocumentsTable carDocuments={fetchedCarDocuments} />
          </div>
        </TabPanel>

        <TabPanel header="Programari">
          <div className="surface-card p-4 shadow-2 border-round flex">
            <div className="col px-5">
              <h3 className="card-title border-bottom-1 border-200 pb-3 mr-4">
                Programari
              </h3>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default Car;
