import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import InstructorTableBtns from "./InstructorTableBtns";
import TablesExpirationDates from "../TablesExpirationDates";

const InstructorsTable = () => {
  const [instructors, setInstructors] = useState({});
  const navigate = useNavigate();

  const fetchAllInstructors = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/instructors";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    await axios
      .get(API_URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data.data);
        setInstructors(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        instructorsTableToast.current.show({
          severity: "error",
          summary: "Eroare",
          detail: "Nu exista instructori!",
          life: 3000,
        });
      });
  };

  const navigateToSelected = (instructor) => {
    navigate(`/school/instructors/${instructor.id}`);
  };

  useEffect(() => {
    fetchAllInstructors();
  }, []);

  const filterTable = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  const instructorsTableToast = useRef(null);

  return (
    <>
      <Toast
        ref={instructorsTableToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <div className="card">
        <DataTable
          value={instructors}
          selectionMode="single"
          onSelectionChange={(e) => navigateToSelected(e.value)}
          stripedRows
          responsiveLayout="scroll"
        >
          <Column
            field="id"
            header="ID"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="last_name"
            header="Nume"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="first_name"
            header="Prenume"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="birthday"
            header="Zi de nastere"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="address"
            header="Adresa"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="criminal_record_expiration_date"
            header="Data exp. cazier"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return (
                <TablesExpirationDates
                  type="criminal_record"
                  rowData={rowData}
                />
              );
            }}
          ></Column>
          <Column
            field="medical_exam_expiration_date"
            header="Data exp. aviz medical"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return (
                <TablesExpirationDates type="medical_exam" rowData={rowData} />
              );
            }}
          ></Column>
          <Column
            field="psychiatrist_certificate_expiration_date"
            header="Data exp. aviz psihologic"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return (
                <TablesExpirationDates
                  type="psychiatrist_certificate"
                  rowData={rowData}
                />
              );
            }}
          ></Column>
          <Column
            field="id_card_expiration_date"
            header="Data exp. buletin"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return <TablesExpirationDates type="id_card" rowData={rowData} />;
            }}
          ></Column>
          <Column
            body={InstructorTableBtns}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default InstructorsTable;
