import React from "react";
import { Link } from "react-router-dom";
import "./SidebarSchool.css";
import {school} from '../../../utils/lang/ro/layout'


const SidebarSchool = () => {
  return (
    <nav className="lg:h-full flex surface-ground sidebar-nav">
      <div
        id="app-sidebar-2"
        className="surface-section h-screen hidden lg:block flex-shrink-0 border-right-1 surface-border select-none sidebar-shool"
      >
        <div className="flex flex-column h-full">
          <div className="flex align-items-center logo-container">
            <Link to="school" className="sidebar-logo" />
          </div>
          <div className="overflow-y-auto">
            <ul className="list-none p-3 m-0">
              <li>
                <div className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer">
                  <span className="font-medium">{school.school}</span>
                </div>
                <ul className="list-none p-0 m-0 overflow-hidden">
                  <li>
                    <Link
                      to="school/all-driving-schedules"
                      className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-calendar mr-2"></i>
                      <span className="font-medium">{school.appointments}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/school/students"
                      className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-book mr-2"></i>
                      <span className="font-medium">{school.students}</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/school/instructors"
                      className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-car mr-2"></i>
                      <span className="font-medium">{school.instructors}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="list-none p-3 m-0">
              <li>
                <div className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer">
                  <span className="font-medium">{school.administration}</span>
                </div>
                <ul className="list-none p-0 m-0 overflow-hidden">
                  <li>
                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                      <i className="pi pi-folder mr-2"></i>
                      <span className="font-medium">{school.payments}</span>
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/school/cars"
                      className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-car mr-2"></i>
                      <span className="font-medium">{school.cars}</span>
                    </Link>
                  </li>
                  <li>
                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                      <i className="pi pi-cog mr-2"></i>
                      <span className="font-medium">{school.messages}</span>
                    </a>
                  </li>
                  <li>
                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                      <i className="pi pi-cog mr-2"></i>
                      <span className="font-medium">{school.courses}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="list-none p-3 m-0">
              <li>
                <div className="p-ripple p-3 flex align-items-center justify-content-between text-600 cursor-pointer">
                  <span className="font-medium">{school.settings}</span>
                </div>
                <ul className="list-none p-0 m-0 overflow-hidden">
                  <li>
                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                      <i className="pi pi-folder mr-2"></i>
                      <span className="font-medium">{school.profile}</span>
                    </a>
                  </li>
                  <li>
                    <a className="p-ripple flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full">
                      <i className="pi pi-chart-bar mr-2"></i>
                      <span className="font-medium">{school.application}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SidebarSchool;
