import { Routes } from "react-router-dom";
import { SchoolRoutes } from "../../routes";

import FooterSchool from "./FooterSchool/FooterSchool";
import SidebarSchool from "./SidebarSchool/SidebarSchool";
import HeaderSchool from "./HeaderSchool/HeaderSchool";

import { SchoolLayoutProvider } from "../../context/SchoolLayoutContext";

const SchoolLayout = (props) => {
  return (
    <SchoolLayoutProvider>
      <div className="flex">
        <SidebarSchool />

        <div className="w-full">
          <HeaderSchool />
          <Routes>{SchoolRoutes.map((route) => route)}</Routes>
          {props.children}
        </div>
      </div>
      <FooterSchool />
    </SchoolLayoutProvider>
  );
};

export default SchoolLayout;
