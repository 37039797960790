import "./CardStudent.css";
import {cardStudent} from "../../../utils/lang/ro/components"

const CardStudent = () => {
  return (
    <div className="student-card">
      <div className="student-card-header flex justify-content-between px-2 py-3">
        <div className="student-card-btn-group flex flex-column align-content-center justify-content-center text-center">
          <span className="text-sm">{cardStudent.licenceCategory}</span>
          <button className="student-card-btn license-category-btn mt-2">
            <i className="pi pi-user-edit font-semibold text-xl mb-2"></i>
            {cardStudent.edit}
          </button>
        </div>
        <div className="student-card-title text-center font-semibold text-2xl">
          Cristina Avram
        </div>
        <div className="student-card-btn-group flex flex-column align-content-center justify-content-center text-center">
          <span className="text-sm">{cardStudent.group}</span>
          <button className="student-card-btn study-group-btn mt-2">
            <i className="pi pi-question-circle font-semibold text-xl mb-2"></i>
            {cardStudent.forming}
          </button>
        </div>
      </div>
      <div className="student-card-body grid max-w-full px-2 py-3">
        <div className="col-3 completed-courses-counter flex flex-column align-content-center justify-content-center text-center text-xs">
          {cardStudent.theory}
          <span className="text-xl font-semibold">0 {cardStudent.of} 26</span>
        </div>

        <div className="avatar-container relative">
          <button type="file" className="avatar-upload-btn absolute">
            <i className="pi pi-camera text-2xl"></i>
          </button>
        </div>

        <div className="col-3 completed-courses-counter flex flex-column align-content-center justify-content-center text-center text-xs">
          {cardStudent.driving}
          <span className="text-xl font-semibold">0 {cardStudent.of} 15</span>
        </div>

        <div className="grid flex-auto action-btns">
          <button className="col-4 notifications-btn">
            <i className="pi pi-bell text-xl mb-2"></i>
            {cardStudent.notifications}
          </button>
          <button className="col-4 settings-btn mt-5">
            <i className="pi pi-save text-xl mb-2"></i> {cardStudent.settings}
          </button>
          <button className="col-4 messages-btn">
            <i className="pi pi-book text-xl mb-2"></i> {cardStudent.messages}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardStudent;
