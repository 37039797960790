import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const CarServiceTable = ({ getCarMaintenances, carMaintenances }) => {
  const [newServiceReceiptModal, setNewServiceReceiptModal] = useState(false);

  const filterTable = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  /* Modal helpers */
  const dialogFuncMap = {
    newServiceReceiptModal: setNewServiceReceiptModal,
  };

  const onClick = (name, data) => {
    dialogFuncMap[`${name}`](true);
    // console.log(data);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  useEffect(() => {
    getCarMaintenances();
  }, []);

  return (
    <div className="card w-full">
      <Dialog
        header="Adauga revizie tehnica"
        visible={newServiceReceiptModal}
        style={{ width: "30vw" }}
        onHide={() => onHide("newServiceReceiptModal")}
        headerStyle={{ textAlign: "center" }}
      >
        <p>Formular chitanta revizie noua</p>
      </Dialog>

      <DataTable
        value={carMaintenances}
        selectionMode="single"
        stripedRows
        responsiveLayout="scroll"
      >
        <Column
          field="id"
          header="ID Cheltuiala"
          sortable
          filter={true}
          filterFunction={filterTable}
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
        ></Column>
        <Column
          field="user_id"
          header="ID User"
          sortable
          filter={true}
          filterFunction={filterTable}
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
        ></Column>
        <Column
          field="created_at"
          header="Data"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="mileage"
          header="Rulaj"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="service_centre"
          header="Service auto"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>

        <Column
          field="expense_amount"
          header="Suma"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="updated_at"
          header="Data actualizarii"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>

        <Column
          header="Chitanta"
          body={(rowData) => {
            return (
              <Button
                label="Adauga"
                onClick={() => onClick("newServiceReceiptModal", rowData)}
                className="p-button-link"
              />
            );
          }}
        ></Column>
        {/* <Column
          body={StudentTableBtns}
          exportable={false}
          style={{ minWidth: "8rem" }}
        ></Column> */}
      </DataTable>
    </div>
  );
};

export default CarServiceTable;
