import SchoolHomeExamsTable from "../../../components/school/SchoolHomeExamsTable";
import SchoolHomeStatistics from "../../../components/school/SchoolHomeStatistics";
import SchoolHomeProgress from "../../../components/school/SchoolHomeProgress";

const SchoolHome = () => {
  return (
    <div className="w-full">
      <SchoolHomeStatistics />
      <SchoolHomeExamsTable />
      <SchoolHomeProgress />
    </div>
  );
};

export default SchoolHome;
