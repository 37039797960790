const CarsDropdown = ({ cars, decontCar, onDecontChange }) => {
  return (
    <select
      name="car_id"
      id="carDropdown"
      className="cars_select"
      value={decontCar}
      onChange={onDecontChange}
    >
      {cars.map((car) => (
        <option key={car.registration_number} value={car.value}>
          {car.registration_number}
        </option>
      ))}
    </select>
  );
};

export default CarsDropdown;
