import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

import "./Buttons.css";
import {label} from "../../utils/lang/ro/components";

const Logout = () => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    window.localStorage.removeItem("accessToken");
    navigate("/login", { replace: true });
    window.location.reload();
  };

  return (
    <Button
      className="p-button-secondary mr-5"
      icon="pi pi-sign-out"
      label={label.logout}
      onClick={handleClick}
    />
  );
};

export default Logout;
