import { useState } from "react";
import RoadmapStepDetail from "./RoadmapStepDetail";
import { Steps } from "primereact/steps";
import "./StudentRoadmap.css";
import {roadmap} from "../../utils/lang/ro/components";

const StudentRoadmap = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const items = [
    {
      label: "Completare date",
      // label: roadmap.data,
      command: (event) => {
        console.log(event);
      },
    },
    {
      label: "Adaugare acte",
      // label: roadmap.docs,

      command: (event) => {
        console.log(event);
      },
    },
    {
      label: "Efectuare plata",
      // label: roadmap.payment,
      command: (event) => {
        console.log(event);
      },
    },
    {
      label: "Legislatie",
      // label: roadmap.law,
      command: (event) => {
        console.log(event);
      },
    },
    {
      label: "Conducere",
      // label: roadmap.driving,
      command: (event) => {
        console.log(event);
      },
    },
    {
      // label: "Examen",
      label: roadmap.exam,
      command: (event) => {
        console.log(event);
      },
    },
  ];

  return (
    <>
      <div className="roadmap-card px-3 py-4 my-3">
        <h3 className="roadmap-card-title">{roadmap.steps}</h3>
        <div className="title-separator"></div>
        <div className="roadmap-steps">
          <Steps
            model={items}
            activeIndex={activeIndex}
            onSelect={(e) => setActiveIndex(e.index)}
            readOnly={false}
          />
        </div>
      </div>

      <RoadmapStepDetail stepIndex={activeIndex} />
    </>
  );
};

export default StudentRoadmap;
