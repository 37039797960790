//Global state file
import { useState, useEffect, createContext } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    user: "",
    accessToken: "",
    profileIncomplete: "",
  });

  useEffect(() => {
    const user = localStorage.getItem("user") || "user";
    const accessToken = localStorage.getItem("accessToken");
    //Condition False in order to hide the profileIncomplete header
    const profileIncomplete =
      localStorage.getItem("profileIncomplete") || false;

    setGlobalState((prevState) => ({
      ...prevState,
      user,
      accessToken,
      profileIncomplete,
    }));
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        setGlobalState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
