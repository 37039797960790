const TablesExpirationDates = ({ type, rowData }) => {
  let data;

  if (rowData.documents.length) {
    rowData.documents.forEach((doc) => {
      if (doc.type == type) {
        data = doc.expiration_date;
      }
    });
  }
  return <>{data}</>;
};

export default TablesExpirationDates;
