import React from "react";
import styles from "./CardCar.module.css";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import {cardCar} from "../../../utils/lang/ro/components";

function CardCar(props) {
  return (
    <div className="surface-card shadow-2 border-rounded p-2 m-2">
      <div className="flex flex-column align-items-center border-bottom-1 surface-border pb-3">
        <img src={props.image} className="mb-3 w-9" alt={props.model} />
        <span className="text-lg text-900 font-medium mb-2">{props.model}</span>
        <span className="text-600 font-medium mb-3">{props.color}</span>
        <span className="text-600 font-medium mb-3">{props.gear}</span>
        <span className="text-2xl text-800 block mb-3 font-semibold">
          {props.plate}
        </span>
        <Rating value={props.rating} readonly stars={5} cancel={false} />
      </div>
      <ul className="list-none m-0 p-0">
        <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
          <span className="text-600 font-medium text-sm">{cardCar.prodYear}</span>
          <span className="text-900 font-medium text-sm">{props.year}</span>
        </li>
        <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
          <span className="text-600 font-medium text-sm">{cardCar.ac}</span>
          <span className="text-900 font-medium text-sm">{props.ac}</span>
        </li>
        <li className="px-0 py-2 flex justify-content-between align-items-center">
          <span className="text-600 font-medium text-sm">{cardCar.camera}</span>
          <span className="text-900 font-medium text-sm">{props.camera}</span>
        </li>
      </ul>
      <div className="flex pt-3 justify-content-between align-items-center">
        <Button
          icon="pi pi-calendar"
          label={props.actionbtn}
          className="p-button-text"
        />
        <Button
          icon="pi pi-heart"
          className="p-button-text p-button-secondary"
        />
      </div>
    </div>
  );
}
export default CardCar;
