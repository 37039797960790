import { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Image } from "primereact/image";

const CarDocumentsTable = ({ carDocuments }) => {
  const [newReceiptModal, setNewReceiptModal] = useState(false);

  const filterTable = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <Image
        src={rowData.file_url}
        preview
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };

  /* Modal helpers */
  const dialogFuncMap = {
    newReceiptModal: setNewReceiptModal,
  };

  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  return (
    <div className="card w-full">
      <Dialog
        header="Adauga chitanta"
        visible={newReceiptModal}
        style={{ width: "30vw" }}
        onHide={() => onHide("newReceiptModal")}
        headerStyle={{ textAlign: "center" }}
      >
        <p>Adauga bon</p>
      </Dialog>

      <DataTable
        value={carDocuments}
        selectionMode="single"
        stripedRows
        responsiveLayout="scroll"
      >
        <Column
          field="type"
          header="Tipul"
          sortable
          filter={true}
          filterFunction={filterTable}
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
        ></Column>
        <Column
          field="series"
          header="Seria"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column
          field="expiration_date"
          header="Data de expirare"
          sortable
          filter={true}
          filterFunction={filterTable}
        ></Column>
        <Column header="Image" body={imageBodyTemplate}></Column>
        <Column
          header="Copie bon"
          body={(rowData) => {
            return (
              <Button
                label="Adauga"
                onClick={() => onClick("newReceiptModal", rowData)}
                className="p-button-link"
              />
            );
          }}
        ></Column>
        {/* <Column
          body={StudentTableBtns}
          exportable={false}
          style={{ minWidth: "8rem" }}
        ></Column> */}
      </DataTable>
    </div>
  );
};

export default CarDocumentsTable;
