import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import Logout from "../../../components/buttons/Logout";
import "./HeaderSchool.module.css";
import {placeholder, label} from '../../../utils/lang/ro/components'


const SchoolHomeActionBar = () => {
  const navigate = useNavigate();

  return (
      <div className="flex w-full justify-content-between align-items-center px-5 surface-section shadow-2 border-bottom-1 surface-border school-home-action-bar">
        <Logout />

        <div className="flex flex-1">
          <a className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
            <i className="pi pi-bars text-4xl"></i>
          </a>

          <span className="p-input-icon-left">
          <i className="pi pi-search"></i>
          <InputText
              className="border-solid border-grey-500"
              placeholder={placeholder.search}
          />
        </span>
        </div>

        <a className="p-ripple cursor-pointer block lg:hidden text-700">
          <i className="pi pi-ellipsis-v text-2xl"></i>
        </a>

        <div
            className="
              actions-bar-btns lg:flex lg:align-items-center select-none lg:flex-row
              surface-section border-1 lg:border-none surface-border shadow-2 lg:shadow-none"
        >
          <Button
              className="p-button-info mr-3 p-button-outlined"
              icon="pi pi-calendar"
              label={label.newAppointment}
              iconPos="left"
              onClick={() => navigate("/school/add-new-appointment/")}
          />
          <Button
              className="p-button-info mr-3 p-button-outlined"
              icon="pi pi-calendar"
              label={label.addStudent}
              iconPos="left"
              onClick={() => navigate("/school/add-new-student/")}
          />
          <Button
              className="p-button-info mr-3 p-button-outlined"
              icon="pi pi-car"
              label={label.addCar}
              iconPos="left"
              onClick={() => navigate("/school/add-new-car/")}
          />
          <Button
              className="p-button-info p-button-outlined"
              icon="pi pi-calendar"
              label={label.addInstructor}
              iconPos="left"
              onClick={() => navigate("/school/add-new-instructor/")}
          />
        </div>
      </div>
  );
};

export default SchoolHomeActionBar;
