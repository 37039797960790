import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import StudentTableBtns from "./StudentTableBtns";
import TablesExpirationDates from "../TablesExpirationDates";

const StudentsTable = () => {
  const [students, setStudents] = useState({});
  const navigate = useNavigate();

  let studentsCopy;

  const fetchAllStudents = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/students";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    await axios
      .get(API_URL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log(response.data.data);
        setStudents(response.data.data);

        studentsCopy = response.data.data;

        const tableData = [];

        studentsCopy.forEach((student) => {
          if (!student.documents.length) {
            tableData.push(student);
          } else {
            let constructedStudent;
            const docs = student.documents;

            docs.forEach((doc) => {
              // console.log(doc.type);
              const tempKey = `${doc.type}_expiration_date`;
              constructedStudent = {
                ...student,
                [tempKey]: doc.expiration_date,
              };
              tableData.push(constructedStudent);
            });
          }
        });
        // console.log(tableData);
      })
      .catch((error) => {
        console.log(error);
        studentsTableToast.current.show({
          severity: "error",
          summary: "Eroare",
          detail: "Nu exista studenti",
          life: 3000,
        });
      });
  };

  const navigateToSelected = (student) => {
    navigate(`/school/students/${student.id}`);
  };

  useEffect(() => {
    fetchAllStudents();
    // constructTableData(students);
  }, []);

  const studentsTableToast = useRef(null);

  const filterTable = (value, filter) => {
    return value.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
  };

  return (
    <>
      <Toast
        ref={studentsTableToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <div className="card">
        <DataTable
          value={students}
          selectionMode="single"
          onSelectionChange={(e) => navigateToSelected(e.value)}
          stripedRows
          responsiveLayout="scroll"
        >
          <Column
            field="id"
            header="ID"
            sortable
            filter={true}
            filterFunction={filterTable}
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
          ></Column>
          <Column
            field="last_name"
            header="Nume"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="first_name"
            header="Prenume"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="birthday"
            header="Zi de nastere"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="address"
            header="Adresa"
            sortable
            filter={true}
            filterFunction={filterTable}
          ></Column>
          <Column
            field="criminal_record_expiration_date"
            header="Data exp. cazier"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return (
                <TablesExpirationDates
                  type="criminal_record"
                  rowData={rowData}
                />
              );
            }}
          ></Column>
          <Column
            field="medical_exam_expiration_date"
            header="Data exp. aviz medical"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return (
                <TablesExpirationDates type="medical_exam" rowData={rowData} />
              );
            }}
          ></Column>
          <Column
            field="psychiatrist_certificate_expiration_date"
            header="Data exp. aviz psihologic"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return (
                <TablesExpirationDates
                  type="psychiatrist_certificate"
                  rowData={rowData}
                />
              );
            }}
          ></Column>
          <Column
            field="id_card_expiration_date"
            header="Data exp. buletin"
            sortable
            filter={true}
            filterFunction={filterTable}
            body={(rowData) => {
              return <TablesExpirationDates type="id_card" rowData={rowData} />;
            }}
          ></Column>
          <Column
            body={StudentTableBtns}
            exportable={false}
            style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
};

export default StudentsTable;
