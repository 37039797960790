import React, {useRef, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {Checkbox} from 'primereact/checkbox';

export default function StudentSettings() {
    const [checked, setChecked] = useState(false);
    const [cities, setCities] = useState([]);




    return (

        <div>
            <div className="text-900 font-medium text-900 text-xl mb-3">Setări</div>
            <div className="grid formgrid p-fluid">
                <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">Setare notificări</p>

                <div className="field-checkbox">
                    <Checkbox inputId="set-notes-email" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-notes-email">Doresc sa primesc notificari pe email</label>
                </div>
                <div className="field-checkbox">
                    <Checkbox inputId="set-notes-phone" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-notes-phone">Doresc sa primesc notificari pe telefon</label>
                </div>
                <div className="field-checkbox">
                    <Checkbox inputId="set-notes-app" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-notes-app">Doresc sa primesc notificari în aplicație</label>
                </div>


                <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">Setare aplicație</p>
                <div className="field-checkbox">
                    <Checkbox inputId="set-app-hide-blocked" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-app-hide-blocked">Ascunde evenimentele blocate</label>
                </div>
                      <div className="field-checkbox">
                    <Checkbox inputId="set-app-hide-past" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-app-hide-past">Ascunde evenimentele trecute</label>
                </div>
                         <div className="field-checkbox">
                    <Checkbox inputId="set-app-hide-tests" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-app-hide-tests">Ascunde chestionarele blocate</label>
                </div>
                             <div className="field-checkbox">
                    <Checkbox inputId="set-app-hide-tips" checked={checked} onChange={e => setChecked(e.checked)}/>
                    <label htmlFor="set-app-hide-tips">Ascunde sugestiile la chestionare</label>
                </div>
                           </div>

        </div>
    )
}