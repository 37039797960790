import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Swal from "sweetalert2";

const StudentTableBtns = (rowData) => {
  const navigate = useNavigate();
  const studentsTableToast = useRef(null);

  const handleEditStudent = (student, navigate) => {
    navigate(`/school/students/${student.id}`);
  };

  const deleteStudent = (student) => {
    const { id, user_name } = student;
    console.log(id, student);

    const API_URL = `https://api.scoliauto.info/api/school/students/${id}`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        user_name,
      },
      data: { userName: user_name },
    };

    axios
      .delete(API_URL, config)
      .then((res) => {
        console.log(res);
        studentsTableToast.current.show({
          severity: "success",
          detail: "Student sters cu succes!",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteStudent = (student) => {
    const { first_name, last_name } = student;

    Swal.fire({
      title: "Confirmati stergerea studentului",
      text: `${first_name} ${last_name}`,
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Introduceti numele studentului!";
        }
      },
    }).then((result) => {
      if (result.value === `${first_name} ${last_name}`) {
        deleteStudent(student);
        Swal.fire(
          `Studentul ${first_name} ${last_name} a fost sters!`,
          "",
          "success"
        );
      } else {
        Swal.fire("Numele introdus este incorect!", "", "error");
      }
    });
  };

  return (
    <div className="flex">
      <Toast
        ref={studentsTableToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success mr-2"
        onClick={() => handleEditStudent(rowData, navigate)}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => handleDeleteStudent(rowData)}
      />
    </div>
  );
};

export default StudentTableBtns;
