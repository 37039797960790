import { useContext } from "react";
import SchoolLayoutContext from "../../../context/SchoolLayoutContext";
import { stepThreeOptions, stepFourOptions } from "../StepSelectOptions";
import FormInputError from "../../../components/Utils/FormInputError";

import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import "./AddNewStudent.css";

const AddNewStudent = () => {
  const {
    addNewStudent: {
      newStudentBasicInfo: { email, phone },
      newStudentUtils: {
        first_name,
        last_name,
        group_name,
        address,
        transmission_type,
        driving_license_category,
        personal_id_number,
      },
      newStudentDocuments: {
        id_card_series,
        id_card_expiration_date,
        id_card_url,
        criminal_record_series,
        criminal_record_expiration_date,
        criminal_record_url,
        medical_exam_series,
        medical_exam_expiration_date,
        medical_exam_url,
        psychiatrist_certificate_series,
        psychiatrist_certificate_expiration_date,
        psychiatrist_certificate_url,
      },
      paymentInfo: {
        total_amount_to_pay,
        current_amount_payed,
        payment_evidence_url,
        type,
      },
    },
    handleNewStudentFormChange,
    setAddNewStudentActiveIndex,
    addNewStudentActiveIndex,
    handleStudentStepOneSubmit,
    handleStudentStepTwoSubmit,
    handleStudentStepThreeSubmit,
    handleStudentStepFourSubmit,
    studentStepTwoUnlocked,
    errors,
    schoolLayoutToast,
  } = useContext(SchoolLayoutContext);

  const customValidity = (e, message) => {
    e.target.setCustomValidity(message);
  };

  let minDate = new Date();

  const isDisabled = addNewStudentActiveIndex !== 0 && "disabled";

  return (
    <>
      <Toast
        ref={schoolLayoutToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <p className="bg-blue-100 text-xl font-bold m-0 text-center py-3">
        Adaugare elev nou
      </p>

      <div className="w-full px-5 pt-3 add-new-student-steps">
        <TabView
          activeIndex={addNewStudentActiveIndex}
          onTabChange={(e) => setAddNewStudentActiveIndex(e.index)}
        >
          <TabPanel header="1. Date personale" disabled={isDisabled}>
            <div className="surface-card p-4 shadow-2 border-round flex">
              <div className="col-6 md:px-6 py-4 relative">
                {studentStepTwoUnlocked && (
                  <div className="step-two-overlay">
                    <i className="pi pi-lock"></i>
                  </div>
                )}

                <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                  Date personale
                </h3>

                <form onSubmit={handleStudentStepOneSubmit}>
                  <div className="grid formgrid p-fluid">
                    {errors.email && !email && (
                      <FormInputError errors={errors.email} />
                    )}
                    <InputText
                      id="email"
                      type="email"
                      name="email"
                      className="mb-3"
                      placeholder="Email"
                      onChange={handleNewStudentFormChange}
                      value={email}
                      required
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti adresa de email")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    {errors.phone && !phone && (
                      <FormInputError errors={errors.phone} />
                    )}
                    <InputText
                      id="phone"
                      type="phone"
                      name="phone"
                      className="mb-3"
                      placeholder="Telefon"
                      onChange={handleNewStudentFormChange}
                      value={phone}
                      required
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti numarul de telefon")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Button
                      type="submit"
                      label="Creeaza Utilizator"
                      className="bg-green-400 border-0 font-medium mt-2 w-max"
                    />
                  </div>
                </form>
              </div>

              <div className="col-6 relative md:px-6 py-4">
                {!studentStepTwoUnlocked && (
                  <div className="step-two-overlay">
                    <i className="pi pi-lock"></i>
                  </div>
                )}

                <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                  Informatii utile
                </h3>

                <form
                  onSubmit={handleStudentStepTwoSubmit}
                  className={
                    !studentStepTwoUnlocked
                      ? "flex flex-column disabled-form"
                      : "flex flex-column"
                  }
                >
                  <InputText
                    id="last_name"
                    type="text"
                    placeholder="Nume"
                    name="last_name"
                    className="w-full mb-3"
                    onChange={handleNewStudentFormChange}
                    value={last_name}
                    required
                    onInvalid={(e) => customValidity(e, "Introduceti nume")}
                    onInput={(e) => customValidity(e, "")}
                  />

                  <InputText
                    id="first_name"
                    type="text"
                    placeholder="Prenume"
                    name="first_name"
                    className="w-full mb-3"
                    onChange={handleNewStudentFormChange}
                    value={first_name}
                    required
                    onInvalid={(e) => customValidity(e, "Introduceti prenume")}
                    onInput={(e) => customValidity(e, "")}
                  />

                  <InputText
                    id="address"
                    type="text"
                    placeholder="Adresa"
                    name="address"
                    className="w-full mb-3"
                    onChange={handleNewStudentFormChange}
                    value={address}
                    required
                    onInvalid={(e) => customValidity(e, "Introduceti adresa")}
                    onInput={(e) => customValidity(e, "")}
                  />

                  {errors.personal_id_number && !personal_id_number && (
                    <FormInputError errors={errors.personal_id_number} />
                  )}

                  <InputText
                    id="personalIdNumber"
                    type="text"
                    placeholder="CNP"
                    name="personal_id_number"
                    className="w-full mb-3"
                    onChange={handleNewStudentFormChange}
                    value={personal_id_number}
                    required
                    onInvalid={(e) => customValidity(e, "Introduceti CNP")}
                    onInput={(e) => customValidity(e, "")}
                  />

                  {/* {errors.birthday && (
                    <FormInputError errors={errors.birthday} />
                  )}

                  <div className="field mb-4 col-12 md:col-12 p-0">
                    <Calendar
                      id="birthday"
                      value={birthday}
                      name="birthday"
                      className="w-full"
                      placeholder={"Data nasterii"}
                      dateFormat="dd/mm/yy"
                      inputClassName="font-medium text-600"
                      showIcon={true}
                      onChange={handleNewStudentFormChange}
                    ></Calendar>
                  </div> */}

                  {errors.group_name && !group_name && (
                    <FormInputError errors={errors.group_name} />
                  )}
                  <InputText
                    id="groupNumber"
                    type="text"
                    placeholder="Grupa"
                    name="group_name"
                    className="w-full mb-3"
                    onChange={handleNewStudentFormChange}
                    value={group_name}
                    required
                    onInvalid={(e) => customValidity(e, "Introduceti grupa ")}
                    onInput={(e) => customValidity(e, "")}
                  />

                  {errors.transmission_type && transmission_type && (
                    <FormInputError errors={errors.transmission_type} />
                  )}

                  <Dropdown
                    className="col-12 md:col-12 mb-3 p-0"
                    name="transmission_type"
                    placeholder="Cutie preferata"
                    options={stepThreeOptions.transmissionOptions}
                    value={transmission_type}
                    onChange={handleNewStudentFormChange}
                  />

                  {errors.driving_license_category &&
                    !driving_license_category && (
                      <FormInputError
                        errors={errors.driving_license_category}
                      />
                    )}

                  <Dropdown
                    className="col-12 md:col-12 p-0"
                    name="driving_license_category"
                    placeholder="Categorie permis"
                    options={stepThreeOptions.licenseType}
                    value={driving_license_category}
                    onChange={handleNewStudentFormChange}
                  />

                  <div className="flex">
                    <Button
                      id="createStudent"
                      type="submit"
                      label="Creeaza student"
                      className="bg-green-400 border-0 font-medium w-max mt-4 mr-3"
                    />

                    <Button
                      id="continueStudentDocuments"
                      type="submit"
                      label="Creeaza si adauga acte"
                      className="bg-blue-400 border-0 font-medium w-max mt-4"
                    />
                  </div>
                </form>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="2. Acte scolarizare" disabled>
            <div className="surface-card p-4 shadow-2 border-round">
              <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                Acte scolarizare
              </h3>

              <form
                onSubmit={handleStudentStepThreeSubmit}
                className="flex flex-wrap"
              >
                <div className="col-4">
                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="id_card_series"
                      type="text"
                      name="id_card_series"
                      onChange={handleNewStudentFormChange}
                      value={id_card_series}
                      required
                      placeholder="Seria / Nr. buletin"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti seria/nr. buletin")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="id_card_expiration_date"
                      value={id_card_expiration_date}
                      name="id_card_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewStudentFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {errors["documents.0.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru Buletin!"]}
                      />
                    )}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="id_card"
                        onChange={handleNewStudentFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!id_card_url && <i className="pi pi-image"></i>}
                      <img src={id_card_url} alt="Buletin" />
                    </div>
                  </div>

                  <div className="mb-3 col-12 md:col-10 flex flex-column ">
                    <InputText
                      id="criminal_record_series"
                      type="text"
                      name="criminal_record_series"
                      onChange={handleNewStudentFormChange}
                      value={criminal_record_series}
                      required
                      placeholder="Seria / Nr. cazier"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti seria/nr. cazier")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="criminal_record_expiration_date"
                      value={criminal_record_expiration_date}
                      name="criminal_record_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewStudentFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {errors["documents.1.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru Cazier!"]}
                      />
                    )}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="criminal_record"
                        onChange={handleNewStudentFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!criminal_record_url && <i className="pi pi-image"></i>}
                      <img src={criminal_record_url} alt="Cazier" />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="medical_exam_series"
                      type="text"
                      name="medical_exam_series"
                      onChange={handleNewStudentFormChange}
                      value={medical_exam_series}
                      required
                      placeholder="Seria / Nr. Certificat medical"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(
                          e,
                          "Introduceti seria/nr. crtificat medical"
                        )
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="medical_exam_expiration_date"
                      value={medical_exam_expiration_date}
                      name="medical_exam_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewStudentFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {errors["documents.2.file"] && (
                      <FormInputError
                        errors={[
                          "Incarcati fisierul pentru Certificat Medical!",
                        ]}
                      />
                    )}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="medical_exam"
                        onChange={handleNewStudentFormChange}
                        className="mb-3 bg-primary-500 border-0"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!medical_exam_url && <i className="pi pi-image"></i>}
                      <img src={medical_exam_url} alt="Aviz medical" />
                    </div>
                  </div>

                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="psychiatrist_certificate_series"
                      type="text"
                      name="psychiatrist_certificate_series"
                      onChange={handleNewStudentFormChange}
                      value={psychiatrist_certificate_series}
                      required
                      placeholder="Seria / Nr. Aviz psihologic"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(
                          e,
                          "Introduceti seria/nr. avizului psihologic"
                        )
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="psychiatrist_certificate_expiration_date"
                      value={psychiatrist_certificate_expiration_date}
                      name="psychiatrist_certificate_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewStudentFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {errors["documents.3.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru Aviz Psihologic!"]}
                      />
                    )}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="psychiatrist_certificate"
                        onChange={handleNewStudentFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!psychiatrist_certificate_url && (
                        <i className="pi pi-image"></i>
                      )}
                      <img
                        src={psychiatrist_certificate_url}
                        alt="Aviz psihologic"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 flex">
                  <Button
                    id="continueWithoutPaymentBtn"
                    type="submit"
                    label="Continua fara plata"
                    className="bg-green-400 border-0 font-medium w-max mt-4"
                  />
                  <Button
                    id="continueToPaymentBtn"
                    type="submit"
                    label="Continua cu plata"
                    className="bg-primary-400 border-0 font-medium w-max mt-4 ml-4"
                  />
                </div>
              </form>
            </div>
          </TabPanel>

          <TabPanel header="3. Plata scolarizare" disabled>
            <div className="surface-card p-4 shadow-2 border-round">
              <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                Plata scolarizare
              </h3>

              <form onSubmit={handleStudentStepFourSubmit}>
                <div className="flex flex-column col-4">
                  <InputText
                    id="paymentAmount"
                    type="number"
                    name="total_amount_to_pay"
                    onChange={handleNewStudentFormChange}
                    value={total_amount_to_pay || 1500}
                    required
                    placeholder="Suma de plata"
                    className="w-full mb-3"
                    disabled
                  />

                  {errors.current_amount_payed && (
                    <FormInputError errors={errors.current_amount_payed} />
                  )}
                  <Dropdown
                    name="current_amount_payed"
                    value={current_amount_payed}
                    onChange={handleNewStudentFormChange}
                    options={stepFourOptions.paymentRates}
                    placeholder="Rata"
                    className="mb-3"
                  />

                  {errors.type && <FormInputError errors={errors.type} />}
                  <Dropdown
                    name="type"
                    value={type}
                    onChange={handleNewStudentFormChange}
                    options={stepFourOptions.paymentTypes}
                    placeholder="Tip plata"
                    className="mb-3"
                  />

                  {payment_evidence_url && (
                    <div className="document-preview mt-3">
                      <img src={payment_evidence_url} alt="Evidenta plata" />
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <Button
                    label="Adauga plata"
                    className="bg-green-400 border-0 font-medium w-max"
                  />
                </div>
              </form>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default AddNewStudent;
