import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";

import FormInputError from "../../../../components/Utils/FormInputError";
import DocumentsList from "../../DocumentsList";

import { uploadInstructorDocumentTypes } from "../../StepSelectOptions";
import avatar from "../../../../assets/images/student/avatar_placeholder.png";
import CarsDropdown from "./CarsDropdown";
import "./InstructorInfoForSchool.css";
import InstructorPaymentsForSchool from "./InstructorPaymentsForSchool";
import InstructorCarExpenses from "./InstructorCarExpenses";
// import InstructorLatestExpenses from "./InstructorLatestExpenses";

const InstructorInfoForSchool = () => {
  const { id } = useParams();

  const [instructorData, setInstructorData] = useState({});
  const [instructorPaymentsData, setInstructorPaymentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewInstructorActiveIndex, setViewInstructorActiveIndex] = useState(0);
  const [errors, setErrors] = useState({});
  const [position, setPosition] = useState("center");
  const [personalDataModal, setPersonalDataModal] = useState(false);
  const [personalData, setPersonalData] = useState({
    first_name: "",
    last_name: "",
    address: "",
  });
  const [idInfo, setIdInfo] = useState({
    phone: "",
  });
  const [idInfoModal, setIdInfoModal] = useState(false);
  const [documentsModal, setDocumentsModal] = useState(false);
  const [documents, setDocuments] = useState({
    file: null,
    series: "",
    expiration_date: "",
    type: "",
  });
  const [viewDocumentsModal, setViewDocumentsModal] = useState(false);
  const [decontsModal, setDecontsModal] = useState(false);
  const [allDecontsModal, setAllDecontsModal] = useState(false);
  const [decont, setDecont] = useState({
    author_user_id: "",
    car_id: "",
    amount: "",
    paid_at: "",
  });
  const [payments, setPayments] = useState({
    instructor_id: id,
    appointments_count: "",
  });
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [paymentsModal, setPaymentsModal] = useState(false);
  const [viewPaymentsModal, setViewPaymentsModal] = useState(false);

  const [documentUrl, setDocumentUrl] = useState("");
  const [instructorAppointments, setInstructorAppointments] = useState([]);

  const [cars, setCars] = useState([]);
  const [allCarExpenses, setAllCarExpenses] = useState([]);

  const viewInstructorToast = useRef(null);

  const fetchInstructorData = async () => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const API_URL = `https://api.scoliauto.info/api/school/instructors/${id}`;
    let uploadedDocumentsNum = 0;

    await axios
      .get(API_URL, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        console.log(res.data.data);

        const instructorData = res.data.data;
        uploadedDocumentsNum = instructorData.documents.length;

        //Set appointments
        // const appointments = instructorData.appointments;
        if (instructorData.appointments) {
          const appointmentsArr = [];

          instructorData.appointments.forEach((appointment) => {
            const appointmentDay = new Date(
              appointment.start_date
            ).getUTCDate();
            const appointmentMonth =
              new Date(appointment.start_date).getUTCMonth() + 1;
            const appointmentYear = new Date(
              appointment.start_date
            ).getUTCFullYear();

            // console.log(appointmentDay, appointmentMonth, appointmentYear);
            appointmentsArr.push({
              day: appointmentDay,
              month: appointmentMonth,
              year: appointmentYear,
            });
          });

          setInstructorAppointments(appointmentsArr);
        }

        setInstructorData(instructorData);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchInstructorPayments = async () => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const API_URL = `https://api.scoliauto.info/api/school/expenses/instructor/${id}`;

    await axios
      .get(API_URL, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const data = res.data.data;
        setInstructorPaymentsData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchInstructorData();
    fetchInstructorPayments();
  }, []);

  useEffect(() => {
    getCarExpenses();
  }, [instructorData]);

  let minDate = new Date();

  const customValidity = (e, message) => {
    e.target.setCustomValidity(message);
  };

  const displayToast = (severity, summary, detail) => {
    viewInstructorToast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  const mockCars = [
    { label: "Car 1", value: 1 },
    { label: "Car 2", value: 2 },
    { label: "Car 3", value: 3 },
  ];

  /* Modal helpers */
  const dialogFuncMap = {
    personalDataModal: setPersonalDataModal,
    idInfoModal: setIdInfoModal,
    documentsModal: setDocumentsModal,
    viewDocumentsModal: setViewDocumentsModal,
    decontsModal: setDecontsModal,
    allDecontsModal: setAllDecontsModal,
    paymentsModal: setPaymentsModal,
    viewPaymentsModal: setViewPaymentsModal,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  /* Modal handlers */
  /* BASIC INFO MODAL */
  const updateBasicInfo = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/instructors/${id}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .patch(API_URL, personalData, config)
      .then((res) => {
        console.log(res);
        const { data } = res.data;
        setInstructorData(data);
        onHide("personalDataModal");
        displayToast("success", "Succes!", "Modificarile au fost salvate!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
      });
  };

  const onBasicInfoChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setPersonalData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleBasicInfoUpdate = (e) => {
    e.preventDefault();
    updateBasicInfo();
  };

  /* PREFERENCES MODAL */
  const updateIdInfo = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/instructors/${id}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .patch(API_URL, idInfo, config)
      .then((res) => {
        const { data } = res.data;
        setErrors({});
        setInstructorData(data);
        onHide("idInfoModal");
        displayToast("success", "Succes!", "Modificarile au fost salvate!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
      });
  };

  const onIdInfoChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setErrors({});

    setIdInfo((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePreferencesUpdate = (e) => {
    e.preventDefault();
    updateIdInfo();
    fetchInstructorData();
  };

  const mapDocumentErrors = (errrors) => {
    console.log(errors);
  };

  /* DOCUMENTS MODAL */
  const updateDocuments = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/documents/upload";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const formData = new FormData();
    formData.append("user_id", instructorData.user.id);
    formData.append(`documents[0][file]`, documents.file);
    formData.append(`documents[0][expiration_date]`, documents.expiration_date);
    formData.append(`documents[0][series]`, documents.series);
    formData.append(`documents[0][type]`, documents.type);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(API_URL, formData, config)
      .then((response) => {
        const { data } = response.data;
        console.log(data);
        setInstructorData(data);
        onHide("documentsModal");
        displayToast("success", "Succes!", "Documentul a fost adaugat!");
      })
      .catch((error) => {
        // console.log(error);
        mapDocumentErrors(error.response.data.errors);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Documentul nu s-a adaugat."
        );
      });
  };

  const onDocumentsChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "expiration_date") {
      const formattedDateValue = new Date(e.target.value)
        .toISOString()
        .substring(0, 10);
      value = formattedDateValue;
    }

    setDocuments((prevState) => {
      if (name === "file") {
        const file = e.target.files[0];
        const fileUrl = file ? URL.createObjectURL(file) : "";
        setDocumentUrl(fileUrl);

        return {
          ...prevState,
          file,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleDocumentsUpdate = (e) => {
    e.preventDefault();
    updateDocuments();
    fetchInstructorData();
  };

  /* CARS */
  const createCarsOptions = (cars) => {
    return cars.map((car) => {
      return {
        value: car.id,
        registration_number: car.registration_number,
      };
    });
  };

  const getCars = async () => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const API_URL = `https://api.scoliauto.info/api/school/cars`;
    await axios
      .get(API_URL, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const cars = createCarsOptions(res.data.available);
        setCars(cars);
      })
      .catch((err) => console.log(err));
  };

  /*DECONTS MODAL */
  const handleDeconts = () => {
    onClick("decontsModal");
    getCars();
  };

  const onDecontChange = (e) => {
    setErrors({});

    const name = e.target.name;
    let value =
      name === "car_id" || name === "amount"
        ? parseInt(e.target.value)
        : e.target.value;

    //Format date in a particular case
    if (name === "paid_at") {
      const year = value.getFullYear();
      const month =
        value.getMonth() < 9
          ? "0" + (value.getMonth() + 1)
          : value.getMonth() + 1;
      const day =
        value.getDate() < 10 ? "0" + value.getDate() : value.getDate();

      const valueDate = year + "-" + month + "-" + day;

      const hours =
        value.getHours() < 10 ? "0" + value.getHours() : value.getHours();
      const minutes =
        value.getMinutes() < 10 ? "0" + value.getMinutes() : value.getMinutes();
      const seconds =
        value.getSeconds() < 10 ? "0" + value.getSeconds() : value.getSeconds();
      const currentTime = hours + ":" + minutes + ":" + seconds;
      const dateTime = valueDate + " " + currentTime;

      value = dateTime;
    }

    setDecont((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const updateDeconts = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/expenses/car/create";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const objToSend = {
      ...decont,
      author_user_id: instructorData.user.id,
    };

    await axios
      .post(API_URL, objToSend, config)
      .then((response) => {
        console.log(response.data);
        onHide("decontsModal");
        displayToast("success", "Succes!", "Decontarea a fost inregistrata!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Eroare inregistrare plata...",
          "Plata nu s-a inregistrat."
        );
      });
  };

  const handleDecontsUpdate = (e) => {
    e.preventDefault();
    updateDeconts();
  };

  /* VIEW DECONTS MODAL */
  const getCarExpenses = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/expenses/car/list`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = { headers: { Authorization: `Bearer ${token}` } };

    await axios
      .get(API_URL, config)
      .then((res) => {
        setAllCarExpenses(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* PAYMENTS MODAL */
  const updatePayments = async () => {
    const API_URL =
      "https://api.scoliauto.info/api/school/expenses/instructor/bulk";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(API_URL, payments, config)
      .then((response) => {
        console.log(response.data);
        onHide("paymentsModal");
        displayToast("success", "Succes!", "Plata a fost inregistrata!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Eroare inregistrare plata...",
          "Plata nu s-a inregistrat."
        );
      });
  };

  const handlePaymentsUpdate = (e) => {
    e.preventDefault();
    updatePayments();
  };

  const onPaymentsChange = (e) => {
    const appointmentPrice = instructorData.price_per_appointment;
    let appointmentsTotal = appointmentPrice * e.target.value;

    setPaymentTotal(appointmentsTotal);

    setErrors({});

    setPayments((prevState) => {
      return {
        ...prevState,
        appointments_count: e.target.value,
      };
    });
  };

  /* APPOINTMENTS TAB */
  const dateTemplate = (date) => {
    instructorAppointments.forEach((appointment) => {
      if (
        instructorAppointments.day === date.day &&
        instructorAppointments.month === date.month &&
        instructorAppointments.year === instructorAppointments.year
      ) {
        return (
          <div
            style={{
              backgroundColor: "#1dcbb3",
              color: "#ffffff",
              fontWeight: "bold",
              borderRadius: "50%",
              width: "2em",
              height: "2em",
              lineHeight: "2em",
              padding: 0,
            }}
          >
            {date.day}
          </div>
        );
      }
    });
    return date.day;
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="p-4">
        <Toast
          ref={viewInstructorToast}
          style={{ left: "50%", transform: "translateX(-50%)" }}
        />
        <TabView
          activeIndex={viewInstructorActiveIndex}
          onTabChange={(e) => setViewInstructorActiveIndex(e.index)}
        >
          <TabPanel header="1. Date generale">
            <div className="surface-card p-4 shadow-2 border-round mb-6">
              <h3>Date generale</h3>
              <div className="flex gap-5">
                <div className="avatar-container col-3">
                  <img src={avatar} alt="avatar placeholder" />
                </div>
                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">
                      Date personale
                    </span>
                    <Button
                      label="Modifica"
                      onClick={() => onClick("personalDataModal")}
                      className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                    />

                    <Dialog
                      header="Editeaza date personale"
                      visible={personalDataModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("personalDataModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handleBasicInfoUpdate}
                        className="edit-form surface-50 p-4 border-round-lg"
                      >
                        <div className="form-group">
                          <label htmlFor="last_name">Nume</label>
                          <InputText
                            name="last_name"
                            onChange={(e) => onBasicInfoChange(e)}
                            value={personalData.last_name}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="first_name">Prenume</label>
                          <InputText
                            name="first_name"
                            onChange={(e) => onBasicInfoChange(e)}
                            value={personalData.first_name}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="address">Adresa</label>
                          <InputText
                            name="address"
                            onChange={(e) => onBasicInfoChange(e)}
                            value={personalData.address}
                          />
                        </div>
                        <Button className="block mx-auto mt-4">Salveaza</Button>
                      </form>
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Nume/prenume:</span>
                        <span>
                          {instructorData.first_name} {instructorData.last_name}
                        </span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>CNP:</span>
                        <span>{instructorData.personal_id_number}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Adresa:</span>
                        <span className="ml-5">{instructorData.address}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Data nasterii:</span>
                        <span>{instructorData.birthday}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Sex:</span>
                        <span>{instructorData.gender}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">
                      Date de identificare
                    </span>
                    <Button
                      label="Modifica"
                      onClick={() => onClick("idInfoModal")}
                      className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                    />

                    <Dialog
                      header="Editeaza preferinte"
                      visible={idInfoModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("idInfoModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handlePreferencesUpdate}
                        className="edit-form surface-50 p-4 border-round-lg"
                      >
                        <div className="form-group">
                          {errors.phone && (
                            <FormInputError errors={errors.phone} />
                          )}
                          <label
                            htmlFor="Phone"
                            className="font-medium text-600 ml-2"
                          >
                            Telefon
                          </label>
                          <InputText
                            id="instructorPhone"
                            type="text"
                            name="phone"
                            className="w-full"
                            onChange={onIdInfoChange}
                            value={idInfo.phone}
                            required
                            placeholder="Telefon"
                          />
                        </div>
                        <Button className="block mx-auto mt-4">Salveaza</Button>
                      </form>
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Email:</span>
                        <span>{instructorData.user.email}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Telefon:</span>
                        <span>{instructorData.phone}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Data inregistrarii:</span>
                        <span>{instructorData.created_at || "-"}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>ID:</span>
                        <span>{instructorData.id}</span>
                      </li>
                      {/* <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima accesare:</span>
                        <span>2022-10-28</span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="2. Administrare">
            <div className="surface-card p-4 shadow-2 border-round mb-6">
              <h3>Administrare</h3>
              <div className="flex justify-content-between">
                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3 mr-2">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">Acte</span>
                    <div className="action-btns">
                      <Button
                        label="Adauga act"
                        onClick={() => onClick("documentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined mr-3"
                      />
                      <Button
                        label="Vezi toate actele"
                        onClick={() => onClick("viewDocumentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                      />
                    </div>

                    <Dialog
                      header="Adaugare act"
                      visible={documentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("documentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handleDocumentsUpdate}
                        className="flex flex-column w-full surface-50 p-4 border-round-lg"
                      >
                        {/* {errors.documents[0].type && <FormInputError errors={errors.documents[0].type}/>} */}
                        <Dropdown
                          options={uploadInstructorDocumentTypes}
                          placeholder="Tipul actului"
                          name="type"
                          className="h-3rem mb-3"
                          value={documents.type}
                          onChange={onDocumentsChange}
                        />

                        <InputText
                          id="add_new_document"
                          type="text"
                          name="series"
                          onChange={onDocumentsChange}
                          value={documents.series}
                          placeholder="Seria / Nr. buletin"
                          className="mb-3"
                          onInvalid={(e) =>
                            customValidity(e, "Introduceti seria/nr. buletin")
                          }
                          onInput={(e) => customValidity(e, "")}
                        />

                        <Calendar
                          id="file_expiration_date"
                          value={documents.expiration_date}
                          name="expiration_date"
                          placeholder="Data expirarii"
                          dateFormat="dd/mm/yy"
                          showIcon={true}
                          onChange={onDocumentsChange}
                          className="mb-3"
                          minDate={minDate}
                        ></Calendar>

                        <label className="custom-file-upload w-max">
                          <input
                            type="file"
                            name="file"
                            onChange={onDocumentsChange}
                            className="mb-3"
                          />
                          <i className="pi pi-file"></i> Incarca
                        </label>

                        <div className="document-preview mt-3">
                          {!documentUrl && <i className="pi pi-image"></i>}
                          <img src={documentUrl} alt="Buletin" />
                        </div>

                        <Button className="block mx-auto mt-4">
                          Adauga act
                        </Button>
                      </form>
                    </Dialog>

                    <Dialog
                      header="Vizualizare acte"
                      visible={viewDocumentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("viewDocumentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <DocumentsList documents={instructorData.documents} />
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Acte incarcate:</span>
                        <span>{instructorData.documents.length}/4</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Acte expirate:</span>
                        <span>0/4</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Examen scoala:</span>
                        <span>01-10-2022</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Examen teoretic politie:</span>
                        <span>01-10-2022</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Examen practic politie:</span>
                        <span>01-10-2022</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3 mr-2">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">
                      Decontari
                    </span>

                    <div className="action-btns">
                      <Button
                        label="Adauga decontare"
                        onClick={handleDeconts}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined mr-3"
                      />
                      <Button
                        label="Vezi toate"
                        onClick={() => onClick("allDecontsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                      />
                    </div>

                    <Dialog
                      header="Adaugare decont"
                      visible={decontsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("decontsModal")}
                    >
                      <form
                        onSubmit={handleDecontsUpdate}
                        className="flex flex-column w-full surface-50 p-4 border-round-lg"
                      >
                        {errors.amount && !decont.amount && (
                          <FormInputError errors={errors.amount} />
                        )}
                        <InputText
                          id="decont"
                          type="text"
                          name="amount"
                          onChange={onDecontChange}
                          value={decont.amount}
                          placeholder="Suma de decontat"
                          className="mb-3"
                        />

                        {errors.car_id && !decont.car_id && (
                          <FormInputError errors={errors.car_id} />
                        )}
                        <CarsDropdown
                          cars={cars}
                          onDecontChange={onDecontChange}
                          decontCar={decont.car_id}
                        />

                        {errors.paid_at && !decont.paid_at && (
                          <FormInputError errors={errors.paid_at} />
                        )}
                        <Calendar
                          id="new_decont_date"
                          value={decont.paid_at}
                          name="paid_at"
                          placeholder="Data decontarii"
                          dateFormat="dd/mm/yy"
                          showIcon={true}
                          onChange={onDecontChange}
                          showTime
                          maxDate={new Date()}
                          showSeconds
                        ></Calendar>
                        <Button className="block mx-auto mt-4">
                          Adauga decontare
                        </Button>
                      </form>
                    </Dialog>

                    <Dialog
                      header="Decontari adaugate"
                      visible={allDecontsModal}
                      onHide={() => onHide("allDecontsModal")}
                      style={{ width: "50vw" }}
                    >
                      <InstructorCarExpenses
                        expenses={allCarExpenses}
                        latestExpenses={false}
                        instructorId={instructorData.user.id}
                      />
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <InstructorCarExpenses
                      expenses={allCarExpenses}
                      latestExpenses={true}
                      instructorId={instructorData.user.id}
                    />
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">Plati</span>
                    <div className="action-btns">
                      <Button
                        label="Adauga plata"
                        onClick={() => onClick("paymentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined mr-3"
                      />
                      <Button
                        label="Vezi toate platile"
                        onClick={() => onClick("viewPaymentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                      />
                    </div>
                  </div>

                  <div className="card-body">
                    <Dialog
                      header="Adauga numarul de sedinte"
                      visible={paymentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("paymentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handlePaymentsUpdate}
                        className="surface-50 p-4 border-round-lg"
                      >
                        <div className="field col-12 px-0 mb-0">
                          <label htmlFor="paymentTotal" className="font-medium">
                            Total plata
                          </label>
                          <InputText
                            id="paymentTotal"
                            type="number"
                            name="payment_count_total"
                            onChange={onPaymentsChange}
                            value={paymentTotal}
                            required
                            className="w-full"
                            disabled
                          />
                        </div>

                        <div className="field col-12 px-0">
                          {errors.appointments_count &&
                            !payments.appointments_count && (
                              <FormInputError
                                errors={errors.appointments_count}
                              />
                            )}
                          <label
                            htmlFor="instructorPayments"
                            className="font-medium"
                          >
                            Numar sedinte
                          </label>
                          <InputNumber
                            id="instructorPayments"
                            value={payments.appointments_count}
                            name="appointments_count"
                            onValueChange={onPaymentsChange}
                            mode="decimal"
                            useGrouping={false}
                            className="w-full"
                            required
                            placeholder="Numar de sedinte"
                            showButtons
                            buttonLayout="horizontal"
                            step={1}
                            min={0}
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                          />
                        </div>

                        <Button
                          label="Adauga plata"
                          className="block mx-auto mt-3"
                        />
                      </form>
                    </Dialog>

                    <Dialog
                      header="Vizualizare plati"
                      visible={viewPaymentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("viewPaymentsModal")}
                    >
                      <InstructorPaymentsForSchool
                        payments={instructorPaymentsData}
                      />
                    </Dialog>

                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Suma totala:</span>
                        <span>-</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Restante:</span>
                        <span>0</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ramas de plata:</span>
                        <span>-</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima plata (lei):</span>
                        <span>-</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima plata (data):</span>
                        <span>-</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="3. Programari conducere">
            <div className="surface-card p-4 shadow-2 border-round mb-6">
              <div className="card-header border-bottom-1 border-200 pb-3 mb-4 flex justify-content-between align-items-center">
                <span className="card-title font-bold text-lg">
                  Programari conducere
                </span>
                <div className="action-btns">
                  <Button
                    label="Adauga programare"
                    className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                  />
                </div>
              </div>
              <div className="flex gap-6">
                <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
                  <div className="card-body">
                    <Calendar
                      inline
                      className="w-full"
                      onChange={(e) => console.log(e)}
                      dateTemplate={dateTemplate}
                      minDate={minDate}
                    ></Calendar>
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">
                      Sedinte conducere
                    </span>
                    <div className="action-btns">
                      <button className="btn-ghost">
                        <i className="pi pi-chevron-left"></i>
                      </button>
                      <span>2022-10-1</span>
                      <button className="btn-ghost">
                        <i className="pi pi-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Masina preferata:</span>
                        <Dropdown
                          options={mockCars}
                          placeholder="Masina preferata"
                          name="preferred_car"
                          className="h-3rem"
                        />
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Sedinte:</span>
                        <span>7/12</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Rulaj (km):</span>
                        <span>500/670</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima sedinta:</span>
                        <span>01-10-2022</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima plata (data):</span>
                        <span>12-10-2022</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  }
};

export default InstructorInfoForSchool;
