import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Swal from "sweetalert2";

const InstructorTableBtns = (rowData) => {
  const navigate = useNavigate();
  const instructorsTableToast = useRef(null);

  const handleEditInstructor = (instructor, navigate) => {
    navigate(`/school/instructors/${instructor.id}`);
  };

  const deleteInstructor = (instructor) => {
    const { id, user_name } = instructor;
    console.log(id, instructor);

    const API_URL = `https://api.scoliauto.info/api/school/instructors/${id}`;
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        user_name,
      },
      data: { userName: user_name },
    };

    axios
      .delete(API_URL, config)
      .then((res) => {
        console.log(res);
        instructorsTableToast.current.show({
          severity: "success",
          detail: "Instructor sters cu succes!",
          life: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteInstructor = (instructor) => {
    const { user_name } = instructor;

    Swal.fire({
      title: "Confirmati stergerea instructorului",
      text: user_name,
      input: "text",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Introduceti numele instructorului!";
        }
      },
    }).then((result) => {
      if (result.value === user_name) {
        deleteInstructor(instructor);
        Swal.fire(`Studentul ${user_name} a fost sters!`, "", "success");
      } else {
        Swal.fire("Numele introdus este incorect!", "", "error");
      }
    });
  };

  return (
    <div className="flex">
      <Toast
        ref={instructorsTableToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded p-button-success mr-2"
        onClick={() => handleEditInstructor(rowData, navigate)}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => handleDeleteInstructor(rowData)}
      />
    </div>
  );
};

export default InstructorTableBtns;
