import  styles from './CardInstructor.module.css';
import {Rating} from "primereact/rating";
import {Button} from "primereact/button";
import React from "react";
import {cardInstructor} from "../../../utils/lang/ro/components"

function CardInstructor(props){
    return(
        <div className="surface-card shadow-2 border-rounded p-2 m-2">

            <div className="flex flex-column align-items-center border-bottom-1 surface-border pb-3">
                <img src={props.image} style={{borderRadius: '50%', height: '100px'}} className="mb-3" alt={props.name}/>
                <span className="text-lg text-900 font-medium mb-2">{props.name}</span>
                <Rating value={props.rating} readonly stars={5} cancel={false}/>
            </div>
            <ul className="list-none m-0 p-0">
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                    <span className="text-600 font-medium text-sm">{cardInstructor.age}</span>
                    <span className="text-900 font-medium text-sm">{props.age}</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center border-bottom-1 surface-border">
                    <span className="text-600 font-medium text-sm">{cardInstructor.experience}</span>
                    <span className="text-900 font-medium text-sm">{props.experience}</span>
                </li>
                <li className="px-0 py-2 flex justify-content-between align-items-center">
                    <span className="text-600 font-medium text-sm">{cardInstructor.smoker}</span>
                    <span className="text-900 font-medium text-sm">{props.smoker}</span>
                </li>
            </ul>
            <div className="flex pt-3 justify-content-between align-items-center">
                <Button icon="pi pi-calendar" label={props.actionbtn} className="p-button-text"/>
                <Button icon="pi pi-heart" className="p-button-text p-button-secondary"/>
            </div>
        </div>
    )
}
export default CardInstructor;