import FooterInstructor from "./FooterInstructor/FooterInstructor";
import HeaderInstructor from "./HeaderInstructor/HeaderInstructor";

const InstructorLayout = (props) => {
  return (
    <div className="wrapper">
      <HeaderInstructor />
      {/*{profileIncomplete &&  window.location.pathname!=='/student/profile' ? <Navigate replace to='/student/profile'/> : ''}*/}
      {props.children}
      <FooterInstructor />
    </div>
  );
};

export default InstructorLayout;
