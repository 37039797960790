import { useContext } from "react";
import { useLocation } from "react-router-dom";

import StudentLayout from "./Student/StudentLayout";
import StudentProfileForm from "./Student/StudentProfileForm";
import InstructorLayout from "./Instructor/InstructorLayout";
import SchoolLayout from "./School/SchoolLayout";
import Login from "./Login/Login";
import Register from "../pages/Register";

import LayoutContext from "../context/LayoutContext";

import "./Layout.css";

const Layout = (props) => {
  const { isLogin, isStudent, isInstructor, profileIncomplete } =
    useContext(LayoutContext);
  const currentRoute = useLocation();

  return (
    <>
      {isLogin ? (
        isStudent ? (
          profileIncomplete ? (
            <StudentProfileForm />
          ) : (
            <StudentLayout props={props} />
          )
        ) : isInstructor ? (
          <InstructorLayout props={props} />
        ) : (
          <SchoolLayout props={props} />
        )
      ) : currentRoute.pathname == "/login" ? (
        <Login />
      ) : (
        <Login />
        // <Register />
      )}
    </>
  );
};

export default Layout;
