import {useState, useEffect, useRef, useContext} from "react";
import {Link, useNavigate} from "react-router-dom";

import LayoutContext from "../../context/LayoutContext";
import GlobalContext from "../../context/GlobalContext";
import axios from "axios";

import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {login} from "../../utils/lang/ro/layout";

import "./Login.css";

const Login = () => {
    //Prevents loss of token from local storage on rerender
    const tokenCheck =
        localStorage.getItem("accessToken") != "undefined"
            ? JSON.parse(localStorage.getItem("accessToken"))
            : "";

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [formError, setFormError] = useState("");
    const [accessToken, setAccessToken] = useState(tokenCheck);

    const {setGlobalState} = useContext(GlobalContext);
    const {
        setIsLogin,
        setUserType,
        setIsStudent,
        setIsInstructor,
        setIsSchool,
    } = useContext(LayoutContext);

    const toast = useRef(null);
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        const user = {user_identification: email, password};
        loginUser(user);
    };

    const loginUser = (user) => {
        const url = "https://api.scoliauto.info/api/login";

        axios
            .post(url, user)
            .then((response) => {
                const accessToken = response.data.accessToken;
                setAccessToken(accessToken);

                setGlobalState((prevState) => ({
                    ...prevState,
                    accessToken,
                }));

                localStorage.setItem(
                    "accessToken",
                    JSON.stringify(response.data.accessToken)
                );

                // console.log(response);

                const userType = response.data.type;

                if (response.data.message) {
                    setFormError(response.data.message);
                    showError(response.data.message);
                    return;
                }

                showSuccess();
                setIsLogin(true);

                if (userType === "school") {
                    setIsSchool(true);
                    navigate("/school");
                }

                if (userType === "student") {
                    setIsStudent(true);
                    navigate("/student");
                }

                setUserType(userType);
            })
            .catch((error) => {
                const errMsg = error.response.data.message;
                setFormError(errMsg);
                showError(errMsg);
            });
    };

    const showSuccess = () => {
        toast.current.show({
            severity: "success",
            detail: "Login cu succes!",
            life: 3000,
        });
    };

    const showError = (errMsg) => {
        toast.current.show({
            severity: "error",
            detail: errMsg,
            life: 3000,
        });
    };

    useEffect(() => {
        localStorage.setItem("accessToken", JSON.stringify(accessToken));
    }, [accessToken]);

    return (
        <div className="login-container">
            <Toast ref={toast}/>
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                {/* <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        </div> */}

                <form id="loginForm" onSubmit={handleLogin}>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">
                        {login.email}
                    </label>

                    <InputText
                        id="loginEmail"
                        type="email"
                        className="w-full mb-3"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <label htmlFor="password" className="block text-900 font-medium mb-2">
                        {login.pass}
                    </label>

                    <Password
                        id="loginPassword"
                        type="password"
                        className="mb-3"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        toggleMask="true"
                        feedback={false}
                        required
                    />

                    {/* <div className="flex align-items-center justify-content-end mb-6">
            <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                            {login.forgotPass}

            </a>
          </div> */}

                    <div className="login-btns flex justify-content-center align-items-center">
                        {/* <Link className="register-link" to="/register">
                                          {login.createAccount}
            </Link> */}
                        <Button
                            type="submit"
                            label="Sign In"
                            icon="pi pi-user"
                            className="mt-3"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
