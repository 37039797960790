import {school} from '../../../utils/lang/ro/layout'
const FooterSchool = () => {
  return (
    <footer className="text-center py-4">
      <p className="mt-0">&copy; {school.waka}</p>
      <div>
        <a
          href="#"
          className="text-500 transition-colors transition-duration-150 hover:text-700"
        >
          <i className="pi pi-twitter text-xl"></i>
        </a>
        <a
          href="#"
          className="text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
        >
          <i className="pi pi-facebook text-xl"></i>
        </a>
        <a
          href="#"
          className="text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
        >
          <i className="pi pi-github text-xl"></i>
        </a>
      </div>
    </footer>
  );
};

export default FooterSchool;
