import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import DocumentsList from "../../DocumentsList";
import StudentPaymentsForSchool from "./StudentPaymentsForSchool";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";

import FormInputError from "../../../../components/Utils/FormInputError";

import {
  stepThreeOptions,
  stepFourOptions,
  uploadDocumentTypes,
} from "../../StepSelectOptions";
import avatar from "../../../../assets/images/student/avatar_placeholder.png";
import "./StudentInfoForSchool.css";

const StudentsInfoForSchool = () => {
  const [studentData, setStudentData] = useState({
    address: "-",
    birthday: "-",
    criminal_record_expiration_date: "-",
    documents: "-",
    documents_series: "-",
    driving_lessons_progress_status: "-",
    driving_license_category: "-",
    gender: "-",
    has_criminal_record: "-",
    has_documents_completed: "-",
    has_id_card: "-",
    has_psychiatrist_certificate: "-",
    has_medical_exam: "-",
    has_payments: "-",
    legislation_progress_status: "-",
    id_card_expiration_date: "-",
    psychiatrist_certificate_expiration_date: "-",
    status: "-",
    user_id: "-",
    transmission_type: "-",
    user_name: "-",
    user: {
      id: "",
      email: "",
      has_profile_completed: "",
      phone: "",
    },
    uploadedDocumentsNum: 0,
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [position, setPosition] = useState("center");
  const [personalDataModal, setPersonalDataModal] = useState(false);
  const [preferencesModal, setPreferencesModal] = useState(false);
  const [documentsModal, setDocumentsModal] = useState(false);
  const [viewDocumentsModal, setViewDocumentsModal] = useState(false);
  const [paymentsModal, setPaymentsModal] = useState(false);
  const [viewPaymentsModal, setViewPaymentsModal] = useState(false);
  const [personalData, setPersonalData] = useState({
    email: "",
    phone: "",
  });
  const [preferences, setPreferences] = useState({
    transmission_type: "",
    group_name: "",
  });
  const [documents, setDocuments] = useState({
    file: null,
    series: "",
    expiration_date: "",
    type: "",
  });
  const [documentUrl, setDocumentUrl] = useState("");
  const [payments, setPayments] = useState({
    total_amount_to_pay: 1500,
    current_amount_payed: "",
    type: "",
  });
  const [paymentUrl, setPaymentUrl] = useState("");
  const [remainingPayments, setRemainingPayments] = useState(0);
  const [latestPayment, setLatestPayment] = useState({
    amount: "",
    date: "",
  });
  const [studentAppointments, setStudentAppointments] = useState([]);
  const [viewStudentActiveIndex, setViewStudentActiveIndex] = useState(0);

  // const { resetFormState } = useContext(SchoolLayoutContext);

  const viewStudentToast = useRef(null);

  const { id } = useParams();

  let minDate = new Date();

  const customValidity = (e, message) => {
    e.target.setCustomValidity(message);
  };

  const displayToast = (severity, summary, detail) => {
    viewStudentToast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  const cars = [
    { label: "Car 1", value: 1 },
    { label: "Car 2", value: 2 },
    { label: "Car 3", value: 3 },
  ];

  const fetchStudentData = async () => {
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const API_URL = `https://api.scoliauto.info/api/school/students/${id}`;

    await axios
      .get(API_URL, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const studentData = res.data.data;
        const stateInfo = {
          ...studentData,
          created_at: new Date(studentData.created_at)
            .toISOString()
            .substring(0, 10),
        };

        const personalDataModal = {
          email: studentData.user.email,
          phone: studentData.user.phone,
        };

        //Get remaining student payments
        const getRemainingPaymentsTotal = () => {
          let totalToPay;

          if (studentData.payments.length) {
            totalToPay = studentData.payments[0].total_amount_to_pay;
            studentData.payments.forEach((payment) => {
              if (payment.current_amount_payed) {
                totalToPay -= payment.current_amount_payed;
              }
            });

            return totalToPay;
          }
        };

        setRemainingPayments(getRemainingPaymentsTotal());

        //Set latest payment
        const latestPaymentMade =
          studentData.payments[studentData.payments.length - 1];

        setLatestPayment((prevState) => {
          if (latestPaymentMade) {
            return {
              amount: latestPaymentMade.current_amount_payed,
              date: new Date(latestPaymentMade.created_at)
                .toISOString()
                .substring(0, 10),
            };
          }
        });

        //Set appointments
        const appointments = studentData.appointments;
        if (appointments) {
          const appointmentsArr = [];

          appointments.forEach((appointment) => {
            const appointmentDay = new Date(
              appointment.start_date
            ).getUTCDate();
            const appointmentMonth =
              new Date(appointment.start_date).getUTCMonth() + 1;
            const appointmentYear = new Date(
              appointment.start_date
            ).getUTCFullYear();

            // console.log(appointmentDay, appointmentMonth, appointmentYear);
            appointmentsArr.push({
              day: appointmentDay,
              month: appointmentMonth,
              year: appointmentYear,
            });
          });

          setStudentAppointments(appointmentsArr);
        }

        setStudentData(stateInfo);

        // autocompleted info in modal
        setPersonalData(personalDataModal);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchStudentData();
  }, []);

  /* Modal helpers */
  const dialogFuncMap = {
    personalDataModal: setPersonalDataModal,
    preferencesModal: setPreferencesModal,
    documentsModal: setDocumentsModal,
    viewDocumentsModal: setViewDocumentsModal,
    paymentsModal: setPaymentsModal,
    viewPaymentsModal: setViewPaymentsModal,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  /* Modal form inputs handlers */

  /* BASIC INFO MODAL */
  const updateBasicInfo = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/students/${id}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .patch(API_URL, personalData, config)
      .then((res) => {
        console.log(res);
        const { data } = res.data;
        setStudentData(data);
        onHide("personalDataModal");
        displayToast("success", "Succes!", "Modificarile au fost salvate!");
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
        console.log(error);
      });
  };

  const onBasicInfoChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setPersonalData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleBasicInfoUpdate = (e) => {
    e.preventDefault();
    updateBasicInfo();
    fetchStudentData();
  };

  /* PREFERENCES MODAL */
  const updatePreferences = async () => {
    const API_URL = `https://api.scoliauto.info/api/school/students/${id}`;
    const token = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .patch(API_URL, preferences, config)
      .then((res) => {
        const { data } = res.data;
        setErrors({});
        setStudentData(data);
        onHide("preferencesModal");
        displayToast("success", "Succes!", "Modificarile au fost salvate!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
      });
  };

  const onPeferenceChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setPreferences((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePreferencesUpdate = (e) => {
    e.preventDefault();
    updatePreferences();
    fetchStudentData();
  };

  /* DOCUMENTS MODAL */
  const updateDocuments = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/documents/upload";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));

    const formData = new FormData();
    formData.append("user_id", studentData.user.id);
    formData.append(`documents[0][file]`, documents.file);
    formData.append(`documents[0][expiration_date]`, documents.expiration_date);
    formData.append(`documents[0][series]`, documents.series);
    formData.append(`documents[0][type]`, documents.type);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    await axios
      .post(API_URL, formData, config)
      .then((response) => {
        const { data } = response.data;
        console.log(data);
        setStudentData(data);
        onHide("documentsModal");
        displayToast("success", "Succes!", "Documentul a fost adaugat!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Adaugare esuata...",
          "Documentul nu s-a adaugat."
        );
      });
  };

  const onDocumentsChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "expiration_date") {
      const formattedDateValue = new Date(e.target.value)
        .toISOString()
        .substring(0, 10);
      value = formattedDateValue;
    }

    setDocuments((prevState) => {
      if (name === "file") {
        const file = e.target.files[0];
        const fileUrl = file ? URL.createObjectURL(file) : "";
        setDocumentUrl(fileUrl);

        return {
          ...prevState,
          file,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleDocumentsUpdate = (e) => {
    e.preventDefault();
    updateDocuments();
    fetchStudentData();
  };

  /* PAYMENTS MODAL */
  const updatePayments = async () => {
    const API_URL = "https://api.scoliauto.info/api/school/payments";
    const accessToken = JSON.parse(window.localStorage.getItem("accessToken"));
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const paymentInfoConfig = {
      /* //TODO: THE AMOUNT BELOW HAS TO BE CHANGED ONCE WE CAN FETCH THE AMOUNT TO BE PAYED */
      student_id: id,
      total_amount_to_pay: 1500,
      current_amount_payed: parseFloat(payments.current_amount_payed),
      type: payments.type,
    };

    await axios
      .post(API_URL, paymentInfoConfig, config)
      .then((response) => {
        console.log(response.data);
        onHide("paymentsModal");
        displayToast("success", "Succes!", "Plata a fost inregistrata!");
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response.data.errors);
        displayToast(
          "error",
          "Eroare inregistrare plata...",
          "Plata nu s-a inregistrat."
        );
      });
  };

  const onPaymentsChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setErrors({});

    setPayments((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handlePaymentsUpdate = (e) => {
    e.preventDefault();
    updatePayments();
    fetchStudentData();
  };

  /* APPOINTMENTS CALENDAR LOGIC*/
  const dateTemplate = (date) => {
    studentAppointments.forEach((appointment) => {
      if (
        studentAppointments.day === date.day &&
        studentAppointments.month === date.month &&
        studentAppointments.year === studentAppointments.year
      ) {
        return (
          <div
            style={{
              backgroundColor: "#1dcbb3",
              color: "#ffffff",
              fontWeight: "bold",
              borderRadius: "50%",
              width: "2em",
              height: "2em",
              lineHeight: "2em",
              padding: 0,
            }}
          >
            {date.day}
          </div>
        );
      }
    });
    return date.day;
  };

  if (loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="p-4">
        <Toast
          ref={viewStudentToast}
          style={{ left: "50%", transform: "translateX(-50%)" }}
        />

        <TabView
          activeIndex={viewStudentActiveIndex}
          onTabChange={(e) => setViewStudentActiveIndex(e.index)}
        >
          <TabPanel header="1. Date generale">
            <div className="surface-card p-4 shadow-2 border-round mb-6">
              <h3>Date generale</h3>
              <div className="flex gap-5">
                <div className="avatar-container col-3">
                  <img src={avatar} alt="avatar placeholder" />
                </div>
                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">
                      Date personale
                    </span>
                    <Button
                      label="Modifica"
                      onClick={() => onClick("personalDataModal")}
                      className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                    />

                    <Dialog
                      header="Editeaza date personale"
                      visible={personalDataModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("personalDataModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handleBasicInfoUpdate}
                        className="edit-form background surface-50 p-4 border-round-lg"
                      >
                        <div className="form-group">
                          <label htmlFor="email">E-mail</label>
                          <InputText
                            name="email"
                            onChange={(e) => onBasicInfoChange(e)}
                            value={personalData.email}
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="phone">Telefon</label>
                          <InputText
                            name="phone"
                            onChange={(e) => onBasicInfoChange(e)}
                            value={personalData.phone}
                          />
                        </div>
                        <Button className="block mx-auto mt-4">Salveaza</Button>
                      </form>
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Data nasterii:</span>
                        <span>{studentData.birthday}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Email:</span>
                        <span>{studentData.user.email}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Telefon:</span>
                        <span>{studentData.user.phone}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Sex:</span>
                        <span>{studentData.gender}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>CNP:</span>
                        <span>{studentData.personal_id_number}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-4 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">
                      Preferinte
                    </span>
                    <Button
                      label="Modifica"
                      onClick={() => onClick("preferencesModal")}
                      className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                    />

                    <Dialog
                      header="Editeaza preferinte"
                      visible={preferencesModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("preferencesModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handlePreferencesUpdate}
                        className="edit-form surface-50 p-4 border-round-lg"
                      >
                        <div className="form-group">
                          <label htmlFor="email">Cutie de viteze</label>
                          <Dropdown
                            className="col-12 md:col-12 p-0"
                            name="transmission_type"
                            placeholder="Cutie preferata"
                            options={stepThreeOptions.transmissionOptions}
                            value={preferences.transmission_type}
                            onChange={onPeferenceChange}
                          />
                        </div>

                        <div className="form-group">
                          {errors.group_name && (
                            <FormInputError errors={errors.group_name} />
                          )}
                          <label
                            htmlFor="groupNumber"
                            className="font-medium text-600 ml-2"
                          >
                            Grupa
                          </label>
                          <InputText
                            id="groupNumberUpdate"
                            type="text"
                            name="group_name"
                            className="w-full"
                            onChange={onPeferenceChange}
                            value={preferences.group_name}
                            placeholder="Grupa"
                          />
                        </div>
                        <Button className="block mx-auto mt-4">Salveaza</Button>
                      </form>
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Grupa:</span>
                        <span>{studentData.group_name}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Categorie permis:</span>
                        <span>{studentData.driving_license_category}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Cutie de viteze:</span>
                        <span>{studentData.transmission_type}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Data inregistrarii:</span>
                        <span>{studentData.created_at}</span>
                      </li>
                      {/* <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima accesare:</span>
                        <span>2022-10-28</span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="2. Administrare">
            <div className="surface-card p-4 shadow-2 border-round mb-6">
              <h3>Administrare</h3>
              <div className="flex gap-6">
                <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">Acte</span>
                    <div className="action-btns">
                      <Button
                        label="Adauga act"
                        onClick={() => onClick("documentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined mr-3"
                      />
                      <Button
                        label="Vezi toate actele"
                        onClick={() => onClick("viewDocumentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                      />
                    </div>

                    <Dialog
                      header="Adaugare act"
                      visible={documentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("documentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handleDocumentsUpdate}
                        className="flex flex-column w-full surface-50 p-4 border-round-lg"
                      >
                        <Dropdown
                          options={uploadDocumentTypes}
                          placeholder="Tipul actului"
                          name="type"
                          className="h-3rem mb-3"
                          value={documents.type}
                          onChange={onDocumentsChange}
                        />

                        <InputText
                          id="add_new_document"
                          type="text"
                          name="series"
                          onChange={onDocumentsChange}
                          value={documents.series}
                          placeholder="Seria / Nr. buletin"
                          className="mb-3"
                          onInvalid={(e) =>
                            customValidity(e, "Introduceti seria/nr. buletin")
                          }
                          onInput={(e) => customValidity(e, "")}
                        />

                        <Calendar
                          id="file_expiration_date"
                          value={documents.expiration_date}
                          name="expiration_date"
                          placeholder="Data expirarii"
                          dateFormat="dd/mm/yy"
                          showIcon={true}
                          onChange={onDocumentsChange}
                          className="mb-3"
                          minDate={minDate}
                        ></Calendar>

                        <label className="custom-file-upload w-max">
                          <input
                            type="file"
                            name="file"
                            onChange={onDocumentsChange}
                            className="mb-3"
                          />
                          <i className="pi pi-file"></i> Incarca
                        </label>

                        <div className="document-preview mt-3">
                          {!documentUrl && <i className="pi pi-image"></i>}
                          <img src={documentUrl} alt="Buletin" />
                        </div>

                        <Button className="mt-4 mx-auto block">
                          Adauga act
                        </Button>
                      </form>
                    </Dialog>

                    <Dialog
                      header="Vizualizare acte"
                      visible={viewDocumentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("viewDocumentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <DocumentsList documents={studentData.documents} />
                    </Dialog>
                  </div>

                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Acte incarcate:</span>
                        <span>{studentData.documents.length}/4</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Acte expirate:</span>
                        <span>0/4</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Examen scoala:</span>
                        <span>01-10-2022</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Examen teoretic politie:</span>
                        <span>01-10-2022</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Examen practic politie:</span>
                        <span>01-10-2022</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between align-items-center">
                    <span className="card-title font-bold text-lg">Plati</span>
                    <div className="action-btns">
                      <Button
                        label="Adauga plata"
                        onClick={() => onClick("paymentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined mr-3"
                      />
                      <Button
                        label="Vezi toate platile"
                        onClick={() => onClick("viewPaymentsModal")}
                        className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                      />
                    </div>
                  </div>

                  <div className="card-body">
                    <Dialog
                      header="Adauga plata"
                      visible={paymentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("paymentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <form
                        onSubmit={handlePaymentsUpdate}
                        className="surface-50 p-4 border-round-lg"
                      >
                        <div className="flex flex-column">
                          <InputText
                            id="paymentAmount"
                            type="number"
                            name="total_amount_to_pay"
                            onChange={onPaymentsChange}
                            value={payments.total_amount_to_pay || 1500}
                            required
                            placeholder="Suma de plata"
                            className="w-full mb-3"
                            disabled
                          />

                          {errors.current_amount_payed && (
                            <FormInputError
                              errors={errors.current_amount_payed}
                            />
                          )}
                          <Dropdown
                            name="current_amount_payed"
                            value={payments.current_amount_payed}
                            onChange={onPaymentsChange}
                            options={stepFourOptions.paymentRates}
                            placeholder="Rata"
                            className="mb-3"
                          />

                          {errors.type && (
                            <FormInputError errors={errors.type} />
                          )}
                          <Dropdown
                            name="type"
                            value={payments.type}
                            onChange={onPaymentsChange}
                            options={stepFourOptions.paymentTypes}
                            placeholder="Tip plata"
                            className="mb-3"
                          />

                          {/* {payment_evidence_url && (
                          <div className="document-preview mt-3">
                            <img
                              src={payment_evidence_url}
                              alt="Evidenta plata"
                            />
                          </div>
                        )} */}
                        </div>

                        <div className="col-12">
                          <Button
                            label="Salveaza modificarile"
                            className="block mx-auto"
                          />
                        </div>
                      </form>
                    </Dialog>

                    <Dialog
                      header="Vizualizare plati"
                      visible={viewPaymentsModal}
                      style={{ width: "30vw" }}
                      onHide={() => onHide("viewPaymentsModal")}
                      headerStyle={{ textAlign: "center" }}
                    >
                      <StudentPaymentsForSchool id={id} />
                    </Dialog>

                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Suma totala:</span>
                        <span>
                          {studentData.payments.length &&
                            studentData.payments[0].total_amount_to_pay}
                        </span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Restante:</span>
                        <span>0</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ramas de plata:</span>
                        <span>{remainingPayments}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima plata (lei):</span>
                        <span>{latestPayment ? latestPayment.amount : 0}</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima plata (data):</span>
                        <span>{latestPayment ? latestPayment.date : 0}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="3. Programari conducere">
            <div className="surface-card p-4 shadow-2 border-round mb-6">
              <div className="card-header border-bottom-1 border-200 pb-3 mb-4 flex justify-content-between align-items-center">
                <span className="card-title font-bold text-lg">
                  Programari conducere
                </span>
                <div className="action-btns">
                  <Button
                    className="p-button-secondary p-button-sm py-2 line-height-3 p-button-outlined"
                    label="Adauga programare"
                  ></Button>
                </div>
              </div>
              <div className="flex gap-6">
                <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
                  <div className="card-body">
                    <Calendar
                      inline
                      className="w-full"
                      onChange={(e) => console.log(e)}
                      dateTemplate={dateTemplate}
                      minDate={minDate}
                    ></Calendar>
                  </div>
                </div>

                <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
                  <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between">
                    <span className="card-title font-bold text-lg">
                      Sedinte conducere
                    </span>
                    <div className="action-btns">
                      <button className="btn-ghost">
                        <i className="pi pi-chevron-left"></i>
                      </button>
                      <span>2022-10-1</span>
                      <button className="btn-ghost">
                        <i className="pi pi-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <ul className="list-none p-3 m-0">
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Masina preferata:</span>
                        <Dropdown
                          options={cars}
                          placeholder="Masina preferata"
                          name="preferred_car"
                          className="h-3rem"
                        />
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Sedinte:</span>
                        <span>7/12</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Rulaj (km):</span>
                        <span>500/670</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima sedinta:</span>
                        <span>01-10-2022</span>
                      </li>
                      <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                        <span>Ultima plata (data):</span>
                        <span>12-10-2022</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>

        {/* <div className="surface-card p-4 shadow-2 border-round mb-6">
          <h3>Sedinte practice</h3>
          <div className="flex gap-6">
            <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
              <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between">
                <span className="card-title font-bold text-lg">
                  Instructori
                </span>
                <div className="action-btns">
                  <button className="btn-ghost">Vezi toti instructorii</button>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-none p-3 m-0">
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Instructor preferat:</span>
                    <Dropdown
                      options={instructors}
                      placeholder="Instructor preferat"
                      name="preferred_instructor"
                      className="h-3rem"
                    />
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Sedinte:</span>
                    <span>10/12</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Nota min/max:</span>
                    <span>7/10</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Ultima sedinta:</span>
                    <span>01-10-2022</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Manevra dificila:</span>
                    <span>Pornire in panta</span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="surface-card shadow-2 border-round list-card col-5 px-5 py-3">
              <div className="card-header border-bottom-1 border-200 pb-3 flex justify-content-between">
                <span className="card-title font-bold text-lg">Masini</span>
                <div className="action-btns">
                  <button className="btn-ghost">Vezi toate masinile</button>
                </div>
              </div>
              <div className="card-body">
                <ul className="list-none p-3 m-0">
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Masina preferata:</span>
                    <Dropdown
                      options={cars}
                      placeholder="Masina preferata"
                      name="preferred_car"
                      className="h-3rem"
                    />
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Sedinte:</span>
                    <span>7/12</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Rulaj (km):</span>
                    <span>500/670</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Ultima sedinta:</span>
                    <span>01-10-2022</span>
                  </li>
                  <li className="border-bottom-1 border-200 py-3 flex justify-content-between">
                    <span>Ultima plata (data):</span>
                    <span>12-10-2022</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
};

export default StudentsInfoForSchool;
