import { useContext } from "react";

import FormInputError from "../../../components/Utils/FormInputError";
import {
  genderOptions,
  weekendCoursesOptions,
  stepThreeOptions,
  collaborationTypes,
  licenseTypes,
} from "../StepSelectOptions";

import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

import SchoolLayoutContext from "../../../context/SchoolLayoutContext";

const AddNewInstructor = () => {
  const {
    newInstructor: {
      newInstructorBasicInfo: { email, phone },
      newInstructorUtils: {
        first_name,
        last_name,
        address,
        personal_id_number,
        driver_license_seniority,
        has_weekend_schedule,
        instructorship_seniority,
        gender,
        price_per_appointment,
        driving_license_categories,
        collaboration_type,
      },
      newInstructorDocuments: {
        id_card_series,
        id_card_expiration_date,
        id_card_url,
        criminal_record_series,
        criminal_record_expiration_date,
        criminal_record_url,
        medical_exam_series,
        medical_exam_expiration_date,
        medical_exam_url,
        psychiatrist_certificate_series,
        psychiatrist_certificate_expiration_date,
        psychiatrist_certificate_url,
        instructor_license_series,
        instructor_license_expiration_date,
        instructor_license_url,
      },
    },
    addNewInstructorActiveIndex,
    setAddNewInstructorActiveIndex,
    handleNewInstructorFormChange,
    handleInstructorStepOneSubmit,
    schoolLayoutToast,
    errors,
    instructorStepTwoUnlocked,
    handleInstructorStepTwoSubmit,
    handleInstructorStepThreeSubmit,
  } = useContext(SchoolLayoutContext);

  const customValidity = (e, message) => {
    e.target.setCustomValidity(message);
  };

  let minDate = new Date();

  return (
    <>
      <Toast
        ref={schoolLayoutToast}
        style={{ left: "50%", transform: "translateX(-50%)" }}
      ></Toast>

      <p className="bg-blue-100 text-xl font-bold m-0 text-center py-3">
        Adaugare instructor nou

      </p>

      <div className="w-full px-5 pt-3 add-new-instructor-steps">
        <TabView
          activeIndex={addNewInstructorActiveIndex}
          onTabChange={(e) => setAddNewInstructorActiveIndex(e.index)}
        >
          <TabPanel header="1. Date personale">
            <div className="surface-card p-4 shadow-2 border-round flex">
              <div className="col-6 md:px-6 py-4 relative">
                {instructorStepTwoUnlocked && (
                  <div className="step-two-overlay">
                    <i className="pi pi-lock"></i>
                  </div>
                )}
                <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                  Date personale
                </h3>

                <form onSubmit={handleInstructorStepOneSubmit}>
                  <div className="grid formgrid p-fluid">
                    <InputText
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="mb-3"
                      onChange={handleNewInstructorFormChange}
                      value={email}
                      required
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti adresa de email")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    {errors.email && <FormInputError errors={errors.email} />}

                    <InputText
                      id="phone"
                      type="phone"
                      name="phone"
                      placeholder="Telefon"
                      className="mb-3"
                      onChange={handleNewInstructorFormChange}
                      value={phone}
                      required
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti numarul de telefon")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    {errors.phone && <FormInputError errors={errors.phone} />}

                    <Button
                      type="submit"
                      label="Creeaza Utilizator"
                      className="bg-green-400 border-0 font-medium w-max"
                    />
                  </div>
                </form>
              </div>

              <div className="col-6 relative md:px-6 py-4">
                {!instructorStepTwoUnlocked && (
                  <div className="step-two-overlay">
                    <i className="pi pi-lock"></i>
                  </div>
                )}

                <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                  Informatii utile
                </h3>

                <form onSubmit={handleInstructorStepTwoSubmit}>
                  <div className="grid formgrid p-fluid">
                    {errors.last_name && (
                      <FormInputError errors={errors.last_name} />
                    )}
                    <InputText
                      id="lastName"
                      type="text"
                      name="last_name"
                      onChange={handleNewInstructorFormChange}
                      value={last_name}
                      required
                      placeholder="Nume"
                      className="mb-4"
                    />

                    {errors.first_name && (
                      <FormInputError errors={errors.first_name} />
                    )}
                    <InputText
                      id="firstName"
                      type="text"
                      name="first_name"
                      onChange={handleNewInstructorFormChange}
                      value={first_name}
                      required
                      placeholder="Prenume"
                      className="mb-4"
                    />

                    {errors.address && (
                      <FormInputError errors={errors.address} />
                    )}
                    <InputText
                      id="address"
                      type="text"
                      name="address"
                      onChange={handleNewInstructorFormChange}
                      value={address}
                      required
                      className="mb-4"
                      placeholder="Adresa"
                    />

                    <div className="num-fields flex w-full">
                      <div className="col-6 pl-0">
                        {errors.personal_id_number && !personal_id_number && (
                          <FormInputError errors={errors.personal_id_number} />
                        )}
                        <InputText
                          id="instructorPersonalIdNumber"
                          type="text"
                          name="personal_id_number"
                          className="mb-4"
                          placeholder="CNP"
                          onChange={handleNewInstructorFormChange}
                          value={personal_id_number}
                          required
                        />
                        {errors.driver_license_seniority && (
                          <FormInputError
                            errors={errors.driver_license_seniority}
                          />
                        )}
                        <InputText
                          className="mb-4"
                          name="driver_license_seniority"
                          onChange={handleNewInstructorFormChange}
                          value={driver_license_seniority}
                          placeholder="Vechime permis"
                        />

                        {errors.has_weekend_schedule && (
                          <FormInputError
                            errors={errors.has_weekend_schedule}
                          />
                        )}
                        <Dropdown
                          className="mb-4"
                          options={weekendCoursesOptions}
                          placeholder="Sedinte weekend"
                          name="has_weekend_schedule"
                          value={has_weekend_schedule}
                          onChange={handleNewInstructorFormChange}
                        />
                      </div>

                      <div className="col-6 pr-0">
                        {errors.instructorship_seniority && (
                          <FormInputError
                            errors={errors.instructorship_seniority}
                          />
                        )}
                        <InputText
                          className="mb-4"
                          name="instructorship_seniority"
                          onChange={handleNewInstructorFormChange}
                          value={instructorship_seniority}
                          placeholder="Vechime instructor"
                        />

                        {errors.gender && (
                          <FormInputError errors={errors.gender} />
                        )}
                        <Dropdown
                          className="mb-4"
                          options={genderOptions}
                          placeholder="Sex"
                          name="gender"
                          value={gender}
                          onChange={handleNewInstructorFormChange}
                        />
                        {errors.price_per_appointment && (
                          <FormInputError
                            errors={errors.price_per_appointment}
                          />
                        )}
                        <InputText
                          className="mb-4"
                          name="price_per_appointment"
                          onChange={handleNewInstructorFormChange}
                          value={price_per_appointment}
                          placeholder="Pret sedinta"
                        />
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="col-6 pl-0">
                        {errors.driving_license_categories && (
                          <FormInputError
                            errors={errors.driving_license_categories}
                          />
                        )}
                        <MultiSelect
                          value={driving_license_categories}
                          onChange={handleNewInstructorFormChange}
                          options={licenseTypes}
                          optionLabel="name"
                          placeholder="Categorii permis"
                          className="w-full"
                          maxSelectedLabels={3}
                          name="driving_license_categories"
                        />
                      </div>
                      <div className="col-6 pr-0">
                        {errors.collaboration_type && (
                          <FormInputError errors={errors.collaboration_type} />
                        )}
                        <Dropdown
                          className="mb-4"
                          options={collaborationTypes}
                          placeholder="Tip colaborare"
                          name="collaboration_type"
                          onChange={handleNewInstructorFormChange}
                          value={collaboration_type}
                        />
                      </div>
                    </div>

                    <Button
                      label="Creeaza instructor"
                      className="bg-green-400 border-0 font-medium w-max"
                    />

                    <Button
                      id="continueInstructorDocuments"
                      label="Creeaza si adauga acte"
                      className="bg-blue-400 ml-4 border-0 font-medium w-max"
                    />
                  </div>
                </form>
              </div>
            </div>
          </TabPanel>

          <TabPanel header="2. Acte doveditoare" disabled>
            <div className="surface-card p-4 shadow-2 border-round">
              <h3 className="card-title border-bottom-1 border-200 pb-3 mb-5">
                Acte doveditoare
              </h3>

              <form
                onSubmit={handleInstructorStepThreeSubmit}
                className="flex flex-wrap"
              >
                <div className="col-4">
                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="id_card_series"
                      type="text"
                      name="id_card_series"
                      onChange={handleNewInstructorFormChange}
                      value={id_card_series}
                      required
                      placeholder="Seria / Nr. buletin"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti seria/nr. buletin")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="id_card_expiration_date"
                      value={id_card_expiration_date}
                      name="id_card_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewInstructorFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {/* {errors["documents.0.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru buletin!"]}
                      />
                    )} */}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="id_card"
                        onChange={handleNewInstructorFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!id_card_url && <i className="pi pi-image"></i>}
                      <img src={id_card_url} alt="Buletin" />
                    </div>
                  </div>

                  <div className="mb-3 col-12 md:col-10 flex flex-column ">
                    <InputText
                      id="criminal_record_series"
                      type="text"
                      name="criminal_record_series"
                      onChange={handleNewInstructorFormChange}
                      value={criminal_record_series}
                      required
                      placeholder="Seria / Nr. cazier"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti seria/nr. cazier")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="criminal_record_expiration_date"
                      value={criminal_record_expiration_date}
                      name="criminal_record_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewInstructorFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {/* {errors["documents.1.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru cazier!"]}
                      />
                    )} */}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="criminal_record"
                        onChange={handleNewInstructorFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!criminal_record_url && <i className="pi pi-image"></i>}
                      <img src={criminal_record_url} alt="Cazier" />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="medical_exam_series"
                      type="text"
                      name="medical_exam_series"
                      onChange={handleNewInstructorFormChange}
                      value={medical_exam_series}
                      required
                      placeholder="Seria / Nr. Certificat medical"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(
                          e,
                          "Introduceti seria/nr. crtificat medical"
                        )
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="medical_exam_expiration_date"
                      value={medical_exam_expiration_date}
                      name="medical_exam_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewInstructorFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {/* {errors["documents.2.file"] && (
                      <FormInputError
                        errors={[
                          "Incarcati fisierul pentru certificat medical!",
                        ]}
                      />
                    )} */}
                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="medical_exam"
                        onChange={handleNewInstructorFormChange}
                        className="mb-3 bg-primary-500 border-0"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!medical_exam_url && <i className="pi pi-image"></i>}
                      <img src={medical_exam_url} alt="Aviz medical" />
                    </div>
                  </div>

                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="psychiatrist_certificate_series"
                      type="text"
                      name="psychiatrist_certificate_series"
                      onChange={handleNewInstructorFormChange}
                      value={psychiatrist_certificate_series}
                      required
                      placeholder="Seria / Nr. Aviz psihologic"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(
                          e,
                          "Introduceti seria/nr. avizului psihologic"
                        )
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="psychiatrist_certificate_expiration_date"
                      value={psychiatrist_certificate_expiration_date}
                      name="psychiatrist_certificate_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewInstructorFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {/* {errors["documents.3.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru aviz psihologic!"]}
                      />
                    )} */}

                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="psychiatrist_certificate"
                        onChange={handleNewInstructorFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!psychiatrist_certificate_url && (
                        <i className="pi pi-image"></i>
                      )}
                      <img
                        src={psychiatrist_certificate_url}
                        alt="Aviz psihologic"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3 col-12 md:col-10 flex flex-column">
                    <InputText
                      id="instructor_license_series"
                      type="text"
                      name="instructor_license_series"
                      onChange={handleNewInstructorFormChange}
                      value={instructor_license_series}
                      required
                      placeholder="Seria / Nr. Atestat"
                      className="mb-3"
                      onInvalid={(e) =>
                        customValidity(e, "Introduceti seria/nr. atestatului")
                      }
                      onInput={(e) => customValidity(e, "")}
                    />

                    <Calendar
                      id="atestat_instructor_expiration_date"
                      value={instructor_license_expiration_date}
                      name="instructor_license_expiration_date"
                      placeholder="Data expirarii"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      onChange={handleNewInstructorFormChange}
                      className="mb-3"
                      minDate={minDate}
                    ></Calendar>

                    {/* {errors["documents.4.file"] && (
                      <FormInputError
                        errors={["Incarcati fisierul pentru atestat!"]}
                      />
                    )} */}

                    <label className="custom-file-upload w-max">
                      <input
                        type="file"
                        name="instructor_license"
                        onChange={handleNewInstructorFormChange}
                        className="mb-3"
                      />
                      <i className="pi pi-file"></i> Incarca
                    </label>

                    <div className="document-preview mt-3">
                      {!instructor_license_url && (
                        <i className="pi pi-image"></i>
                      )}
                      <img
                        src={instructor_license_url}
                        alt="Atestat instructor"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6 flex">
                  <Button
                    type="submit"
                    label="Adauga acte"
                    className="bg-green-400 border-0 font-medium w-max mt-4"
                  />
                </div>
              </form>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default AddNewInstructor;
