const fields = {
    register:
        {
            firstname: 'Prenume',
            lastname: 'Nume',
            email: 'Email',
            password: 'Parola',
            confirmationPassword: 'Confirma parola',
            birthDay: 'Data nasterii',
            phone: 'Telefon'

        }
}
export default fields