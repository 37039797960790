import { Outlet, Link } from "react-router-dom";

export default function LawsTests() {
  let legislationItems = [
    {
      title: "Legislatie",
      total: 24,
      done: 3,
      icon: "pi pi-shopping-exclamation-triangle",
      image: "/assets/images/student/semne_drum_cu_prioritate.png",
      text: "terminate",
    },
    {
      title: "Capitolul 1 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 2 (ce este in cap 2)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 3 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 4 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 5 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 6 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 7 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 8 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 9 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Capitolul 10 (drumuri publice)",
      total: 50 + "cursuri",
      done: 0,
      icon: "pi pi-shopping-car",
      image: "",
      type: "lectie",
      text: "terminate",
    },
    {
      title: "Chestionare iesire din sala",
      total: 10,
      done: 3,
      type: "chestionar",
      icon: "pi pi-shopping-briefcase",
      image: "../../../assets/images/student/semne_drum_cu_prioritate.png",
      text: "terminate",
    },
  ];

  function LinkButton(type, title) {
    if (type === "chestionar") {
      return (
        <Link to={`/student/laws-tests/${title}`}>
          {" "}
          Acceseaza chestionarele{" "}
        </Link>
      );
    }
    return (
      <Link to={`/student/laws-chapter/${title}`}>Aceseaza captiloul </Link>
    );
  }

  function renderElement(item, i) {
    return (
      <div className="col-12 md:col-6 lg:col-3" key={i}>
        <div className="surface-card shadow-2 p-3 border-round">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                {item.title}
              </span>
              <div className="text-900 font-medium text-xl">{item.total}</div>
            </div>
            <div>
              <img
                src="../../../assets/images/student/semne_drum_cu_prioritate.png"
                alt=""
              />
              {/*<img src={require(`${item.image}`)} alt={item.title}/>*/}
              <img src={item.image} alt={item.title} />
            </div>
          </div>
          <img
            src="../../../assets/images/student/semne_drum_cu_prioritate.png"
            alt=""
          />
          <span className="text-green-500 font-medium">{item.done} </span>
          <span className="text-500">{item.text}</span>
          {LinkButton(item.type, item.title)}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>Legislatie aici</h1>
      <div className="surface-ground px-4 py-5 md:px-6 lg:px-8">
        <div className="grid">
          {legislationItems.map((item, i) => {
            return renderElement(item, i);
          })}
        </div>
      </div>
      <Outlet />
    </div>
  );
}
