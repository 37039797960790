import {FileUpload} from "primereact/fileupload";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
export default function StudentInfo() {
    return (
        <div>
            <div className="text-900 font-medium text-900 text-xl mb-3">Profil</div>
            <p className="m-0 mb-4 p-0 text-600 line-height-3 mr-3">Aici se pot completa si modifica datele
                personale</p>
            <div className="surface-card p-4 shadow-2 border-round">
                <div className="grid formgrid p-fluid">
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="avatar2" className="font-medium text-900">Avatar</label>
                        <div className="flex align-items-center">
                            <img src="assets/images/blocks/avatars/circle/avatar-f-4.png" alt="avatar-f-4"
                                 className="mr-4"/>
                            <FileUpload id="avatar2" mode="basic" name="avatar" accept="image/*"
                                        maxFileSize={1000000}
                                        chooseOptions={{className: 'p-button-plain p-button-outlined'}}
                                        chooseLabel="Upload Image"/>
                        </div>
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="first-name" className="font-medium text-900">Nume</label>
                        <InputText id="first-name" type="text"/>
                        <label htmlFor="last-name" className="font-medium text-900">Prenume</label>
                        <InputText id="last-name" type="text"/>
                    </div>

                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="email" className="font-medium text-900">Email</label>
                        <InputText id="email" type="email"/>
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="phone" className="font-medium text-900">Telefon</label>
                        <InputText id="phone" type="tel"/>
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="birthday" className="font-medium text-900">Data nașterii</label>
                        <InputText id="birthday" type="date"/>
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div className="col-12">
                        <Button label="Save Changes" className="w-auto mt-3"/>
                    </div>
                </div>
            </div>
        </div>
    )
}