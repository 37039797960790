import { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import StudentInfo from "./StudentInfo/StudentInfo";
import StudentDriver from "./StudentDriver/StudentDriver";
import StudentDocs from "./StudentDocs/StudentDocs";
import StudentPayments from "./StudentPayments/StudentPayments";
import StudentSettings from "./StudentSettings/StudentSettings";

export default function StudentProfile() {
  let categories = [];
  let [active2, setActive2] = useState(0);
  let [initState, setInitState] = useState();
  let [category, setCategory] = useState(null);

  return (
    <div>
      <TabView className="tabview-header-icon">
        <TabPanel header="Profil">
          <StudentInfo />
        </TabPanel>
        <TabPanel header="Acte">
          <StudentDocs />
        </TabPanel>
        <TabPanel header="Școala">
          <StudentDriver />
        </TabPanel>
        <TabPanel header="Plati">
          <StudentPayments />
        </TabPanel>
        <TabPanel header="Setari">
          <StudentSettings />
        </TabPanel>
      </TabView>
    </div>

    // <ul className="surface-card p-0 m-0 list-none flex overflow-x-auto select-none">
    //     <li className="px-3">
    //         <a className={classNames('p-ripple cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150', { 'border-blue-500 text-blue-500 hover:border-blue-500': active2 === 0, 'text-700 border-transparent': active2 !== 0 })} onClick={() => setActive2(0)}>
    //             <i className="pi pi-home mr-2"></i>
    //             <span className="font-medium">Profil</span>
    //             <Ripple />
    //         </a>
    //     </li>
    //     <li className="px-3">
    //         <a className={classNames('p-ripple cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150', { 'border-blue-500 text-blue-500 hover:border-blue-500': active2 === 1, 'text-700 border-transparent': active2 !== 1 })} onClick={() => setActive2(1)}>
    //             <i className="pi pi-user mr-2"></i>
    //             <span className="font-medium">Acte</span>
    //             <Ripple />
    //         </a>
    //     </li>
    //     <li className="flex align-items-center">
    //         <div style={{ width: '1px', height: '50%' }} className="border-right-1 surface-border"></div>
    //     </li>
    //     <li className="px-3">
    //         <a className={classNames('p-ripple cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150', { 'border-blue-500 text-blue-500 hover:border-blue-500': active2 === 2, 'text-700 border-transparent': active2 !== 2 })} onClick={() => setActive2(2)}>
    //             <i className="pi pi-cog mr-2"></i>
    //             <span className="font-medium">Setari</span>
    //             <Ripple />
    //         </a>
    //     </li>
    // </ul>
  );
}
