import CardStudent from "../../../components/cards/CardStudent/CardStudent";
import StudentRoadmap from "../../../components/roadmap/StudentRoadmap";

const StudentHome = () => {
  return (
    <>
      <CardStudent />
      <StudentRoadmap />
      {/* <div className="surface-card shadow-2 border-round flex justify-content-between p-3">
        <div className="border-right-1 md:border-bottom-none surface-border flex-auto p-3">
          <div className="ml-3 flex-1">
            <span className="block text-900 mb-1 text-md font-medium">
              Teorie
            </span>
            <div className="flex align-items-center justify-content-between">
              <span className="font-bold text-700">16/16</span>
            </div>
            <ul className="list-none p-0 m-0 flex align-content-center mt-2">
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-blue-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-blue-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-blue-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-blue-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-blue-500"
              ></li>
            </ul>
          </div>
        </div>
        <div className="border-right-1 md:border-bottom-none surface-border flex-auto p-3">
          <img
            style={{ height: "50px", borderRadius: "50px" }}
            src="https://img.freepik.com/free-photo/travel-agent-hearing-customer-desires-portrait-good-looking-european-businesswoman-blue-blouse-glasses-holding-hands-pockets-smiling-being-friendly-polite-gray-wall_176420-25025.jpg?w=996&t=st=1662490610~exp=1662491210~hmac=7486580be9b947ba9129dc88035166139f447a74b432ecbf88a73b7ab001e0d4"
            alt=""
          />
          <span className="block text-900 mb-1 text-md font-medium">
            Maria Popescu{" "}
          </span>
        </div>
        <div className="border-right-none md:border-bottom-none surface-border flex-auto p-3">
          <div className="ml-3 flex-1">
            <span className="block text-900 mb-1 text-md font-medium">
              Conducere
            </span>
            <div className="flex align-items-center justify-content-between">
              <span className="font-bold text-700">7/30</span>
            </div>
            <ul className="list-none p-0 m-0 flex align-content-center mt-2">
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-orange-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-orange-500 mr-1"
              ></li>
              <li
              style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-orange-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-orange-500 mr-1"
              ></li>
              <li
                style={{ height: "5px", borderRadius: "3px" }}
                className="flex-1 bg-orange-500"
              ></li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default StudentHome;
