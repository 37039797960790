import { Route } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./Layout/Login/Login";

import {
  LawsTests,
  StudentDrivingSchedule,
  StudentProfile,
  StudentHome,
  SingleTestCategory,
  LawChapter,
} from "./pages/student";
import {
  SchoolSettings,
  Students,
  SchoolProfile,
  Instructors,
  AllDrivingSchedules,
  SchoolHome,
  StudentInfoForSchool,
  AddNewStudent,
  AddNewAppointment,
  AddNewInstructor,
} from "./pages/school";
import InstructorInfoForSchool from "./pages/school/Instructors/InstructorInfoForSchool/InstructorInfoForSchool";
import Cars from "./pages/school/Cars/Cars";
import Car from "./pages/school/Cars/Car";
import AddNewCar from "./pages/school/AddNewCar/AddNewCar";

const lawsChapters = [
  { id: "1", title: "Robin Wieruch" },
  { id: "2", title: "Sarah Finnley" },
];

export const MainRoutes = [
  <Route path="/" element={<Home />} />,
  <Route path="/register" element={<Register />} />,
  <Route path="/login" element={<Login />} />,
];

function DynamicPage() {
  return (
    <div>
      <h1>Dynamic</h1>
    </div>
  );
}

export const StudentRoutes = [
  <Route path="/student" element={<StudentHome />} />,
  <Route path="/student/profile" element={<StudentProfile />} />,
  <Route
    path="/student/driving-schedules"
    element={<StudentDrivingSchedule />}
  />,
  <Route path="/student/laws-tests" element={<LawsTests />} />,
  <Route path="/student/laws-chapter/:id" element={<LawChapter />} />,
  <Route path="/student/laws-tests/:id" element={<SingleTestCategory />} />,
];

export const SchoolRoutes = [
  <Route path="/school" element={<SchoolHome />} key="school-route-home" />,
  <Route
    path="/school/profile"
    element={<SchoolProfile />}
    key="school-route-profile"
  />,
  <Route
    path="/school/instructors"
    element={<Instructors />}
    key="school-route-instructors"
  />,
  <Route
    path="/school/instructors/:id"
    element={<InstructorInfoForSchool />}
    key="school-route-single-instructor"
  />,
  <Route
    path="/school/all-driving-schedules"
    element={<AllDrivingSchedules />}
    key="school-route-schedules"
  />,
  <Route
    path="/school/settings"
    element={<SchoolSettings />}
    key="school-route-settings"
  />,
  <Route
    path="/school/students"
    element={<Students />}
    key="school-route-students"
  />,
  <Route
    path="/school/students/:id"
    element={<StudentInfoForSchool />}
    key="school-route-single-student"
  />,
  <Route
    path="/school/add-new-appointment/"
    element={<AddNewAppointment />}
    key="school-route-appointment"
  />,
  <Route
    path="/school/add-new-student/"
    element={<AddNewStudent />}
    key="school-route-add-new-student"
  />,
  <Route
    path="/school/add-new-instructor/"
    element={<AddNewInstructor />}
    key="school-route-add-new-instructor"
  />,
  <Route
    path="/school/add-new-car/"
    element={<AddNewCar />}
    key="school-route-add-new-car"
  />,
  <Route path="/school/cars/" element={<Cars />} key="school-route-cars" />,
  <Route
    path="/school/cars/:id"
    element={<Car />}
    key="school-route-single-car"
  />,
];
